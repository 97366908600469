import { PopularizationInfopovodFilter, PopularizationInfopovodFilterParamName } from '@interfaces/popularization-infopovod.interface';
import { PopularizationInfopovodData, PopularizationInfopovodModel } from '@models/popularization/infopovod';
import { APIUtilError } from '@utils/api';
import { ObjectLiteral, deleteUndefined, isObjectsHasDifferences } from '@utils/object.utils';
import { debounce } from 'lodash';
import { makeAutoObservable, runInAction, toJS } from 'mobx';

export interface InfopovodsViewModelStartParams {
    t: any;
    searchParams: URLSearchParams;
    setSearchParams: (params: any) => void;
    showError: (title: string, description: string) => void;
}

export class InfopovodsViewModel {
    private infopovodModel = new PopularizationInfopovodModel();

    public isLoading: boolean = false;

    public currentPage: number = 1;
    public pageSize: number = 20;
    public availablePageSizes = [10, 20, 50, 100];
    public currentFilter: PopularizationInfopovodFilter = {};

    public infopovodsTotal: number = 0;
    public infopovods: PopularizationInfopovodData[] = [];

    constructor(private props: InfopovodsViewModelStartParams) {
        const incomingFilter = this.getFilterFromSearchParams(props.searchParams);
        this.currentFilter = incomingFilter;
        this.currentPage = incomingFilter.page || 0;
        this.pageSize = incomingFilter.page_size || this.availablePageSizes[1];

        makeAutoObservable(this);
        this.wakeUpSir();
    }

    public async updateSearchParamsIfNeeded(searchParams: URLSearchParams): Promise<Boolean> {
        const newFilter = this.getFilterFromSearchParams(searchParams);
        if (isObjectsHasDifferences(newFilter, toJS(this.currentFilter))) {
            runInAction(() => {
                this.currentPage = newFilter.page || 1;
                this.pageSize = newFilter.page_size || this.availablePageSizes[1];
                this.currentFilter = newFilter;
            });
            await this.fetch(newFilter);
            return true;
        }
        return false;
    }

    private async wakeUpSir() {
        await this.fetch(this.currentFilter);
    }

    private getFilterFromSearchParams = (searchParams: URLSearchParams) => {
        let filter: PopularizationInfopovodFilter = { page: 1, page_size: this.pageSize };
        const keys: PopularizationInfopovodFilterParamName[] = ['page', 'page_size'];

        for (const key of keys) {
            const value = searchParams.get(key);
            if (!value) continue;
            switch (key) {
                case 'page':
                    try {
                        const parsedPage = parseInt(value);
                        filter[key] = parsedPage;
                    } catch {
                        filter[key] = 1;
                    }
                    break;
                case 'page_size':
                    try {
                        const parsedSize = parseInt(value);
                        if (!this.availablePageSizes.includes(parsedSize)) throw new Error();
                        filter.page_size = parsedSize;
                    } catch {
                        filter.page_size = this.availablePageSizes[0];
                    }
                    break;

                default:
                    break;
            }
        }
        return filter;
    };

    private extractSearchParamsFromFilter = (filter: ObjectLiteral) => {
        let searchParams: ObjectLiteral = {};
        for (const key in filter) {
            switch (key) {
                case 'event_id':
                    searchParams.event_id = filter[key];
                    break;
                case 'member_id':
                    searchParams.member_id = filter[key];
                    break;
                case 'type_id':
                    searchParams.type_id = filter[key];
                    break;
                default:
                    break;
            }
        }
        return deleteUndefined(searchParams);
    };

    public onFilterValueChange = (values: ObjectLiteral) => {
        const formsValues = deleteUndefined(values);
        let filter: PopularizationInfopovodFilter = { page: 1, page_size: this.pageSize };

        for (const key in formsValues) {
            switch (key) {
                default:
                    break;
            }
        }
        this.currentPage = 1;
        this.currentFilter = deleteUndefined(filter);

        // const searchParams = this.extractSearchParamsFromFilter(deleteUndefined(filter));
        // this.props.setSearchParams(searchParams as any);
        this.debouncedFetch(deleteUndefined(filter));
    };

    private debouncedFetch = debounce(async (params: PopularizationInfopovodFilter) => {
        this.fetch(params);
    }, 400);

    private async fetch(params: PopularizationInfopovodFilter) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.infopovodModel.get(params);
            runInAction(() => {
                this.infopovods = result.data;
                this.infopovodsTotal = result.total || 0;
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public delete = async (id: number) => {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            await this.infopovodModel.delete(id);
            const updated = this.infopovods.filter((item) => item.id !== id);
            this.infopovods = updated;
            this.infopovodsTotal = this.infopovodsTotal - 1;
        } catch (error) {
            this.props.showError(this.props.t('common.error.empty'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };

    public onChangePagination = async (page: number, pageSize: number) => {
        if (pageSize !== this.pageSize) {
            runInAction(() => {
                this.pageSize = pageSize;
            });
        }
        const filter: PopularizationInfopovodFilter = { ...this.currentFilter, page: page, page_size: pageSize };
        runInAction(() => {
            this.currentPage = page;
            this.currentFilter = filter;
        });

        const currentFiltersSearchParams = this.extractSearchParamsFromFilter(filter);
        this.props.setSearchParams(currentFiltersSearchParams);

        this.fetch(filter);
    };
}
