import { FinanceData, FinanceModel } from '@models/finance-model';
import { APIUtilError, isAPIError } from '@utils/api';
import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { DictionaryModel, FinanceTransactionTypeDictionaryData } from '@models/dictionary-model';
import { FinanceFilterInfo, FinancesFilterParamName, FinancesFilter } from '@interfaces/finances.interface';
import { ObjectLiteral, deleteUndefined, isObjectsHasDifferences } from '@utils/object.utils';
import { ResultTypeModel } from '@models/result-type-model';
import { ResultModel } from '@models/result-model';
import { StartupModel } from '@models/startup-model';
import { StartupProjectModel } from '@models/startup-project-model';
import { debounce } from 'lodash';

export interface FinancesViewModelStartParams {
    t: any;
    searchParams: URLSearchParams;
    setSearchParams: (params: any) => void;
    showError: (title: string, description: string) => void;
}

export class FinancesViewModel {
    private financeModel = new FinanceModel();
    private dictionaryModel = new DictionaryModel();
    private resultTypeModel = new ResultTypeModel();
    private resultModel = new ResultModel();
    private startupModel = new StartupModel();
    private startupProjectModel = new StartupProjectModel();

    public isLoading: boolean = false;

    public currentPage: number = 1;
    public pageSize: number = 20;
    public availablePageSizes = [10, 20, 50, 100];
    public currentFilter: FinancesFilter = {};

    public currentFilterInfo: FinanceFilterInfo[] = [];
    public isCurrentFilterLoading = false;

    public financesTotal: number = 0;
    public finances: FinanceData[] = [];
    public financeOnEdit?: FinanceData;

    public transactionTypes: FinanceTransactionTypeDictionaryData[] = [];

    constructor(private props: FinancesViewModelStartParams) {
        const incomingFilter = this.getFilterFromSearchParams(props.searchParams);
        this.currentFilter = incomingFilter;
        this.currentPage = incomingFilter.page || 0;
        this.pageSize = incomingFilter.page_size || this.availablePageSizes[1];

        makeAutoObservable(this);
        this.wakeUpSir();
    }

    private async wakeUpSir() {
        this.fetchTransationTypes();
        await this.fetchFinances(this.currentFilter);
        await this.prepareFilterInfo(this.currentFilter);
    }

    public onEdit = async (id: number) => {
        const filter: FinancesFilter = { ...this.currentFilter, edit: id };
        runInAction(() => {
            this.currentFilter = filter;
        });
        const currentFiltersSearchParams = this.extractSearchParamsFromFilter(filter);
        this.props.setSearchParams(currentFiltersSearchParams);

        await this.fetchForEdit(`${id}`);
    };

    public onCancelEdit = () => {
        runInAction(() => {
            this.financeOnEdit = undefined;
        });
        this.props.setSearchParams(this.currentFilter);
    };

    private async fetchForEdit(id: number | string) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.financeModel.getFinanceById(id);
            runInAction(() => {
                this.financeOnEdit = result.data;
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public async updateSearchParamsIfNeeded(searchParams: URLSearchParams): Promise<Boolean> {
        const newFilter = this.getFilterFromSearchParams(searchParams);
        if (isObjectsHasDifferences(newFilter, toJS(this.currentFilter))) {
            runInAction(() => {
                this.currentPage = newFilter.page || 1;
                this.pageSize = newFilter.page_size || this.availablePageSizes[1];
                this.currentFilter = newFilter;
            });
            await this.fetchFinances(newFilter);
            return true;
        }
        return false;
    }

    private getFilterFromSearchParams = (searchParams: URLSearchParams) => {
        let filter: FinancesFilter = { page: 1, page_size: this.pageSize };
        const keys: FinancesFilterParamName[] = ['edit', 'result_type_id', 'result_id', 'startup_id', 'startup_project_id', 'page', 'page_size'];

        for (const key of keys) {
            const value = searchParams.get(key);
            if (!value) continue;
            switch (key) {
                case 'edit':
                    filter[key] = parseInt(value);
                    break;
                case 'result_type_id':
                    try {
                        filter[key] = parseInt(value);
                    } catch {
                        filter[key] = undefined;
                    }
                    break;
                case 'result_id':
                    try {
                        filter[key] = parseInt(value);
                    } catch {
                        filter[key] = undefined;
                    }
                    break;
                case 'startup_id':
                case 'startup_project_id':
                    try {
                        filter[key] = parseInt(value);
                    } catch {
                        filter[key] = undefined;
                    }
                    break;
                case 'page':
                    try {
                        const parsedPage = parseInt(value);
                        filter[key] = parsedPage;
                    } catch {
                        filter[key] = 1;
                    }
                    break;
                case 'page_size':
                    try {
                        const parsedSize = parseInt(value);
                        if (!this.availablePageSizes.includes(parsedSize)) throw new Error();
                        filter.page_size = parsedSize;
                    } catch {
                        filter.page_size = this.availablePageSizes[0];
                    }
                    break;

                default:
                    break;
            }
        }
        return filter;
    };

    private extractSearchParamsFromFilter = (filter: ObjectLiteral) => {
        let searchParams: ObjectLiteral = {};
        for (const key in filter) {
            switch (key) {
                default:
                    searchParams[key] = filter[key] ? filter[key] : undefined;
            }
        }
        return deleteUndefined(searchParams);
    };

    public filterInfoCount(filter: FinancesFilter) {
        let count = 0;
        const keys: FinancesFilterParamName[] = ['result_type_id', 'result_id', 'startup_id', 'startup_project_id'];
        for (const key of keys) {
            const value = filter[key];
            if (!value || value === undefined) continue;
            count++;
        }
        return count;
    }

    public async prepareFilterInfo(filter: FinancesFilter) {
        runInAction(() => {
            this.isCurrentFilterLoading = true;
        });

        let info: FinanceFilterInfo[] = [];
        const keys: FinancesFilterParamName[] = ['edit', 'result_type_id', 'result_id', 'startup_id', 'startup_project_id'];
        for (const key of keys) {
            const value = filter[key];
            if (!value || value === undefined) continue;
            switch (key) {
                case 'edit':
                    try {
                        await this.fetchForEdit(filter[key]!);
                    } catch {}
                    break;
                case 'result_type_id':
                    try {
                        const result = await this.resultTypeModel.getResultTypeById(filter[key]!);
                        info.push({
                            type: key,
                            name: this.props.t('finances.filter.result_type_id'),
                            value: result.data.title
                        });
                    } catch {}
                    break;
                case 'result_id':
                    try {
                        const result = await this.resultModel.getById(filter[key]!);
                        info.push({
                            type: key,
                            name: this.props.t('finances.filter.result_id'),
                            value: result.data.title
                        });
                    } catch {}
                    break;
                case 'startup_id':
                    try {
                        const result = await this.startupModel.getStartupById(filter[key]!);
                        info.push({
                            type: key,
                            name: this.props.t('finances.filter.startup_id'),
                            value: result.data.name ? result.data.name : `#${result.data.id} ${this.props.t('startups.content.name.empty')}`
                        });
                    } catch {}
                    break;
                case 'startup_project_id':
                    try {
                        const result = await this.startupProjectModel.getStartupProjectById(filter[key]!);
                        info.push({
                            type: key,
                            name: this.props.t('finances.filter.startup_project_id'),
                            value: result.data.name ? result.data.name : `#${result.data.id} ${this.props.t('startups.content.name.empty')}`
                        });
                    } catch {}
                    break;
                default:
                    break;
            }
        }

        runInAction(() => {
            this.currentFilterInfo = info;
            this.isCurrentFilterLoading = false;
        });
    }

    public onRemoveFilterInfo = (info: FinanceFilterInfo) => {
        let filterCopy = this.currentFilter;
        filterCopy[info.type] = undefined;
        this.onFilterValueChange(filterCopy);
    };

    public onFilterValueChange = (values: ObjectLiteral) => {
        const formsValues = deleteUndefined(values);
        let filter: FinancesFilter = { ...this.currentFilter, page: 1, page_size: this.pageSize };
        for (const key in formsValues) {
            switch (key) {
                case 'result_type_id':
                    filter[key] = formsValues[key];
                    break;
                case 'result_id':
                    filter[key] = formsValues[key];
                    break;
                default:
                    break;
            }
        }

        const searchParams = this.extractSearchParamsFromFilter(deleteUndefined(filter));
        this.props.setSearchParams(searchParams as any);

        runInAction(() => {
            this.currentPage = 1;
            this.currentFilter = deleteUndefined(filter);
        });
        this.debouncedFetch(deleteUndefined(filter));
    };

    private debouncedFetch = debounce(async (params: FinancesFilter) => {
        this.fetchFinances(params);
    }, 400);

    private async fetchTransationTypes() {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.dictionaryModel.getDictionary({ altname: 'transaction-type' });
            runInAction(() => {
                this.transactionTypes = result.data as FinanceTransactionTypeDictionaryData[];
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    private async fetchFinances(params: FinancesFilter) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.financeModel.getFinances(params);
            runInAction(() => {
                this.finances = result.data;
                this.financesTotal = result.total || 0;
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public async update(finance: FinanceData): Promise<string | undefined> {
        runInAction(() => {
            this.isLoading = true;
        });

        try {
            await this.financeModel.update(finance);
            let copy = [...this.finances];
            const index = copy.map((item) => item.id).indexOf(finance.id);
            copy[index] = finance;
            this.finances = copy;
        } catch (error) {
            return isAPIError(error) ? error.localizedDescription : 'Unknown Error';
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public onChangePagination = async (page: number, pageSize: number) => {
        if (pageSize !== this.pageSize) {
            runInAction(() => {
                this.pageSize = pageSize;
            });
        }
        const filter: FinancesFilter = { ...this.currentFilter, page: page, page_size: pageSize };
        runInAction(() => {
            this.currentPage = page;
            this.currentFilter = filter;
        });

        const currentFiltersSearchParams = this.extractSearchParamsFromFilter(filter);
        this.props.setSearchParams(currentFiltersSearchParams);

        this.fetchFinances(filter);
    };
}
