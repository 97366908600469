import { APIInstance } from '@utils/api';
import { deleteUndefined } from '@utils/object.utils';
import {
    ReportsGetFilter,
    ReportsApiResp,
    ReportApiResp,
    ReportApiSchemaResp,
    ReportRequestsResp,
} from './report-api.contract';

export class ReportApiModel {
    public async getReportsApi(params: ReportsGetFilter): Promise<ReportsApiResp> {
        try {
            const result = await APIInstance.get<ReportsApiResp>('/cp-manager/report-api', {
                params: deleteUndefined({
                    search: params.search,
                    limit: params.page_size,
                    offset: params.page_size && params.page ? params.page_size * (params.page - 1) : 0
                })
            });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getReportById(id: number): Promise<ReportApiResp> {
        try {
            const result = await APIInstance.get<ReportApiResp>(`/cp-manager/report-api/${id}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getReportSchema(id: number): Promise<ReportApiSchemaResp> {
        try {
            const result = await APIInstance.get<ReportApiSchemaResp>(`/cp-manager/report-api/${id}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getReportRequests(id: number, params: any): Promise<ReportRequestsResp> {
        try {
            const result = await APIInstance.get<ReportRequestsResp>(`/cp-manager/report-api/${id}/requests`, { params });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getReportRequestsData(id: number, requestId: number) {
        try {
            const result = await APIInstance.get(`/cp-manager/report-api/${id}/requests/${requestId}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async revokeRequest(id: number, requestId: number, revoke: boolean) {
        try {
            await APIInstance.put(`/cp-manager/report-api/${id}/requests/${requestId}/revoke`, { revoke });
        } catch (error) {
            throw error;
        }
    }
}   
