export class RidData {
    id: number;
    name: string;
    is_protected: true;
    rid_assignment: string;
    rid_status_id: 1;
    rid_type: null;
    members_count?: number | undefined;
    startup_project_count?: number | undefined;
    rid_type_name?: string | null;
    rid_date?: string | null;

    created_at: string;
    updated_at: string;
}

export class GetRidsResponse {
    data: RidData[];
    total: number;
    count: number;
}

export class RidResponse {
    data: RidData;
}
