import { APIInstance } from '@utils/api';
import { deleteUndefined } from '@utils/object.utils';
import { GetRidsResponse, RidData, RidResponse } from './rid.contract';
import { RidGetFilter } from '@interfaces/rid.interface';

export class RidModel {
    public async getRids(params: RidGetFilter): Promise<GetRidsResponse> {
        try {
            const result = await APIInstance.get<GetRidsResponse>('/cp-manager/rid', {
                params: deleteUndefined({
                    search: params.search,
                    result_type_id: params.result_type_id ? [params.result_type_id] : undefined,
                    result_id: params.result_id ? [params.result_id] : undefined,
                    startup_id: params.startup_id,
                    startup_project_id: params.startup_project_id,
                    limit: params.page_size,
                    offset: params.page_size && params.page ? params.page_size * (params.page - 1) : 0
                })
            });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getRidById(id: string | number): Promise<RidResponse> {
        try {
            const result = await APIInstance.get<RidResponse>(`/cp-manager/rid/${id}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async update(rid: RidData): Promise<RidResponse> {
        try {
            const result = await APIInstance.put<RidResponse>(`/cp-manager/rid/${rid.id}`, { ...rid });
            return result.data;
        } catch (error) {
            throw error;
        }
    }
}
