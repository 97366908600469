import { ReportAttachedFile, ReportData, ReportGetFilesFilter, ReportGetUsersFilter, ReportModel, ReportUser, ReportType } from '@models/report';
import { User, UserModel, UsersGetFilter } from '@models/users-model';
import { APIUtilError } from '@utils/api';
import { deleteUndefined } from '@utils/object.utils';
import { debounce } from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';
import { stringToDate } from '@utils/date.utils';

export interface ReportEditViewModelStartParams {
    t: any;
    reportId?: string | undefined;
    showError: (title: string, description: string) => void;
    onFinish: () => void;
}

export class ReportEditViewModel {
    private reportModel = new ReportModel();
    private userModel = new UserModel();
    public isLoading: boolean = false;
    public report: ReportData = new ReportData();
    public reportTypes: ReportType[] = [];
    public reportFile: any[] = [];

    public availablePageSizes = [10, 20, 50, 100];

    public filesPage: number = 1;
    public filesPageSize: number = this.availablePageSizes[0];
    public filesIsLoading: boolean = false;
    public filesFilter: ReportGetFilesFilter;
    public files: ReportAttachedFile[] = [];
    public filesTotal: number = 0;

    public connectedUsersIsLoading: boolean = false;
    public connectedUsersPage: number = 1;
    public connectedUsersPageSize: number = this.availablePageSizes[0];
    public connectedUsersFilter: ReportGetUsersFilter;
    public connectedUsers: ReportUser[] = [];
    public connectedUsersTotal: number = 0;

    public searchUsersIsLoading: boolean = false;
    public searchUsersPage: number = 1;
    public searchUsersPageSize: number = this.availablePageSizes[0];
    public searchUsersFilter: UsersGetFilter;
    public searchUsers: User[] = [];
    public searchUsersTotal: number = 0;

    constructor(private startParams: ReportEditViewModelStartParams) {
        makeAutoObservable(this);
        this.wakeUpSir();
    }

    private async wakeUpSir() {
        await this.fetchReportTypes();

        if (this.startParams.reportId && this.startParams.reportId.length > 0 && Number(this.startParams.reportId)) {
            await this.fetchReport(this.startParams.reportId);

            const filesFilter: ReportGetFilesFilter = {
                reportId: Number(this.startParams.reportId),
                page: this.filesPage,
                page_size: this.filesPageSize
            };
            this.filesFilter = filesFilter;
            await this.fetchFiles(filesFilter);

            const connectedUsersFilter: ReportGetUsersFilter = {
                reportId: Number(this.startParams.reportId),
                page: this.filesPage,
                page_size: this.filesPageSize
            };
            this.connectedUsersFilter = connectedUsersFilter;
            await this.fetchConnectedUsers(connectedUsersFilter);

            const usersFilter: UsersGetFilter = {
                page: this.filesPage,
                page_size: 10
            };
            this.searchUsersFilter = usersFilter;
            await this.fetchUsers(usersFilter);
        }
    }

    private async fetchReport(reportId: string) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.reportModel.getReportById(parseInt(reportId));
            runInAction(() => {
                this.report = result.data;
                this.reportFile = result.data.file_id
                    ? [
                          {
                              uid: `${result.data.file_uuid}`,
                              name: result.data.file_name,
                              title: result.data.file_name,
                              size: result.data.size,
                              lastModifiedDate: stringToDate(result.data.file_updated_at),
                              downloads: 0
                          }
                      ]
                    : [];
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    private async fetchReportTypes() {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.reportModel.fetchReportTypes();
            runInAction(() => {
                this.reportTypes = result.data;
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    private async fetchFiles(filter: ReportGetFilesFilter) {
        switch (this.report.type_id) {
            case 1:
                return await this.fetchFilesFunc(filter);
            case 2:
                return await this.fetchObuchEventsFunc(filter);
        }
    }

    private async fetchFilesFunc(filter: ReportGetFilesFilter) {
        runInAction(() => {
            this.filesIsLoading = true;
        });
        try {
            const filesResponse = await this.reportModel.getReportFiles(filter);
            runInAction(() => {
                this.files = filesResponse.data;
                this.filesTotal = filesResponse.total;
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.filesIsLoading = false;
            });
        }
    }

    private async fetchObuchEventsFunc(filter: ReportGetFilesFilter) {
        runInAction(() => {
            this.filesIsLoading = true;
        });
        try {
            const filesResponse = await this.reportModel.getReportObuchEvents(filter);
            runInAction(() => {
                this.files = filesResponse.data;
                this.filesTotal = filesResponse.total;
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.filesIsLoading = false;
            });
        }
    }

    private async fetchConnectedUsers(filter: ReportGetUsersFilter) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const usersResponse = await this.reportModel.getReportUsers(filter);
            runInAction(() => {
                this.connectedUsers = usersResponse.data;
                this.connectedUsersTotal = usersResponse.total;
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    private async fetchUsers(params: UsersGetFilter) {
        runInAction(() => {
            this.searchUsersIsLoading = true;
        });
        try {
            const result = await this.userModel.getUsers(params);
            runInAction(() => {
                this.searchUsers = result.data;
                this.searchUsersTotal = result.total;
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.searchUsersIsLoading = false;
            });
        }
    }

    public async updateReport(data: ReportData) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            if (this.startParams.reportId)
                await this.reportModel.updateReport(String(this.startParams.reportId), { name: data.name, type_id: data.type_id });
            else {
                await this.reportModel.createReport({ name: data.name, type_id: data.type_id });
                this.startParams.onFinish();
            }
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public onSearchUsers = (value: string) => {
        let filter: UsersGetFilter = { page: 1, page_size: this.searchUsersPageSize, search: value };
        runInAction(() => {
            this.searchUsersFilter = deleteUndefined(filter);
        });
        this.debouncedFetch(deleteUndefined(filter));
    };

    private debouncedFetch = debounce(async (params: UsersGetFilter) => {
        this.fetchUsers(params);
    }, 400);

    public onSearchConnectedUsers = (value: string) => {
        let filter: ReportGetUsersFilter = {
            reportId: Number(this.startParams.reportId),
            search: value,
            page: 1,
            page_size: this.searchUsersPageSize
        };
        runInAction(() => {
            this.connectedUsersFilter = deleteUndefined(filter);
        });
        this.debouncedConnectedUsersFetch(filter);
    };

    private debouncedConnectedUsersFetch = debounce(async (params: ReportGetUsersFilter) => {
        this.fetchConnectedUsers(params);
    }, 400);

    public onChangeConnectedUsersPagination = async (page: number, pageSize: number) => {
        if (pageSize !== this.connectedUsersPageSize) {
            runInAction(() => {
                this.connectedUsersPageSize = pageSize;
            });
        }
        const filter: ReportGetUsersFilter = { ...this.connectedUsersFilter, page: page, page_size: pageSize };
        runInAction(() => {
            this.connectedUsersPage = page;
            this.connectedUsersFilter = filter;
        });
        this.fetchConnectedUsers(filter);
    };

    public async addUsers(userIds: string[]) {
        runInAction(() => {
            this.connectedUsersIsLoading = true;
        });
        try {
            for (const id of userIds) {
                await this.reportModel.addUserToReport(Number(this.startParams.reportId), id);
            }
            await this.fetchConnectedUsers(this.connectedUsersFilter);
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.save'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.connectedUsersIsLoading = false;
            });
        }
    }

    public async deleteUser(userId: string) {
        runInAction(() => {
            this.connectedUsersIsLoading = true;
        });
        try {
            await this.reportModel.deleteUserFromReport(Number(this.startParams.reportId), userId);
            const updatedUsers = this.connectedUsers.filter((item) => item.user_id !== userId);
            runInAction(() => {
                this.connectedUsers = updatedUsers;
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.empty'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.connectedUsersIsLoading = false;
            });
        }
    }

    public onChangeFilesPagination = async (page: number, pageSize: number) => {
        if (pageSize !== this.filesPageSize) {
            runInAction(() => {
                this.filesPageSize = pageSize;
            });
        }
        const filter: ReportGetFilesFilter = { ...this.filesFilter, page: page, page_size: pageSize };
        runInAction(() => {
            this.filesPage = page;
            this.filesFilter = filter;
        });
        this.fetchFiles(filter);
    };

    // FILES

    public getUploaderURL() {
        return this.reportModel.uploadURL(this.report?.id);
    }

    public getUploaderHeaders() {
        return this.reportModel.uploadHeaders;
    }

    public getDownloadLink(uid: string) {
        return this.reportModel.downloadURL(this.report?.id, uid);
    }

    public downloadFile = async (file: ReportAttachedFile) => {
        try {
            await this.reportModel.downloadFileFromReport(file.url, file.name);
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        }
    };

    public async deleteFile(id: number): Promise<boolean> {
        try {
            await this.reportModel.deleteFileFromReport(Number(this.startParams.reportId), id);
            const updatedFiles = this.files.filter((item) => item.id !== id);
            runInAction(() => {
                this.files = updatedFiles;
            });
            return true;
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.empty'), (error as APIUtilError).localizedDescription);
            return false;
        }
    }
}
