import { APIInstance } from '@utils/api';
import { plainToInstance } from 'class-transformer';
import {
    GetIndicatorsResponse,
    Indicator,
    IndicatorAdditionalValue,
    IndicatorAdditionalValuesResponse,
    IndicatorFormulaPart,
    IndicatorResponse,
    IndicatorValueResponse
} from './indicator.contract';
import { deleteUndefined } from '@utils/object.utils';

export interface GetIndicatorParams {}

export class IndicatorModel {
    public async get(params: GetIndicatorParams): Promise<GetIndicatorsResponse> {
        try {
            const result = await APIInstance.get<GetIndicatorsResponse>('/cp-manager/indicator');
            let instancedData = result.data;
            instancedData.data = instancedData.data.map((item) => {
                let indicator = plainToInstance(Indicator, item);
                indicator.indicator_formula = plainToInstance(IndicatorFormulaPart, indicator.indicator_formula);
                return indicator;
            });
            return instancedData;
        } catch (error) {
            throw error;
        }
    }

    public async getIndicatorById(id: string): Promise<IndicatorResponse> {
        try {
            const result = await APIInstance.get<IndicatorResponse>(`/cp-manager/indicator/${id}`);
            let instancedData: IndicatorResponse = new IndicatorResponse();
            instancedData.data = plainToInstance(Indicator, result.data.data);
            instancedData.data.indicator_formula = plainToInstance(IndicatorFormulaPart, instancedData.data.indicator_formula);

            return instancedData;
        } catch (error) {
            throw error;
        }
    }

    public async create(indicator: Indicator): Promise<IndicatorResponse> {
        try {
            const result = await APIInstance.post<IndicatorResponse>('/cp-manager/indicator', { ...indicator });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async update(indicator: Indicator): Promise<IndicatorResponse> {
        try {
            const result = await APIInstance.patch<IndicatorResponse>(`/cp-manager/indicator/${indicator.id}`, { ...indicator });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getIndicatorValues(params: {
        month_id?: string | number;
        indicator_id?: string | number;
        page?: number;
        page_size?: number;
    }): Promise<IndicatorValueResponse> {
        try {
            const result = await APIInstance.get<IndicatorValueResponse>('/cp-manager/indicator_value', {
                params: deleteUndefined({
                    month_id: params.month_id,
                    indicator_id: params.indicator_id,
                    limit: params.page_size,
                    offset: params.page_size && params.page ? params.page_size * (params.page - 1) : 0
                })
            });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async updateIndicatorValue(
        id: string | number,
        data: {
            value?: number;
            is_locked?: boolean;
        }
    ): Promise<IndicatorValueResponse> {
        try {
            const result = await APIInstance.patch<IndicatorValueResponse>(
                `/cp-manager/indicator_value/${id}`,
                deleteUndefined({
                    value: data.value,
                    is_locked: data.is_locked
                })
            );
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    //
    // Additional values
    //
    public async getAdditionalValues(params: {
        month_id?: string | number;
        indicator_id?: string | number;
        tag?: string;
        page?: number;
        page_size?: number;
    }): Promise<IndicatorAdditionalValuesResponse> {
        try {
            const result = await APIInstance.get<IndicatorAdditionalValuesResponse>('/cp-manager/indicator_value/additional', {
                params: deleteUndefined({
                    indicator_id: params.indicator_id,
                    tag: params.tag,
                    month_id: params.month_id,
                    limit: params.page_size,
                    offset: params.page_size && params.page ? params.page_size * (params.page - 1) : 0
                })
            });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async createAdditionalValue(data: {
        indicator_id: string | number;
        month_id: number;
        add_value: number;
        tag: string;
        is_active: boolean;
    }): Promise<{ data: IndicatorAdditionalValue }> {
        try {
            const result = await APIInstance.post<{ data: IndicatorAdditionalValue }>('/cp-manager/indicator_value/additional', {
                ...data
            });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async updateAdditionalValue(
        id: string | number,
        data: {
            add_value?: number;
            is_active?: boolean;
        }
    ) {
        try {
            await APIInstance.patch(
                `/cp-manager/indicator_value/additional/${id}`,
                deleteUndefined({
                    add_value: data.add_value,
                    is_active: data.is_active
                })
            );
        } catch (error) {
            throw error;
        }
    }

    public async deleteAdditionalValue(id: string | number) {
        try {
            await APIInstance.delete(`/cp-manager/indicator_value/additional/${id}`);
        } catch (error) {
            throw error;
        }
    }
}
