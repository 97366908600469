import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Divider, Form, Input, Typography, notification, Row, Col, Button, Space } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useViewModel } from '@models/model';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { ObjectLiteral } from '@utils/object.utils';
import { ReportsViewModel } from './reports.view.model';
import { ContentTable } from './table';
import { ReportData } from '@models/report';
import { windowOpen } from '@utils/window.utils';

const ReportsView: React.FC = observer(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [search, setSearch] = useSearchParams();
    const setSearchParams = (params: any) => setSearch(params);

    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    const viewModel = useViewModel(() => new ReportsViewModel({ t, searchParams: search, setSearchParams, showError: showErrorNotification }));
    const [filterForm] = Form.useForm();

    useEffect(() => {
        updateIfNeeded();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const updateIfNeeded = async () => {
        const isResetNeeded = await viewModel.updateSearchParamsIfNeeded(search);
        if (isResetNeeded) {
            filterForm.resetFields();
        }
    };

    const onEdit = (reportId?: number | undefined) => {
        if (!reportId) return;
        windowOpen(`/reports-manage/edit/${reportId}`);
    };

    const onFilterValueChange = () => {
        const valFromFields: ObjectLiteral = filterForm.getFieldsValue();
        viewModel.onFilterValueChange(valFromFields);
    };

    const onCreate = () => {
        navigate('create');
    };

    return (
        <div>
            {contextHolder}

            <Row gutter={12} align="middle" justify="space-between">
                <Col>
                    <Typography.Title>{t('reports.title')}</Typography.Title>
                </Col>
                <Col>
                    <Space>
                        <Button type="primary" icon={<PlusCircleOutlined />} onClick={onCreate}>
                            {t('common.create')}
                        </Button>
                    </Space>
                </Col>
            </Row>

            <Divider style={{ marginTop: 0 }} />

            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <Form form={filterForm} initialValues={viewModel.currentFilter} layout="vertical" onValuesChange={onFilterValueChange}>
                    <Form.Item style={{ margin: 0 }} name={'search'}>
                        <Input prefix={<SearchOutlined />} size="large" allowClear placeholder={t('reports.filter.search.placeholder') || ''} />
                    </Form.Item>
                </Form>

                <ContentTable
                    currentPage={viewModel.currentPage}
                    loading={viewModel.isLoading}
                    rows={viewModel.reports}
                    total={viewModel.reportsTotal}
                    pageSize={viewModel.pageSize}
                    onChangePagination={viewModel.onChangePagination}
                    onEdit={(report: ReportData) => onEdit(report.id)}
                    onDelete={(report: ReportData) => (report.id ? viewModel.delete(report.id) : {})}
                />
            </Space>
        </div>
    );
});

export default ReportsView;
