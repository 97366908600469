import { APIInstance } from '@utils/api';
import { GetAirflowProcessesResponse } from './airflow.contract';

export class AirflowModel {
    public async getProcesses(): Promise<GetAirflowProcessesResponse> {
        try {
            const result = await APIInstance.get<GetAirflowProcessesResponse>('/cp-manager/airflow/stats');
            return result.data;
        } catch (error) {
            throw error;
        }
    }
}
