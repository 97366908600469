import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Breadcrumb, Divider, Form, Input, Skeleton, Space, Tag, Typography, notification } from 'antd';
import { useViewModel } from '@models/model';
import { useTranslation } from 'react-i18next';
import { ResultsViewModel } from './results.view.model';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { ContentTable } from './table';
import { ResultData } from '@models/result-model';
import { SearchOutlined } from '@ant-design/icons';
import { ObjectLiteral } from '@utils/object.utils';

const ResultsView: React.FC = observer(() => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [search, setSearch] = useSearchParams();
    const setSearchParams = (params: any) => setSearch(params);

    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    const viewModel = useViewModel(() => new ResultsViewModel({ t, searchParams: search, setSearchParams, showError: showErrorNotification }));
    const [filterForm] = Form.useForm();

    useEffect(() => {
        updateIfNeeded();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const updateIfNeeded = async () => {
        const isResetNeeded = await viewModel.updateSearchParamsIfNeeded(search);
        if (isResetNeeded) {
            filterForm.resetFields();
        }
    };

    const onShowMembers = (result: ResultData) => {
        navigate({
            pathname: '/members-manage',
            search: createSearchParams({
                result_id: `${result.id}`
            }).toString()
        });
    };

    const onShowEvents = (result: ResultData) => {
        if (!result.id) return;
        navigate({
            pathname: '/events-manage',
            search: createSearchParams({
                result_id: `${result.id}`
            }).toString()
        });
    };

    const onShowStartups = (result: ResultData) => {
        if (!result.id) return;
        navigate({
            pathname: '/startups-manage',
            search: createSearchParams({
                result_id: `${result.id}`
            }).toString()
        });
    };

    const onShowRids = (result: ResultData) => {
        if (!result.id) return;
        navigate({
            pathname: '/rids-manage',
            search: createSearchParams({
                result_id: `${result.id}`
            }).toString()
        });
    };

    const onShowFinances = (resultType: ResultData) => {
        if (!resultType.id) return;
        navigate({
            pathname: '/finances-manage',
            search: createSearchParams({
                result_id: `${resultType.id}`
            }).toString()
        });
    };

    const onFilterValueChange = () => {
        const valFromFields: ObjectLiteral = filterForm.getFieldsValue();
        viewModel.onFilterValueChange(valFromFields);
    };

    const titleText = `${t('result.title')}: ${viewModel.resultType?.title || ''}`;

    return (
        <div style={{ marginTop: 10 }}>
            {contextHolder}
            <Breadcrumb items={[{ title: <a href="/result-types-manage">{t('result_types.title')}</a> }, { title: titleText }]} />
            {viewModel.isResultTypeLoading ? (
                <Typography.Title>
                    <Skeleton.Input active={true} size="large" />
                </Typography.Title>
            ) : (
                <Space>
                    <Typography.Title>
                        {`${t('result.title')}:`}
                        <Tag color={viewModel.resultType?.primary_color ? viewModel.resultType.primary_color : undefined}>
                            <Typography.Title style={{ margin: 0 }}>{`${viewModel.resultType?.title || ''}`}</Typography.Title>
                        </Tag>
                    </Typography.Title>
                    <Typography.Title style={{ marginTop: 25 }} level={2} type="secondary">
                        {viewModel.resulsTotal}
                    </Typography.Title>
                </Space>
            )}

            <Divider style={{ marginTop: 0 }} />
            <Form form={filterForm} initialValues={viewModel.currentFilter} layout="vertical" onValuesChange={onFilterValueChange}>
                <Form.Item style={{ margin: 0 }} name={'search'}>
                    <Input prefix={<SearchOutlined />} size="large" allowClear placeholder={t('events.filter.search.placeholder') || ''} />
                </Form.Item>
            </Form>
            <br />
            <ContentTable
                currentPage={viewModel.currentPage}
                pageSize={viewModel.pageSize}
                rows={viewModel.results}
                loading={viewModel.isLoading}
                total={viewModel.resulsTotal}
                onShowMembers={onShowMembers}
                onShowEvents={onShowEvents}
                onShowStartups={onShowStartups}
                onShowRids={onShowRids}
                onShowFinances={onShowFinances}
                onChangePagination={viewModel.onChangePagination}
            />
            <br />
        </div>
    );
});

export default ResultsView;
