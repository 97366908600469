import { APIInstance } from '@utils/api';
import { GetResultTypesResponse, ResultTypeData, ResultTypeResponse } from './result-type.contract';

export class ResultTypeModel {
    public async getResultTypes(): Promise<GetResultTypesResponse> {
        try {
            const q_result = await APIInstance.get<GetResultTypesResponse>('/cp-manager/result-type');
            return q_result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getResultTypeById(id: string | number): Promise<ResultTypeResponse> {
        try {
            const q_result = await APIInstance.get<ResultTypeResponse>(`/cp-manager/result-type/${id}`);
            return q_result.data;
        } catch (error) {
            throw error;
        }
    }

    public async create(result: ResultTypeData): Promise<ResultTypeResponse> {
        try {
            const q_result = await APIInstance.post<ResultTypeResponse>('/cp-manager/result-type', { ...result });
            return q_result.data;
        } catch (error) {
            throw error;
        }
    }

    public async update(resultType: ResultTypeData): Promise<ResultTypeResponse> {
        try {
            const q_result = await APIInstance.put<ResultTypeResponse>(`/cp-manager/result-type/${resultType.id}`, { ...resultType });
            return q_result.data;
        } catch (error) {
            throw error;
        }
    }

    public async delete(id: number) {
        try {
            const q_result = await APIInstance.delete(`/cp-manager/result-type/${id}`);
            return q_result.data;
        } catch (error) {
            throw error;
        }
    }
}
