import { parseISO } from 'date-fns';
import { format, toDate } from 'date-fns-tz';

export const formatDate = (date: string | Date | undefined, pattern: string): string => {
    if (!date) return format(new Date(), pattern);
    return format(date instanceof Date ? date : parseISO(date), pattern);
};

export const stringToDate = (str: string | undefined): Date => {
    try {
        return toDate(str || new Date().toISOString());
    } catch {
        return toDate(new Date().toISOString());
    }
};
