import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import '@assets/css/App.css';
import { useViewModel } from '@models/model';
import { Indicator } from '@models/indicator-model';
import { IndicatorEditViewModel } from './indicator.edit.view.model';
import { Badge, Button, Col, Collapse, Form, Input, notification, Row, Spin, Switch, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { RobotOutlined } from '@ant-design/icons';
import { plainToInstance } from 'class-transformer';
import FormulaEditView from './formula.edit.view';
import { toJS } from 'mobx';
import { useNavigate, useParams } from 'react-router-dom';
import { IndicatorValueEdit } from './indicator-value/value.edit';
import { AdditionalValuesEdit } from './additional-values';

const IndicatorEditView: React.FC = observer(() => {
    const { t } = useTranslation();
    const { id: indicatorId } = useParams();
    const navigate = useNavigate();

    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (description: string) => {
        notifyer.error({
            message: t('common.error.empty'),
            description: description
        });
    };
    const showSuccessNotification = (title: string) => {
        notifyer.success({
            message: title
        });
    };

    const onFinish = () => {
        showSuccessNotification(t('common.saved'));
    };

    const onClickCancel = () => {
        navigate('/indicator-manage');
    };

    const viewModel = useViewModel(
        () => new IndicatorEditViewModel(indicatorId, (description: string) => showErrorNotification(description), onFinish)
    );
    const [form] = Form.useForm<Indicator>();
    const [isAltNameAuto, setIsAltNameAuto] = useState(true);
    const [generatedAltName, setGeneratedAltName] = useState(viewModel.indicator.alt_title || '');
    const [formulaValue, setFormulaValue] = useState(toJS(viewModel.indicator.indicator_formula));

    useEffect(() => {
        form.resetFields();
        setGeneratedAltName(viewModel.indicator.alt_title);
        setIsAltNameAuto(viewModel.indicator.alt_title === viewModel.generateAltName(viewModel.indicator.title));
        setFormulaValue(toJS(viewModel.indicator.indicator_formula) || []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewModel.indicator]);

    const onChangeTitle = (value: string) => {
        setGeneratedAltName(viewModel.generateAltName(value));
    };

    const onChangeAltNameSwitch = () => {
        setIsAltNameAuto(!isAltNameAuto);
        form.setFieldsValue({ alt_title: generatedAltName });
    };

    const onClickSave = () => form.submit();

    const onSuccessFormValidation = async () => {
        const data = getFormData();
        await viewModel.onSave(data);
    };

    const getFormData = () => {
        const valFromFields: Record<string, any> = form.getFieldsValue();
        var converted: Indicator = plainToInstance(Indicator, {
            ...viewModel.indicator,
            ...valFromFields,
            indicator_formula: formulaValue
        });

        if (isAltNameAuto) {
            converted.alt_title = generatedAltName;
        }
        return converted;
    };

    return (
        <div>
            <IndicatorValueEdit
                indicatorId={indicatorId || ''}
                isVisible={viewModel.isIndicatorValueEdit}
                onClose={() => viewModel.toggleIndicatorValueEdit()}
            />
            <AdditionalValuesEdit
                indicatorId={indicatorId || ''}
                resultTypes={viewModel.resultTypes}
                tag={viewModel.additionalValueEditTag}
                onClose={() => viewModel.setAdditionalValueEditTag(undefined)}
            />
            <br />
            <Spin spinning={viewModel.isLoading}>
                {contextHolder}
                <Row gutter={12} justify="end" align="middle">
                    <Col>
                        <Button onClick={onClickCancel}>{t('common.cancel')}</Button>
                    </Col>
                    <Col>
                        <Button type="primary" onClick={onClickSave} loading={viewModel.isLoading} disabled={viewModel.isLoading}>
                            {viewModel.indicator.id !== undefined ? t('common.save') : t('common.create')}
                        </Button>
                    </Col>
                </Row>
                <br />
                <div className="edit-content-view">
                    <Form form={form} initialValues={viewModel.indicator} layout="horizontal" onFinish={onSuccessFormValidation}>
                        <Form.Item
                            name="title"
                            label={t('indicator.content.title.title')}
                            rules={[{ required: true, message: t('indicator.content.title.required') || '' }]}
                        >
                            <Input
                                placeholder={t('indicator.content.title.placeholder') || ''}
                                onChange={(event) => onChangeTitle(event.currentTarget.value)}
                            />
                        </Form.Item>
                        <Form.Item
                            name="alt_title"
                            label={t('indicator.content.alt_name.title')}
                            tooltip={t('indicator.content.alt_name.hint')}
                            required
                        >
                            <Row gutter={12}>
                                <Col span={20}>
                                    {isAltNameAuto === true ? (
                                        <Form.Item noStyle>
                                            <Input disabled value={generatedAltName} />
                                        </Form.Item>
                                    ) : (
                                        <Form.Item
                                            name="alt_title"
                                            rules={[{ required: true, message: t('indicator.content.alt_name.required') || '' }]}
                                        >
                                            <Input placeholder={t('indicator.content.alt_name.placeholder') || ''} />
                                        </Form.Item>
                                    )}
                                </Col>
                                <Col span={4}>
                                    <Form.Item label={<RobotOutlined />} tooltip={t('indicator.content.alt_name.auto')}>
                                        <Switch checked={isAltNameAuto} onChange={onChangeAltNameSwitch} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form.Item>
                        <Form.Item
                            name="short_description"
                            label={t('indicator.content.short_description.title')}
                            rules={[{ required: true, message: t('indicator.content.short_description.required') || '' }]}
                        >
                            <Input placeholder={t('indicator.content.short_description.placeholder') || ''} />
                        </Form.Item>
                        <Form.Item
                            name="description"
                            label={t('indicator.content.description.title')}
                            rules={[{ required: true, message: t('indicator.content.description.required') || '' }]}
                        >
                            <Input.TextArea
                                style={{ maxHeight: '50px' }}
                                showCount
                                maxLength={255}
                                placeholder={t('indicator.content.description.placeholder') || ''}
                            />
                        </Form.Item>

                        <Form.Item
                            name="indicator_controller"
                            label={t('indicator.content.indicator_controller.title')}
                            rules={[{ required: true, message: t('indicator.content.indicator_controller.required') || '' }]}
                        >
                            <Input placeholder={t('indicator.content.indicator_controller.placeholder') || ''} />
                        </Form.Item>
                    </Form>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: '10px',
                            marginBottom: '20px'
                        }}
                    >
                        <Typography.Text>{t('indicator.content.indicator_value.title')}: </Typography.Text>
                        <Tooltip title={t('indicator.content.indicator_value.edit.hint')}>
                            <Badge count={viewModel.indicator.value_count} showZero>
                                <Button color="primary" variant="outlined" onClick={() => viewModel.toggleIndicatorValueEdit()}>
                                    {viewModel.indicator.indicator_value}
                                </Button>
                            </Badge>
                        </Tooltip>
                    </div>
                    <Collapse
                        defaultActiveKey={['1']}
                        items={[
                            {
                                key: '1',
                                label: t('indicator.content.indicator_formula.title'),
                                children: (
                                    <FormulaEditView
                                        formula={formulaValue}
                                        resultTypes={viewModel.resultTypes}
                                        onEdit={setFormulaValue}
                                        onClickEditAdditional={viewModel.setAdditionalValueEditTag}
                                    />
                                )
                            }
                        ]}
                    />

                    <br />
                </div>
            </Spin>
        </div>
    );
});

export default IndicatorEditView;
