import { APIInstance } from '@utils/api';
import { deleteUndefined, ObjectLiteral } from '@utils/object.utils';
import { DictionaryScheme, GetDictionaryListResponse, GetDictionaryResponse, GetDictionarySchemeResponse } from './dictionary.contract';
import { saveAs } from 'file-saver';

export interface GetDictionaryParams {
    altname: string;
    search?: string;
    page?: number | undefined;
    page_size?: number | undefined;
}

export class DictionaryModel {
    public async getDictionaries(): Promise<GetDictionaryListResponse> {
        try {
            const result = await APIInstance.get<GetDictionaryListResponse>(`/cp-manager/dictionary`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getDictionary(params: GetDictionaryParams): Promise<GetDictionaryResponse> {
        try {
            const result = await APIInstance.get<GetDictionaryResponse>(`/cp-manager/dictionary/${params.altname}`, {
                params: deleteUndefined({
                    search: params.search,
                    limit: params.page_size,
                    offset: params.page_size && params.page ? params.page_size * (params.page - 1) : 0
                })
            });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getScheme(altname: string): Promise<GetDictionarySchemeResponse> {
        try {
            const result = await APIInstance.get<GetDictionarySchemeResponse>(`/cp-manager/dictionary/${altname}/scheme`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public getPrimaryKey(scheme: DictionaryScheme): string | undefined {
        const markedAsPrimary = scheme.columns.filter((item) => item.is_primary === true);
        if (markedAsPrimary.length === 0) return undefined;
        return markedAsPrimary[0].key;
    }

    public async createDictionaryItem(altname: string, data: ObjectLiteral) {
        try {
            const result = await APIInstance.post(`/cp-manager/dictionary/${altname}`, data);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getDictionaryItemById(altname: string, id: string | number) {
        try {
            const result = await APIInstance.get(`/cp-manager/dictionary/${altname}/${id}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async updateDictionaryItem(altname: string, id: string | number, data: ObjectLiteral) {
        try {
            const result = await APIInstance.put(`/cp-manager/dictionary/${altname}/${id}`, data);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async deleteDictionaryItem(altname: string, id: string | number) {
        try {
            const result = await APIInstance.delete(`/cp-manager/dictionary/${altname}/${id}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async downloadExcelFile(altname: string, filename: string) {
        await APIInstance({
            url: `/cp-manager/dictionary/${altname}/export/excel`,
            method: 'GET',
            responseType: 'blob'
        }).then((response) => {
            saveAs(response.data, filename);
        });
    }
}
