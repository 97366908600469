import { CompressOutlined, LinkOutlined, SettingOutlined } from '@ant-design/icons';
import { RootModel } from '@models/root-model';
import { FloatButton } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export const RootSettingsView = observer(() => {
    const { t } = useTranslation();

    return (
        <FloatButton.Group key={'top'} trigger="click" placement={'top'} style={{}} icon={<SettingOutlined />}>
            <FloatButton
                type={RootModel.appModel.isCompact ? 'primary' : 'default'}
                onClick={() => RootModel.appModel.toggleCompact()}
                icon={<CompressOutlined />}
                tooltip={t('root.settings.compactMode')}
            />
            <FloatButton
                type={RootModel.appModel.isNewTab ? 'primary' : 'default'}
                onClick={() => RootModel.appModel.toggleNewTab()}
                icon={<LinkOutlined />}
                tooltip={t('root.settings.newTab')}
            />
        </FloatButton.Group>
    );
});
