import { useState } from 'react';
import { Table, Form, Tooltip, Button, Typography } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ResultData } from '@models/result-model';
import { CalculatorOutlined, ExperimentOutlined, RocketOutlined, UnorderedListOutlined, UsergroupAddOutlined } from '@ant-design/icons';

interface TableProps {
    currentPage: number;
    pageSize: number;
    rows: ResultData[];
    total: number;
    loading: boolean;
    onChangePagination?: (page: number, pageSize: number) => void;
    onShowMembers: (result: ResultData) => void;
    onShowEvents: (result: ResultData) => void;
    onShowStartups: (result: ResultData) => void;
    onShowRids: (result: ResultData) => void;
    onShowFinances: (resultType: ResultData) => void;
}

export const ContentTable = (props: TableProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [data, setData] = useState<ResultData[]>([]);

    useEffect(() => {
        setData(props.rows);
    }, [props]);

    const columns = [
        {
            title: 'ID',
            width: '3%',
            dataIndex: 'id'
        },
        {
            title: t('result.content.title.title'),
            dataIndex: 'title'
        },
        {
            title: t('result.content.operator.title'),
            render: (_: any, record: ResultData) => {
                return <div>{record.operator.name}</div>;
            }
        },
        {
            title: t('result.content.member_count.title'),
            render: (_: any, record: ResultData) => {
                return (
                    <Tooltip title={t('result.content.member_count.show')}>
                        <Button
                            style={{ padding: 0, paddingTop: 0, paddingLeft: 5, paddingRight: 5 }}
                            type="text"
                            onClick={() => props.onShowMembers(record)}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                                <UsergroupAddOutlined style={{ fontSize: 18, color: 'gray' }} />
                                <Typography.Text style={{ fontSize: 18, paddingTop: 4, paddingLeft: 4 }}>
                                    {new Intl.NumberFormat('ru-ru', {}).format(record.member_count || 0)}
                                </Typography.Text>
                            </div>
                        </Button>
                    </Tooltip>
                );
            }
        },
        {
            title: t('result.content.event_count.title'),
            render: (_: any, record: ResultData) => {
                return (
                    <Tooltip title={t('result.content.event_count.description')}>
                        <Button
                            style={{ padding: 0, paddingTop: 0, paddingLeft: 5, paddingRight: 5 }}
                            type="text"
                            onClick={() => props.onShowEvents(record)}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                                <UnorderedListOutlined style={{ fontSize: 18, color: 'gray' }} />
                                <Typography.Text style={{ fontSize: 18, paddingTop: 4, paddingLeft: 4 }}>
                                    {new Intl.NumberFormat('ru-ru', {}).format(record.event_count || 0)}
                                </Typography.Text>
                            </div>
                        </Button>
                    </Tooltip>
                );
            }
        },
        {
            title: t('result.content.startup_count.title'),
            render: (_: any, record: ResultData) => {
                return (
                    <Tooltip title={t('result.content.startup_count.description')}>
                        <Button
                            style={{ padding: 0, paddingTop: 0, paddingLeft: 5, paddingRight: 5 }}
                            type="text"
                            onClick={() => props.onShowStartups(record)}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                                <RocketOutlined style={{ fontSize: 18, color: 'gray' }} />
                                <Typography.Text style={{ fontSize: 18, paddingTop: 4, paddingLeft: 4 }}>
                                    {new Intl.NumberFormat('ru-ru', {}).format(record.startup_count || 0)}
                                </Typography.Text>
                            </div>
                        </Button>
                    </Tooltip>
                );
            }
        },
        {
            title: t('result.content.rid_count.title'),
            render: (_: any, record: ResultData) => {
                return (
                    <Tooltip title={t('result.content.rid_count.description')}>
                        <Button
                            style={{ padding: 0, paddingTop: 0, paddingLeft: 5, paddingRight: 5 }}
                            type="text"
                            onClick={() => props.onShowRids(record)}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                                <ExperimentOutlined style={{ fontSize: 18, color: 'gray' }} />
                                <Typography.Text style={{ fontSize: 18, paddingTop: 4, paddingLeft: 4 }}>
                                    {new Intl.NumberFormat('ru-ru', {}).format(record.rid_count || 0)}
                                </Typography.Text>
                            </div>
                        </Button>
                    </Tooltip>
                );
            }
        },
        {
            title: t('result.content.finances_count.title'),
            render: (_: any, record: ResultData) => {
                return (
                    <Tooltip title={t('result.content.finances_count.description')}>
                        <Button
                            style={{ padding: 0, paddingTop: 0, paddingLeft: 5, paddingRight: 5 }}
                            type="text"
                            onClick={() => props.onShowFinances(record)}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                                <CalculatorOutlined style={{ fontSize: 18, color: 'gray' }} />
                                <Typography.Text style={{ fontSize: 18, paddingTop: 4, paddingLeft: 4 }}>
                                    {new Intl.NumberFormat('ru-ru', {}).format(record.finance_count || 0)}
                                </Typography.Text>
                            </div>
                        </Button>
                    </Tooltip>
                );
            }
        }
    ];

    return (
        <Form form={form} component={false}>
            <Table
                size="small"
                loading={props.loading}
                bordered
                dataSource={data}
                columns={columns}
                rowClassName="row"
                rowKey={'id'}
                pagination={{
                    current: props.currentPage,
                    pageSize: props.pageSize,
                    total: props.total,
                    position: ['bottomCenter'],
                    onChange: props.onChangePagination
                }}
            />
        </Form>
    );
};
