import { useState } from 'react';
import { Table, Form, Tooltip, Button } from 'antd';
import { useEffect } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { WebsiteMenuData } from '@models/website-menu-model';

interface TableProps {
    rows: WebsiteMenuData[];
    total: number;
    loading: boolean;
    onEdit: (id: string) => void;
}

export const EditableTable = (props: TableProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [data, setData] = useState<WebsiteMenuData[]>([]);

    useEffect(() => {
        setData(props.rows);
    }, [props]);

    const columns = [
        {
            title: t('website_menu.content.name.title'),
            dataIndex: 'name'
        },
        {
            title: 'Alt-name',
            dataIndex: 'alt_name'
        },
        {
            title: '',
            width: '10%',
            dataIndex: 'operations',
            render: (_: any, record: WebsiteMenuData) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Tooltip title={t('common.edit')}>
                            <Button onClick={() => props.onEdit(record.alt_name)}>
                                <EditOutlined />
                            </Button>
                        </Tooltip>
                    </div>
                );
            }
        }
    ];

    return (
        <Form form={form} component={false}>
            <Table
                size="small"
                loading={props.loading}
                bordered
                dataSource={data}
                columns={columns}
                rowClassName="row"
                rowKey={'id'}
                pagination={false}
            />
        </Form>
    );
};
