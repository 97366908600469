import { ObjectLiteral } from '@utils/object.utils';

export class WebsiteMenuData {
    name: string;
    alt_name: string;
    items: ObjectLiteral;
}

export class GetWebsiteMenusResponse {
    data: WebsiteMenuData[];
    total: number;
}

export class GetWebsiteMenuResponse {
    data: WebsiteMenuData;
}
