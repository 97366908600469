import { ResultsFilterParamName, ResultsGetFilter } from '@interfaces/result.interface';
import { ResultData, ResultModel } from '@models/result-model';
import { ResultTypeData, ResultTypeModel } from '@models/result-type-model';
import { APIUtilError } from '@utils/api';
import { ObjectLiteral, deleteUndefined, isObjectsHasDifferences } from '@utils/object.utils';
import { debounce } from 'lodash';
import { makeAutoObservable, runInAction, toJS } from 'mobx';

export interface ResultsViewModelStartParams {
    t: any;
    searchParams: URLSearchParams;
    setSearchParams: (params: any) => void;
    showError: (title: string, description: string) => void;
}

export class ResultsViewModel {
    private resultsModel = new ResultModel();
    private resultTypeModel = new ResultTypeModel();
    public isLoading: boolean = false;

    public isResultTypeLoading: boolean = false;
    public resultType?: ResultTypeData | undefined;

    public currentFilter: ResultsGetFilter = {};
    public currentPage: number = 1;
    public pageSize: number = 10;
    public availablePageSizes = [10, 20, 50, 100];

    public resulsTotal: number = 0;
    public results: ResultData[] = [];

    constructor(private props: ResultsViewModelStartParams) {
        const incomingFilter = this.getFilterFromSearchParams(props.searchParams);
        this.currentFilter = incomingFilter;
        this.currentPage = incomingFilter.page || 0;
        this.pageSize = incomingFilter.page_size || this.availablePageSizes[1];

        makeAutoObservable(this);
        this.wakeUpSir();
    }

    private async wakeUpSir() {
        await this.fetchResultType(
            this.currentFilter.result_type_id && this.currentFilter.result_type_id.length > 0 ? this.currentFilter.result_type_id[0] : ''
        );
        await this.fetchResults(this.currentFilter);
    }

    public async updateSearchParamsIfNeeded(searchParams: URLSearchParams): Promise<Boolean> {
        const newFilter = this.getFilterFromSearchParams(searchParams);
        if (isObjectsHasDifferences(newFilter, toJS(this.currentFilter))) {
            runInAction(() => {
                this.currentPage = newFilter.page || 1;
                this.pageSize = newFilter.page_size || this.availablePageSizes[1];
                this.currentFilter = newFilter;
            });
            await this.fetchResults(newFilter);
            return true;
        }
        return false;
    }

    private getFilterFromSearchParams = (searchParams: URLSearchParams) => {
        let filter: ResultsGetFilter = { page: 1, page_size: this.pageSize };
        const keys: ResultsFilterParamName[] = ['result_type_id', 'search', 'page', 'page_size'];

        for (const key of keys) {
            const value = searchParams.get(key);
            if (!value) continue;
            switch (key) {
                case 'search':
                    filter[key] = value;
                    break;

                case 'result_type_id':
                    filter[key] = [value];
                    break;

                case 'page':
                    try {
                        const parsedPage = parseInt(value);
                        filter[key] = parsedPage;
                    } catch {
                        filter[key] = 1;
                    }
                    break;
                case 'page_size':
                    try {
                        const parsedSize = parseInt(value);
                        if (!this.availablePageSizes.includes(parsedSize)) throw new Error();
                        filter.page_size = parsedSize;
                    } catch {
                        filter.page_size = undefined;
                    }
                    break;
                default:
                    break;
            }
        }
        return filter;
    };

    private extractSearchParamsFromFilter = (filter: ObjectLiteral) => {
        let searchParams: ObjectLiteral = {};
        for (const key in filter) {
            switch (key) {
                case 'result_type_id':
                    searchParams.result_type_id = filter[key];
                    break;
                default:
                    searchParams[key] = filter[key] ? filter[key] : undefined;
            }
        }

        return deleteUndefined(searchParams);
    };

    public onFilterValueChange = (values: ObjectLiteral) => {
        const formsValues = deleteUndefined(values);
        let filter: ResultsGetFilter = { ...this.currentFilter, page: 1, page_size: this.pageSize };
        for (const key in formsValues) {
            switch (key) {
                case 'search':
                    filter.search = formsValues[key];
                    break;
                default:
                    break;
            }
        }

        const searchParams = this.extractSearchParamsFromFilter(deleteUndefined(filter));
        this.props.setSearchParams(searchParams as any);

        runInAction(() => {
            this.currentPage = 1;
            this.currentFilter = deleteUndefined(filter);
        });
        this.debouncedFetch(deleteUndefined(filter));
    };

    private debouncedFetch = debounce(async (params: ResultsGetFilter) => {
        this.fetchResults(params);
    }, 600);

    private async fetchResultType(id: string | number) {
        runInAction(() => {
            this.isResultTypeLoading = true;
        });
        try {
            const resultType = await this.resultTypeModel.getResultTypeById(id);
            runInAction(() => {
                this.resultType = resultType.data;
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isResultTypeLoading = false;
            });
        }
    }

    private async fetchResults(params: ResultsGetFilter) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const results = await this.resultsModel.getResults(params);
            runInAction(() => {
                this.results = results.data;
                this.resulsTotal = results.total;
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public onChangePagination = async (page: number, pageSize: number) => {
        if (pageSize !== this.pageSize) {
            runInAction(() => {
                this.pageSize = pageSize;
            });
        }
        const filter: ResultsGetFilter = { ...this.currentFilter, page: page, page_size: pageSize };
        runInAction(() => {
            this.currentPage = page;
            this.currentFilter = filter;
        });

        const currentFiltersSearchParams = this.extractSearchParamsFromFilter(filter);
        this.props.setSearchParams(currentFiltersSearchParams);

        this.fetchResults(filter);
    };
}
