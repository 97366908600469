import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import '@assets/css/App.css';
import RootView from '@pages/root/root.view';
import NotFoundView from '@pages/404.view';
import UsersManageView from '@pages/users/users.view';
import { RootModel } from '@models/root-model';
import IndicatorView from '@pages/indicator/indicator.view';
import StaticPageView from '@pages/static-page/static-page.view';
import IndicatorEditView from '@pages/indicator/edit/indicator.edit.view';
import StaticPageEditView from '@pages/static-page/edit/static-page.edit.view';
import { Spin } from 'antd';
import ResultTypesView from '@pages/result-types/result-types.view';
import ResultTypeEditView from '@pages/result-types/edit/result-type.edit.view';
import OperatorsView from '@pages/operators/operators.view';
import MembersView from '@pages/members/members.view';
import EventsView from '@pages/events/events.view';
import StartupsView from '@pages/startups/startups.view';
import StartupProjectView from '@pages/startup-project/startup-project.view';
import RidsView from '@pages/rids/rids.view';
import FinancesView from '@pages/finances/finances.view';
import AirflowView from '@pages/airflow/airflow.view';
import DictionariesView from '@pages/dictionaries/dictionaries.view';
import DictionaryView from '@pages/dictionaries/single-dictionary/dictionary.view';
import EventEditView from '@pages/events/edit/event.edit.view';
import EventsDashboardView from '@pages/events-dashboard/events-dashboard.view';
import ReportsView from '@pages/reports/reports.view';
import ReportsApiView from '@pages/reports-api/reports-api.view';
import ReportApiEditView from '@pages/reports-api/edit/reports-api.edit.view';
import OauthClientsView from '@pages/oauth/oauth.view';
import OauthClientEditView from '@pages/oauth/edit/oauth.edit.view';
import ReportEditView from '@pages/reports/edit/report.edit.view';
import ResultsView from '@pages/results/results.view';
import UserEditView from '@pages/users/edit/user.edit.view';
import EmailTemplateView from '@pages/email-template/email-template.view';
import EmailTemplateEditView from '@pages/email-template/edit/email-template.edit.view';
import WebsiteMenuView from '@pages/website-menu/website-menu.view';
import StartupEditView from '@pages/startups/edit/startup.edit.view';
import StartupProjectEditView from '@pages/startup-project/edit/startup-project.edit.view';
import RidEditView from '@pages/rids/edit/rid.edit.view';
import PopularizationInfopovodsView from '@pages/popularization/infopovod/infopovods.view';
import PopularizationInfopovodEditView from '@pages/popularization/infopovod/edit/infopovod.edit.view';
import PopularizationResourcesView from '@pages/popularization/resource/resources.view';
import PopularizationResourceEditView from '@pages/popularization/resource/edit/resource.edit.view';

interface AppProps {}

const App: React.FunctionComponent<AppProps> = () => {
    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        initialize();
    }, []);

    const initialize = async () => {
        await RootModel.initialize();
        setIsInitialized(true);
    };

    return (
        <div className="app">
            <Spin spinning={!isInitialized}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<RootView />}>
                            <Route index element={<EventsDashboardView />} />
                            <Route index path="events-dashboard" element={<EventsDashboardView />} />
                            <Route path="users-manage/*">
                                <Route path="" element={<UsersManageView />} />
                                <Route path="edit/:id" element={<UserEditView />} />
                                <Route path="*" element={<NotFoundView />} />
                            </Route>
                            {/* <Route path="news-manage/*">
                                <Route path="" element={<NewsView />} />
                                <Route path="create" element={<NewsEditView />} />
                                <Route path="edit/:id" element={<NewsEditView />} />
                                <Route path="*" element={<NotFoundView />} />
                            </Route> */}
                            <Route path="indicator-manage/*">
                                <Route path="" element={<IndicatorView />} />
                                <Route path="create" element={<IndicatorEditView />} />
                                <Route path="edit/:id" element={<IndicatorEditView />} />
                                <Route path="*" element={<NotFoundView />} />
                            </Route>
                            <Route path="static-page-manage/*">
                                <Route path="" element={<StaticPageView />} />
                                <Route path="create" element={<StaticPageEditView />} />
                                <Route path="edit/:id" element={<StaticPageEditView />} />
                                <Route path="*" element={<NotFoundView />} />
                            </Route>
                            <Route path="result-types-manage/*">
                                <Route path="" element={<ResultTypesView />} />
                                <Route path="edit/:id" element={<ResultTypeEditView />} />

                                <Route path="results-manage" element={<ResultsView />} />
                                <Route path="*" element={<NotFoundView />} />
                            </Route>
                            <Route path="operators-manage/*">
                                <Route path="" element={<OperatorsView />} />
                                <Route path=":id" element={<OperatorsView />} />
                                <Route path="*" element={<NotFoundView />} />
                            </Route>
                            <Route path="members-manage/*">
                                <Route path="" element={<MembersView />} />
                                <Route path="*" element={<NotFoundView />} />
                            </Route>
                            <Route path="events-manage/*">
                                <Route path="" element={<EventsView />} />
                                <Route path="create" element={<EventEditView />} />
                                <Route path="edit/:id" element={<EventEditView />} />
                                <Route path="*" element={<NotFoundView />} />
                            </Route>
                            <Route path="startups-manage/*">
                                <Route path="" element={<StartupsView />} />
                                <Route path="edit/:id" element={<StartupEditView />} />
                                <Route path="*" element={<NotFoundView />} />
                            </Route>
                            <Route path="startup-project-manage/*">
                                <Route path="" element={<StartupProjectView />} />
                                <Route path="edit/:id" element={<StartupProjectEditView />} />
                                <Route path="*" element={<NotFoundView />} />
                            </Route>
                            <Route path="rids-manage/*">
                                <Route path="" element={<RidsView />} />
                                <Route path="edit/:id" element={<RidEditView />} />
                                <Route path="*" element={<NotFoundView />} />
                            </Route>
                            <Route path="finances-manage/*">
                                <Route path="" element={<FinancesView />} />
                                <Route path="*" element={<NotFoundView />} />
                            </Route>
                            <Route path="airflow-manage" element={<AirflowView />} />
                            <Route path="dictionaries-manage/*">
                                <Route path="" element={<DictionariesView />} />
                                <Route path=":altname" element={<DictionaryView />} />
                                <Route path=":altname/edit/:id" element={<DictionaryView />} />
                                <Route path="*" element={<NotFoundView />} />
                            </Route>
                            <Route path="reports-manage/*">
                                <Route path="" element={<ReportsView />} />
                                <Route path="create" element={<ReportEditView />} />
                                <Route path="edit/:id" element={<ReportEditView />} />
                                <Route path="*" element={<NotFoundView />} />
                            </Route>
                            <Route path="reports-api-manage/*">
                                <Route path="" element={<ReportsApiView />} />
                                <Route path="edit/:id" element={<ReportApiEditView />} />
                                <Route path="*" element={<NotFoundView />} />
                            </Route>
                            <Route path="oauth-clients-manage/*">
                                <Route path="" element={<OauthClientsView />} />
                                <Route path=":id" element={<OauthClientEditView />} />
                            </Route>
                            <Route path="email-templates/*">
                                <Route path="" element={<EmailTemplateView />} />
                                <Route path="edit/:id" element={<EmailTemplateEditView />} />
                                <Route path="*" element={<NotFoundView />} />
                            </Route>
                            <Route path="website-menu-manage/*">
                                <Route path="" element={<WebsiteMenuView />} />
                                <Route path=":id" element={<WebsiteMenuView />} />
                                <Route path="*" element={<NotFoundView />} />
                            </Route>
                            <Route path="popularization-infopovod/*">
                                <Route path="" element={<PopularizationInfopovodsView />} />
                                <Route path="create" element={<PopularizationInfopovodEditView />} />
                                <Route path="edit/:id" element={<PopularizationInfopovodEditView />} />
                                <Route path="*" element={<NotFoundView />} />
                            </Route>

                            <Route path="popularization-resource/*">
                                <Route path="" element={<PopularizationResourcesView />} />
                                <Route path="create" element={<PopularizationResourceEditView />} />
                                <Route path="edit/:id" element={<PopularizationResourceEditView />} />
                                <Route path="*" element={<NotFoundView />} />
                            </Route>

                            <Route path="*" element={<NotFoundView />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </Spin>
        </div>
    );
};

export default App;
