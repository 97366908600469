import { APIInstance } from '@utils/api';
import { deleteUndefined } from '@utils/object.utils';
import { GetStartupsResponse, StartupResponse } from './startup-project.contract';
import { StartupGetFilter } from '@interfaces/startup.interface';

export class StartupProjectModel {
    public async getStartupProjects(params: StartupGetFilter): Promise<GetStartupsResponse> {
        try {
            const result = await APIInstance.get<GetStartupsResponse>('/cp-manager/startup/project', {
                params: deleteUndefined({
                    search: params.search,
                    result_type_id: params.result_type_id ? [params.result_type_id] : undefined,
                    result_id: params.result_id ? [params.result_id] : undefined,
                    member_id: params.member_id,
                    finance_id: params.finance_id,
                    rid_id: params.rid_id,
                    startup_id: params.startup_id,
                    startup_studio_id: params.startup_studio_id,
                    limit: params.page_size,
                    offset: params.page_size && params.page ? params.page_size * (params.page - 1) : 0
                })
            });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getStartupProjectById(id: string | number): Promise<StartupResponse> {
        try {
            const result = await APIInstance.get<StartupResponse>(`/cp-manager/startup/project/${id}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    // public async update(startup: StartupData): Promise<StartupResponse> {
    //     try {
    //         const result = await APIInstance.put<StartupResponse>(`/cp-manager/startup/${startup.id}`, { ...startup });
    //         return result.data;
    //     } catch (error) {
    //         throw error;
    //     }
    // }
}
