import { APIUtilError } from '@utils/api';
import { makeAutoObservable, runInAction } from 'mobx';
import { StartupData, StartupModel } from '@models/startup-model';
import {
    DictionaryModel,
    RegionDictionaryData,
    StartupDirectionDictionaryData,
    TRLDictionary,
    OrganizationDictionaryData,
    MonthDictionaryData
} from '@models/dictionary-model';

export interface StartupEditViewModelStartParams {
    t: any;
    startupId: string | undefined;
    showError: (title: string, description: string) => void;
    showSuccess: (title: string, description: string) => void;
}

export class StartupEditViewModel {
    private startupModel = new StartupModel();
    private dictionaryModel = new DictionaryModel();

    public isLoading: boolean = false;
    public startup: StartupData = new StartupData();

    public startupDirections: StartupDirectionDictionaryData[] = [];
    public regionDictionary: RegionDictionaryData[] = [];
    public institutionDictionary: OrganizationDictionaryData[] = [];
    public MonthDictionary: MonthDictionaryData[] = [];
    public trlDictionary: TRLDictionary[] = [];

    constructor(private props: StartupEditViewModelStartParams) {
        makeAutoObservable(this);
        this.wakeUpSir();
    }

    private async wakeUpSir() {
        // await this.fetchStartupDirections();
        await this.fetchRegionDictionary();
        await this.fetchInstitutionDictionary();
        await this.fetchMonthDictionary();
        // await this.fetchTRLDictionary();
        if (this.props.startupId && this.props.startupId.length > 0) this.fetchStartup(this.props.startupId);
    }

    private async fetchRegionDictionary() {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.dictionaryModel.getDictionary({ altname: 'region' });
            runInAction(() => {
                this.regionDictionary = result.data as RegionDictionaryData[];
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    private async fetchInstitutionDictionary() {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.dictionaryModel.getDictionary({ altname: 'institution' });
            runInAction(() => {
                this.institutionDictionary = result.data as OrganizationDictionaryData[];
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    private async fetchMonthDictionary() {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.dictionaryModel.getDictionary({ altname: 'month' });
            runInAction(() => {
                this.MonthDictionary = result.data as MonthDictionaryData[];
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }
    private async fetchStartup(startupId: string) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const startup = await this.startupModel.getStartupById(startupId);
            runInAction(() => {
                this.startup = startup.data;
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public onSave = async (startup: StartupData) => {
        await this.save(startup);
    };

    private save = async (startup: StartupData) => {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            await this.startupModel.update(startup);
            this.props.showSuccess(this.props.t('common.saved'), '');
        } catch (error) {
            this.props.showError(this.props.t('common.error.save'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };
}
