import { OauthClientsModel, OauthClientsDataFull } from '@models/oauth-model';
import { makeAutoObservable, runInAction } from 'mobx';
import { APIUtilError } from '@utils/api';

export class OauthClientEditViewModel {
    private oauthClientModel = new OauthClientsModel();
    public isLoading: boolean = false;
    public client: OauthClientsDataFull = new OauthClientsDataFull();

    constructor(public clientId: string | undefined, private showError: (description: string) => void) {
        makeAutoObservable(this);
        this.wakeUpSir();
    }

    private async wakeUpSir() {
        if (this.clientId) await this.fetchClientById(this.clientId);
    }

    private async fetchClientById(clientId: string) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.oauthClientModel.getClientById(clientId);
            runInAction(() => {
                this.client = result.data;
            });
        } catch (error) {
            this.showError((error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }
}
