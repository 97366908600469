import React, { useState } from 'react';
import { Space, Tooltip, Typography } from 'antd';
import { Button, Table } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { InputRules, ValueTableItem } from './values.table.contract';
import { EditableCell, EditableRow } from './values.table.components';

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

interface TableProps {
    rows: number[] | string[];
    maxRows?: number | undefined;
    defaultNewValue?: number | string | undefined;
    inputRules?: InputRules | undefined;
    placeholder?: string | undefined;
    onUpdate: (rows: (number | string)[]) => void;
}

export const EditableValuesTable: React.FC<TableProps> = ({ rows, maxRows, onUpdate, inputRules, defaultNewValue }) => {
    const { t } = useTranslation();

    const convertRawData = (data: number[] | string[]): ValueTableItem[] => {
        return data.map((item, index) => {
            return {
                id: uuidv4(),
                index: index,
                value: item
            };
        });
    };
    const [dataSource, setDataSource] = useState<ValueTableItem[]>(convertRawData(rows));

    const defaultColumns = [
        {
            title: '#',
            width: '10%',
            key: 'numeric',
            render: (_: any, record: ValueTableItem) => {
                return (
                    <span key={`numeric-${record.index}`}>
                        <Typography.Text type="secondary">{record.index + 1}</Typography.Text>
                    </span>
                );
            }
        },
        {
            dataIndex: 'value',
            editable: true,
            key: 'content'
        },
        {
            key: 'operation',
            width: '10%',
            dataIndex: 'operation',
            render: (_: any, record: ValueTableItem) => (
                <Space key={`operation-${record.index}`} direction="horizontal" size="small" align="center">
                    <Tooltip title={t('common.delete')}>
                        <Button onClick={() => handleDelete(record)}>
                            <DeleteOutlined />
                        </Button>
                    </Tooltip>
                </Space>
            )
        }
    ];

    const handleUpdate = (items: ValueTableItem[]) => {
        const newRawData = items.map((item) => item.value);
        onUpdate(newRawData);
    };

    const isRowsOutOfLimits = () => {
        if (maxRows && dataSource.length >= maxRows) return true;
    };

    const handleAdd = () => {
        if (isRowsOutOfLimits()) return;
        const newData = [
            ...dataSource,
            {
                id: uuidv4(),
                index: dataSource.length,
                value: defaultNewValue || ''
            }
            //reindex
        ].map((item, index) => {
            return {
                id: item.id,
                index: index,
                value: item.value
            };
        });
        setDataSource(newData);
        handleUpdate(newData);
    };

    const handleSave = (row: ValueTableItem) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => item.id === row.id);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row
        });
        setDataSource(newData);
        handleUpdate(newData);
    };

    const handleDelete = (row: ValueTableItem) => {
        const newData = dataSource
            .filter((item) => item.id !== row.id)
            .map((item, index) => {
                return {
                    id: item.id,
                    index: index,
                    value: item.value
                };
            });

        setDataSource(newData);
        handleUpdate(newData);
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell
        }
    };

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: ValueTableItem) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
                inputRules
            })
        };
    });

    return (
        <div>
            <Table components={components} dataSource={dataSource} columns={columns as ColumnTypes} size="small" showHeader={false} pagination={false} rowKey={'id'} />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Button disabled={isRowsOutOfLimits()} onClick={handleAdd} type="primary" icon={<PlusOutlined />} size="large" style={{ marginTop: 10 }} />
            </div>
        </div>
    );
};
