import { SortType } from '@components/UI/sortDropdown.view';
import { ResultTypeData, ResultTypeModel } from '@models/result-type-model';
import { APIUtilError } from '@utils/api';
import { makeAutoObservable, runInAction } from 'mobx';

export interface ResultTypesViewModelStartParams {
    t: any;
    showError: (title: string, description: string) => void;
}

export class ResultTypesViewModel {
    private resultTypesModel = new ResultTypeModel();
    public loading: boolean = false;

    public currentPage: number = 1;
    public pageSize: number = 10;

    public resultTypesCount: number = 0;
    public resultTypes: ResultTypeData[] = [];

    public currentSortType?: SortType | undefined = undefined;

    constructor(private startParams: ResultTypesViewModelStartParams) {
        makeAutoObservable(this);
        this.fetchResultTypes();
    }

    private async fetchResultTypes() {
        runInAction(() => {
            this.loading = true;
        });
        try {
            const resultTypes = await this.resultTypesModel.getResultTypes();
            runInAction(() => {
                this.resultTypes = resultTypes.data;
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    public delete = async (resultType: ResultTypeData) => {
        if (!resultType.id) return;
        runInAction(() => {
            this.loading = true;
        });
        try {
            await this.resultTypesModel.delete(resultType.id);
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.empty'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    };
}
