import * as React from 'react';
import { Button, Col, Layout, Row, Tooltip, Typography } from 'antd';
import { Profile } from '@models/auth-model';
import { useTranslation } from 'react-i18next';
import { RootModel } from '@models/root-model';
import { observer } from 'mobx-react-lite';

interface Props {
    profile?: Profile;
}

const AppHeader: React.FC<Props> = observer(({ profile }) => {
    const { t } = useTranslation();

    const LogoutIcon = () => {
        return (
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11.0017 10C11.0138 7.82497 11.1103 6.64706 11.8787 5.87868C12.7573 5 14.1715 5 17 5H18C20.8284 5 22.2426 5 23.1213 5.87868C24 6.75736 24 8.17157 24 11V19C24 21.8284 24 23.2426 23.1213 24.1213C22.2426 25 20.8284 25 18 25H17C14.1715 25 12.7573 25 11.8787 24.1213C11.1103 23.3529 11.0138 22.175 11.0017 20"
                    stroke="#A533FF"
                    strokeWidth="2"
                    strokeLinecap="round"
                />
                <path
                    d="M11 22.5C8.64298 22.5 7.46447 22.5 6.73223 21.7678C6 21.0355 6 19.857 6 17.5V12.5C6 10.143 6 8.96447 6.73223 8.23223C7.46447 7.5 8.64298 7.5 11 7.5"
                    stroke="#A533FF"
                    strokeWidth="2"
                />
                <path d="M18 15L9 15M9 15L11 17M9 15L11 13" stroke="#A533FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );
    };

    return (
        <Layout.Header className="header" style={{ backgroundColor: 'white' }}>
            <Row gutter={12} justify="space-between">
                <Col>
                    <button
                        className={['collapseBtn', RootModel.appModel.isSidebarCollapsed ? 'active' : ''].join(' ')}
                        onClick={RootModel.appModel.toggleCollapsedSidebar}
                    >
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15 0C6.71774 0 0 6.71774 0 15C0 23.2823 6.71774 30 15 30C23.2823 30 30 23.2823 30 15C30 6.71774 23.2823 0 15 0ZM19.1935 9.71371C19.1935 10.129 18.996 10.5161 18.6613 10.7581L12.7984 15L18.6613 19.2379C18.996 19.4798 19.1935 19.871 19.1935 20.2823V22.1734C19.1935 22.4355 18.8952 22.5887 18.6814 22.4355L8.7621 15.2581C8.72088 15.2284 8.68731 15.1893 8.66416 15.1441C8.64101 15.0989 8.62893 15.0488 8.62893 14.998C8.62893 14.9472 8.64101 14.8971 8.66416 14.8519C8.68731 14.8067 8.72088 14.7676 8.7621 14.7379L18.6814 7.56048C18.8952 7.40726 19.1935 7.56048 19.1935 7.82258V9.71371Z"
                                fill="#A533FF"
                            />
                        </svg>
                    </button>
                </Col>
                <Col>
                    <Row gutter={12} align="middle" style={{ paddingTop: '10px' }}>
                        {profile?.name && (
                            <Col>
                                <div style={{ display: 'flex', marginTop: '-15px' }}>
                                    <Typography.Text strong>{profile?.name}</Typography.Text>
                                </div>
                            </Col>
                        )}
                        <Col>
                            <Tooltip title={t('auth.signout-button.title')}>
                                <Button
                                    style={{ padding: 0, paddingTop: 0 }}
                                    type="text"
                                    icon={<LogoutIcon />}
                                    onClick={() => RootModel.authModel.signOut()}
                                />
                            </Tooltip>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Layout.Header>
    );
});

export default AppHeader;
