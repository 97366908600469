export class Operator {
    id: number;
    name: string;
    site: string;
    api_id: number;
    api_name?: string;
    count_errors_solved?: number;
    count_errors_not_solved?: number;
    logo_url?: string;
}

export class GetOperatorsResponse {
    data: Operator[];
    total: number;
    count: number;
}

export class OperatorResponse {
    data: Operator;
}

export class OperatorError {
    id: number;
    title: string;
    error_log: string;
    is_solved: boolean;
}

export class GetOperatorErrors {
    data: OperatorError[];
    total: number;
    count: number;
}
