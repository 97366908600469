import { APIInstance, BaseURL } from '@utils/api';
import { deleteUndefined } from '@utils/object.utils';
import {
    EventData,
    EventResponse,
    GetEventFilesResponse,
    GetEventHistoryItemReponse,
    GetEventHistoryItemsReponse,
    GetEventsResponse
} from './event.contract';
import { RootModel } from '@models/root-model';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { EventsGetFilter } from '@interfaces/events.interface';

export class EventModel {
    public uploadURL(eventId: number) {
        return `${BaseURL}/cp-manager/event/${eventId}/file`;
    }

    public uploadHeaders = async () => {
        let accessToken = RootModel?.authModel?.getAccessToken();
        if (!accessToken) {
            await RootModel?.authModel?.updateToken();
            accessToken = RootModel?.authModel?.getAccessToken();
        }
        return { Authorization: RootModel?.authModel ? `Bearer ${accessToken}` : '' };
    };

    public async getEvents(params: EventsGetFilter): Promise<GetEventsResponse> {
        try {
            const result = await APIInstance.get<GetEventsResponse>('/cp-manager/event', {
                params: deleteUndefined({
                    search: params.search,
                    date_start: params.range_start ? dayjs(params.range_start).format('YYYY-MM-DD') : undefined,
                    date_end: params.range_end ? dayjs(params.range_end).format('YYYY-MM-DD') : undefined,
                    result_type_id: params.result_type_id,
                    result_id: params.result_id,
                    region_id: params.region_id,
                    operator_id: params.operator_id,
                    event_type_id: params.event_type_id,
                    top_50: params.top_50,
                    limit: params.page_size,
                    offset: params.page_size && params.page ? params.page_size * (params.page - 1) : 0
                })
            });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getEventCountByDates(params: { range_start: Date; range_end: Date; type: 'month' | 'year' }) {
        try {
            const result = await APIInstance.get<GetEventHistoryItemReponse>('/cp-manager/event/calendar', {
                params: deleteUndefined({
                    date_start: dayjs(params.range_start).format('YYYY-MM-DD'),
                    date_end: dayjs(params.range_end).format('YYYY-MM-DD'),
                    type: params.type
                })
            });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getEventById(id: string | number): Promise<EventResponse> {
        try {
            const result = await APIInstance.get<EventResponse>(`/cp-manager/event/${id}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async create(event: EventData): Promise<EventResponse> {
        try {
            const result = await APIInstance.post<EventResponse>(`/cp-manager/event`, { ...event });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async update(event: EventData): Promise<EventResponse> {
        try {
            const result = await APIInstance.put<EventResponse>(`/cp-manager/event/${event.id}`, { ...event });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async delete(eventId: number): Promise<EventResponse> {
        try {
            const result = await APIInstance.delete(`/cp-manager/event/${eventId}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getFiles(eventId: number): Promise<GetEventFilesResponse> {
        try {
            const result = await APIInstance.get(`/cp-manager/event/${eventId}/file`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async deleteFile(eventId: number, fileUUID: string) {
        try {
            const result = await APIInstance.delete(`/cp-manager/event/${eventId}/file/${fileUUID}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async saveFileTitle(eventId: number, fileUUID: string, title: string) {
        try {
            const result = await APIInstance.put(`/cp-manager/event/${eventId}/file/${fileUUID}`, { file_title: title });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public getDownloarFileUrl(eventId: number, fileUUID: string) {
        return `${BaseURL}/cp-manager/event/${eventId}/file/${fileUUID}`;
    }

    public async getExcelExport(params: EventsGetFilter, filename: string) {
        await APIInstance({
            url: '/cp-manager/event/export/excel',
            method: 'GET',
            params: deleteUndefined({
                search: params.search,
                date_start: params.range_start ? dayjs(params.range_start).format('YYYY-MM-DD') : undefined,
                date_end: params.range_end ? dayjs(params.range_end).format('YYYY-MM-DD') : undefined,
                result_type_id: params.result_type_id,
                region_id: params.region_id,
                operator_id: params.operator_id,
                top_50: params.top_50
            }),
            // headers: headers,
            responseType: 'blob'
        }).then((response) => {
            saveAs(response.data, filename);
        });
    }

    public async getHistoryItems(eventId: string | number, page: number | undefined, pageSize: number | undefined) {
        try {
            const result = await APIInstance.get<GetEventHistoryItemsReponse>(`/cp-manager/event/${eventId}/history`, {
                params: deleteUndefined({
                    limit: pageSize,
                    offset: pageSize && page ? pageSize * (page - 1) : 0
                })
            });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getHistoryItem(eventId: string | number, historyId: number) {
        try {
            const result = await APIInstance.get<GetEventHistoryItemReponse>(`/cp-manager/event/${eventId}/history/${historyId}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }
}
