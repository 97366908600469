import React, { useContext, useEffect, useRef, useState } from 'react';
import { Input, InputRef } from 'antd';
import { Form } from 'antd';
import type { FormInstance } from 'antd/es/form';
import { InputRules, ValueTableItem } from './values.table.contract';
import { EditOutlined } from '@ant-design/icons';

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
    index: number;
}

export const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof ValueTableItem;
    record: ValueTableItem;
    handleSave: (record: ValueTableItem) => void;
    inputRules?: InputRules | undefined;
}

export const EditableCell: React.FC<EditableCellProps> = ({ title, editable, children, dataIndex, record, handleSave, inputRules, ...restProps }) => {
    const [editing, setEditing] = useState(false);
    const form = useContext(EditableContext)!;
    const inputRef = useRef<InputRef>(null);

    useEffect(() => {
        if (editing) {
            inputRef.current!.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                rules={[
                    {
                        required: inputRules?.required || false,
                        message: inputRules?.requiredMessage || ''
                    }
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} {...inputRules} />
            </Form.Item>
        ) : (
            <div style={{ paddingRight: 24 }} onClick={toggleEdit}>
                {`${record.value}`.length === 0 ? <EditOutlined /> : children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};
