/* eslint-disable no-unreachable */
import { APIInstance } from '@utils/api';
import { OauthClientsDataResponse, OauthClientsDataFullResponse } from './oauth.contract';

export class OauthClientsModel {
    public async getClients(): Promise<OauthClientsDataResponse> {
        try {
            const result = await APIInstance.get<OauthClientsDataResponse>('cp-manager/oauth/client');
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getClientById(clienId: string): Promise<OauthClientsDataFullResponse> {
        try {
            const result = await APIInstance.get<OauthClientsDataFullResponse>(`cp-manager/oauth/client/${clienId}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }
}
