export class OauthScopeItem {
    name: string;
    desc: string;
}

export class OauthClientsData {
    client_id: string;
    access_type: string;
    operator_id: number;
    operator_name: string;
    operator_api_id: number;
    operator_api_name: string;
    scope: OauthScopeItem[];
}

export class OauthClientsDataResponse {
    data: OauthClientsData[];
    count: number;
    total: number;
}

export class OauthClientsDataFull extends OauthClientsData {
    client_secret: string;
}

export class OauthClientsDataFullResponse {
    data: OauthClientsDataFull;
}
