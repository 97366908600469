import { OauthClientsModel, OauthClientsData } from '@models/oauth-model';
import { makeAutoObservable, runInAction } from 'mobx';
import { APIUtilError } from '@utils/api';

export class OauthClientsViewModel {
    private oauthClientModel = new OauthClientsModel();
    public isLoading: boolean = false;
    public oauthClients: OauthClientsData[] = [];

    constructor(private showError: (description: string) => void) {
        makeAutoObservable(this);
        this.wakeUpSir();
    }

    private async wakeUpSir() {
        await this.fetchOauthClients();
    }

    private async fetchOauthClients() {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.oauthClientModel.getClients();
            runInAction(() => {
                this.oauthClients = result.data;
            });
        } catch (error) {
            this.showError((error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }
}
