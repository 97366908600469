import { APIInstance } from '@utils/api';
import { deleteUndefined } from '@utils/object.utils';
import {
    GetUsersResponse,
    UsersGetFilter,
    UserHistoryLoggerDataListResponse,
    UserHistoryLoggerDataItemResponse,
    UserCreateResponse,
    UserResponse
} from './user.contract';
export class UserModel {
    public async getUsers(params: UsersGetFilter): Promise<GetUsersResponse> {
        try {
            const result = await APIInstance.get<GetUsersResponse>('/cp-manager/user', {
                params: deleteUndefined({
                    search: params.search,
                    limit: params.page_size,
                    offset: params.page_size && params.page ? params.page_size * (params.page - 1) : 0
                })
            });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async createUser(email: string, name: string, role_id: string): Promise<UserCreateResponse> {
        try {
            const reuslt = await APIInstance.post<UserCreateResponse>(`/cp-manager/user`, { email, role_id, name });
            return reuslt.data;
        } catch (error) {
            throw error;
        }
    }

    public async getUserById(userId: string): Promise<UserResponse> {
        try {
            const result = await APIInstance.get<UserResponse>(`/cp-manager/user/${userId}`);
            return result.data;
        } catch (error) {
            throw error
        }
    }

    public async updateUser(userId: string, data: any): Promise<any> {
        try {
            await APIInstance.patch(`/cp-manager/user/${userId}`, { ...data });
        } catch (error) {
            throw error
        }
    }

    public async resetUserPassword(userId: string) {
        try {
            return await APIInstance.post(`/cp-manager/user/${userId}/reset-password`);
        } catch (error) {
            throw error
        }
    }

    public async deleteUser(userId: string) {
        try {
            await APIInstance.delete(`/cp-manager/user/${userId}`);
        } catch (error) {
            throw error;
        }
    }

    // INST
    public async getInst(userId: string) {
        try {
            const result = await APIInstance.get(`/cp-manager/user/${userId}/institution`);
            return result.data;
        } catch (error) {
            throw error
        }
    }

    public async bindInst(userId: string, instId: string) {
        try {
            return await APIInstance.put(`/cp-manager/user/${userId}/institution`, { inst_id: instId });
        } catch (error) {
            throw error
        }
    }

    public async deleteUserInst(userId: string) {
        try {
            return await APIInstance.delete(`/cp-manager/user/${userId}/institution`);
        } catch (error) {
            throw error
        }
    }

    // HISTORY
    public async getHistoryItems(userId: string, page: number | undefined, pageSize: number | undefined) {
        try {
            const result = await APIInstance.get<UserHistoryLoggerDataListResponse>(`/cp-manager/user/${userId}/history`, {
                params: deleteUndefined({
                    limit: pageSize,
                    offset: pageSize && page ? pageSize * (page - 1) : 0
                })
            });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getHistoryItem(userId: string, historyId: number) {
        try {
            const result = await APIInstance.get<UserHistoryLoggerDataItemResponse>(`/cp-manager/user/${userId}/history/${historyId}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }
}
