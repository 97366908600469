import { Card, Col, Collapse, Row, Tag, Timeline, Typography } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { ClockCircleOutlined } from '@ant-design/icons';
import { EventData } from '@models/event-model';
dayjs.locale('ru');

const ListCard = (props: { event: EventData }) => {
    const { event } = props;
    const { t } = useTranslation();

    const [collapseActiveKey, setCollapseActiveKey] = useState<any>([]);

    const getDatesTitle = (event: EventData) => {
        if (!event.begin_date || !dayjs(event.begin_date).isValid()) return t('events_dashboard.list.timeline_title');
        return `${t('events_dashboard.list.begin_date')}: ${dayjs(event.begin_date).format('(dd) D MMM YYYY в H:mm')}`;
    };
    const [timelineTitle, setTimelineTitle] = useState(getDatesTitle(event));

    const getTimelineItemsForListCard = (event: EventData) => {
        let items: any = [];

        let dates = [
            {
                label: t('events_dashboard.list.announcing_date'),
                date: event.announcing_date
            },
            {
                label: t('events_dashboard.list.registration_begin_date'),
                date: event.registration_begin_date
            },
            {
                label: t('events_dashboard.list.registration_end_date'),
                date: event.registration_end_date
            },
            {
                label: t('events_dashboard.list.begin_date'),
                date: event.begin_date
            },
            {
                label: t('events_dashboard.list.end_date'),
                date: event.end_date
            },
            {
                label: t('events_dashboard.list.today'),
                date: new Date(),
                dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />
            }
        ];
        dates = dates.filter((item) => item && dayjs(item.date).isValid());
        dates = dates.sort((a, b) => {
            return dayjs(a.date).toDate().valueOf() - dayjs(b.date).toDate().valueOf();
        });

        for (const date of dates) {
            items.push({
                label: date.label,
                children: dayjs(date.date).format('(dd) D MMM YYYY в H:mm'),
                color: date.dot ? 'blue' : dayjs(date.date).isBefore(new Date()) ? 'green' : 'gray',
                dot: date.dot
            });
        }
        return items;
    };

    return (
        <Card
            title={<a href={`events-manage/edit/${event.id || ''}`}>{event.name}</a>}
            extra={
                <div>
                    {event.result_type && (
                        <Tag color={event.result_type?.primary_color ? event.result_type.primary_color : undefined}>{event.result_type.title}</Tag>
                    )}
                </div>
            }
        >
            <Row gutter={[0, 16]} justify="space-between">
                {event.description && event.description.length > 0 && (
                    <Col span={24}>
                        <Card.Meta description={event.description} />
                    </Col>
                )}
                {event.address && event.address.length > 0 && (
                    <Col span={24}>
                        <Typography.Text>{event.address}</Typography.Text>
                    </Col>
                )}
                <Col span={24}>
                    <Collapse
                        collapsible="header"
                        defaultActiveKey={'timeline'}
                        activeKey={collapseActiveKey}
                        onChange={(key: string | string[]) => {
                            if (typeof key === 'object' && key.length === 0) {
                                setTimelineTitle(getDatesTitle(event));
                            } else {
                                setTimelineTitle(t('events_dashboard.list.timeline_title') || '');
                            }
                            setCollapseActiveKey(key);
                        }}
                        items={[
                            {
                                key: 'timeline',
                                label: timelineTitle,
                                children: <Timeline mode="left" items={getTimelineItemsForListCard(event)} />
                            }
                        ]}
                    />
                </Col>
            </Row>
        </Card>
    );
};

export default ListCard;
