import { useState } from 'react';
import { Table, Form, Typography, Tooltip, Button } from 'antd';
import { useEffect } from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { DictionaryInfo } from '@models/dictionary-model';

interface TableProps {
    rows: DictionaryInfo[];
    loading: boolean;
    onEdit: (item: DictionaryInfo) => void;
}

export const DataTable = (props: TableProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [data, setData] = useState<DictionaryInfo[]>([]);

    useEffect(() => {
        setData(props.rows);
    }, [props]);

    const columns = [
        {
            title: t('dictionaries.content.name'),
            render: (_: any, record: DictionaryInfo) => {
                return <Typography.Text strong>{record.name}</Typography.Text>;
            }
        },
        {
            title: t('dictionaries.content.altname'),
            render: (_: any, record: DictionaryInfo) => {
                return <Typography.Text copyable>{record.altname}</Typography.Text>;
            }
        },
        {
            title: t('dictionaries.content.public_api_link'),
            render: (_: any, record: DictionaryInfo) => {
                return <Typography.Text copyable>{record.public_api_link}</Typography.Text>;
            }
        },
        {
            title: '',
            width: '10%',
            dataIndex: 'operations',
            render: (_: any, record: DictionaryInfo) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Tooltip title={t('common.open')}>
                            <Button onClick={() => props.onEdit(record)}>
                                <ArrowRightOutlined />
                            </Button>
                        </Tooltip>
                    </div>
                );
            }
        }
    ];

    return (
        <Form form={form} component={false}>
            <Table
                size="small"
                loading={props.loading}
                bordered
                dataSource={data}
                columns={columns}
                rowClassName="row"
                rowKey={'altname'}
                pagination={false}
            />
        </Form>
    );
};
