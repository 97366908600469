import { EmailTemplateData, EmailTemplateFile, EmailTemplateModel } from '@models/email-template-model';
import { APIUtilError } from '@utils/api';
import { makeAutoObservable, runInAction } from 'mobx';

export interface EmailTemplateEditViewModelStartParams {
    t: any;
    templateId: string | undefined;
    showError: (title: string, description: string) => void;
    showSuccess: (title: string, description: string) => void;
    close: () => void;
}

export class EmailTemplateEditViewModel {
    private emailTemplateModel = new EmailTemplateModel();
    public isLoading: boolean = false;
    public isFilesLoading: boolean = false;
    public isPublished = false;
    public template: EmailTemplateData = new EmailTemplateData();
    public files: EmailTemplateFile[] = [];

    constructor(public props: EmailTemplateEditViewModelStartParams) {
        if (this.props.templateId && this.props.templateId.length > 0) this.isPublished = true;
        makeAutoObservable(this);
        this.wakeUpSir();
    }

    private async wakeUpSir() {
        if (this.isPublished && this.props.templateId && this.props.templateId.length > 0) {
            await this.fetchTemplate(this.props.templateId);
            await this.fetchFiles();
        }
    }

    private async fetchTemplate(templateId: string) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const template = await this.emailTemplateModel.getTemplateById(templateId);
            runInAction(() => {
                this.template = template.data;
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
            if ((error as APIUtilError).response?.status === 404) {
                this.props.close();
            }
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    private async fetchFiles() {
        runInAction(() => {
            this.isFilesLoading = true;
        });
        try {
            const template = await this.emailTemplateModel.getFiles();
            runInAction(() => {
                this.files = template.data;
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
            if ((error as APIUtilError).response?.status === 404) {
                this.props.close();
            }
        } finally {
            runInAction(() => {
                this.isFilesLoading = false;
            });
        }
    }

    public onSave = async (template: EmailTemplateData) => {
        await this.save(template);
    };

    private save = async (template: EmailTemplateData) => {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            await this.emailTemplateModel.updateTemplate(template);
            this.props.showSuccess(this.props.t('common.saved'), '');
        } catch (error) {
            this.props.showError(this.props.t('common.error.save'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };

    public updateFiles() {
        this.fetchFiles();
    }

    public getOpenFileUrl(file: EmailTemplateFile) {
        return this.emailTemplateModel.getDownloarFileUrl(file.uuid);
    }

    public getUploaderURL() {
        return this.emailTemplateModel.getUploadFileURL();
    }

    public getUploaderHeaders() {
        return this.emailTemplateModel.uploadHeaders;
    }

    public getDownloadUrl(uuid: string): string | undefined {
        return this.emailTemplateModel.getDownloarFileUrl(uuid);
    }

    public async deleteFile(uuid: string): Promise<boolean> {
        try {
            await this.emailTemplateModel.deleteFile(uuid);
            return true;
        } catch (error) {
            this.props.showError(this.props.t('common.error.empty'), (error as APIUtilError).localizedDescription);
            return false;
        }
    }
}
