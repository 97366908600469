import { DeleteOutlined, DownloadOutlined, ExclamationCircleOutlined, EyeOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { ReportAttachedFile } from '@models/report';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';

interface TableProps {
    currentPage: number;
    pageSize: number;
    rows: ReportAttachedFile[];
    total: number;
    loading: boolean;
    onChangePagination: (page: number, pageSize: number) => void;
}

export const ObuchEventsTable = (props: TableProps) => {
    const { t } = useTranslation();
    const [data, setData] = useState<ReportAttachedFile[]>([]);

    useEffect(() => {
        setData(props.rows);
    }, [props]);

    const onChangePage = (page: number, pageSize: number) => {
        props.onChangePagination(page, pageSize);
    };

    const obuch_status: any = {
        draft: {
            font: '#d0cee1',
            border: '#d0cee1',
            bg: '#FFFFFF',
            id: '1',
            title: 'Черновик',
            class: 'gray',
            desc: 'Данные сохранены. Необходима проверка'
        },
        verified: {
            font: '#1b1b1b',
            border: '#fcaf17',
            bg: '#FFFFFF',
            id: '2',
            title: 'Готово к загрузке',
            class: 'yellow',
            desc: 'Данные проверены и готовы к загрузке. Редактирование возможно, но приведет к возврату к статусу "Черновик"'
        },
        uploaded: {
            font: '#FFFFFF',
            border: '#FFFFFF',
            bg: '#8f00ff',
            id: '3',
            title: 'Загружено',
            class: 'purple',
            desc: 'Данные загружены. Редактирование возможно, но приведет к изменению статуса на "Изменено"'
        },
        updated: {
            font: '#1b1b1b',
            border: '#FBB3C1',
            bg: '#FFFFFF',
            id: '4',
            title: 'Изменено',
            class: 'pink',
            desc: 'Данные изменены. Необходима проверка'
        },
        updated_verified: {
            font: '#1b1b1b',
            border: '#fcaf17',
            bg: '#FFFFFF',
            id: '5',
            title: 'Изменено, ожидает загрузки',
            class: 'yellow',
            desc: 'Данные проверены и готовы к загрузке. Редактирование возможно, но приведет к возврату к статусу "Изменено"'
        },
        uploaded_with_errors: {
            font: '#FFFFFF',
            border: '#FFFFFF',
            bg: '#fd493d',
            id: '6',
            title: 'Загружено с ошибками',
            class: 'red',
            desc: 'Данные загружены частично. Необходимо отредактировать ошибочные данные'
        }
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'event_id'
        },
        {
            title: t('reports.obuch_event.name'),
            dataIndex: 'event_name'
        },
        {
            title: t('reports.obuch_event.status'),
            render: (_: any, record: any) => {
                return obuch_status[record.status].title;
            }
        }
    ];

    return (
        <Table
            size="small"
            loading={props.loading}
            bordered
            dataSource={data}
            columns={columns}
            rowClassName="row"
            rowKey={'id'}
            pagination={{
                current: props.currentPage,
                pageSize: props.pageSize,
                showSizeChanger: false,
                total: props.total,
                position: ['bottomCenter'],
                onChange: onChangePage
            }}
        />
    );
};
