import { observer } from 'mobx-react-lite';
import { Drawer, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import React from 'react';
import ValuesTable from './table';
import { useViewModel } from '@models/model';
import { IndicatorValueEditViewModel } from './value.model';

interface Props {
    indicatorId: string;
    isVisible: boolean;
    onClose: () => void;
}

export const IndicatorValueEdit: React.FC<Props> = observer(({ indicatorId, isVisible, onClose }) => {
    const { t } = useTranslation();
    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (description: string) => {
        notifyer.error({
            message: t('common.error.empty'),
            description: description
        });
    };
    const showSuccessNotification = (title: string, description: string) => {
        notifyer.success({
            message: title,
            description: description
        });
    };

    const viewModel = useViewModel(
        () => new IndicatorValueEditViewModel({ indicatorId, t, showError: showErrorNotification, showSuccess: showSuccessNotification })
    );

    return (
        <Drawer forceRender title={t('indicator.content.indicator_value.edit.title')} width={750} onClose={onClose} open={isVisible} destroyOnClose>
            {contextHolder}
            <ValuesTable
                rows={viewModel.indicatorValues}
                currentPage={viewModel.currentPage}
                pageSize={viewModel.pageSize}
                total={viewModel.dataTotal}
                loading={viewModel.isLoading}
                onChangePagination={viewModel.onChangePagination}
                onSave={(id: number, value: number, is_locked: boolean) => viewModel.updateValues(id, value, is_locked)}
            />
        </Drawer>
    );
});
