import { Button, Card, Divider, Drawer, Form, InputNumber, Space, Typography, Select, DatePicker, notification } from 'antd';
import '@assets/css/App.css';
import { plainToInstance } from 'class-transformer';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FinanceData } from '@models/finance-model';
import { FinanceTransactionTypeDictionaryData } from '@models/dictionary-model';
import { instanceToPlain } from 'class-transformer';
import dayjs from 'dayjs';

interface Props {
    visible: boolean;
    finance: FinanceData;
    transaction_type_dict: FinanceTransactionTypeDictionaryData[];
    onClose: () => void;
    onSave: (finance: FinanceData) => void;
}

const EditFinace: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const [notifyer, contextHolder] = notification.useNotification();
    const showError = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    useEffect(() => {
        form.resetFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.finance]);

    const onClose = async () => {
        props.onClose();
    };

    const onSave = async () => {
        try {
            const valFromFields: Record<string, any> = form.getFieldsValue();
            var converted: FinanceData = plainToInstance(FinanceData, {
                id: props.finance?.id,
                transaction_type_id: valFromFields.transaction_type_id,
                inv_volume: valFromFields.inv_volume
            });
            await save(converted);
        } catch (error) {
            showError(t('common.error.save'), `${error}`);
        }
    };

    const save = async (finance: FinanceData) => {
        const result = await props.onSave(finance);
        if (typeof result === 'string') {
            showError(t('common.error.save'), `${result}`);
        } else {
            onClose();
        }
    };

    const prepareDataForForm = () => {
        let data = instanceToPlain(props.finance);
        data.invest_date = data.invest_date ? dayjs(data.invest_date, 'YYYY-MM-DD') : data.invest_date;
        return data;
    };

    const getFinanceTransationTypeSelectorItems = () =>
        props.transaction_type_dict.map((item) => {
            return {
                value: item.id,
                label: item.name
            };
        });

    return (
        <Drawer
            forceRender
            title={t('finances.edit.title')}
            width={500}
            onClose={onClose}
            open={props.visible}
            bodyStyle={{ paddingBottom: 80 }}
            extra={
                <Space>
                    <Button onClick={onSave} type="primary">
                        {t('common.save')}
                    </Button>
                </Space>
            }
        >
            {contextHolder}

            <Card title="id" size="small">
                <Typography.Text copyable>{props.finance.id}</Typography.Text>
            </Card>

            <Divider />
            <Form layout="vertical" form={form} initialValues={prepareDataForForm()} requiredMark={true}>
                <Form.Item
                    name={'transaction_type_id'}
                    label={t('finances.content.transaction_type')}
                    rules={[{ required: true, message: t('common.required') || '' }]}
                >
                    <Select
                        options={getFinanceTransationTypeSelectorItems()}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    />
                </Form.Item>
                <Form.Item
                    name="invest_date"
                    label={t('finances.content.invest_date')}
                    rules={[{ required: true, message: t('common.required') || '' }]}
                >
                    <DatePicker style={{ width: '100%' }} showNow={false} format={'DD-MM-YYYY'} disabled />
                </Form.Item>
                <Form.Item
                    name="inv_volume"
                    label={t('finances.content.inv_volume')}
                    rules={[{ required: true, message: t('common.required') || '' }]}
                >
                    <InputNumber style={{ width: '100%' }} min={0} addonAfter="₽" />
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default EditFinace;
