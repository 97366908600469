import { difference, keys, uniq } from 'lodash';

export const deleteUndefined = (object: any) => {
    Object.keys(object).forEach((key) => (object[key] === undefined ? delete object[key] : {}));
    return object;
};

export interface ObjectLiteral {
    [key: string]: any;
}

export const isObjectsHasDifferences = (firstObject: any, secondObject: any) => {
    const hasDifferenceByKeys = difference(keys(firstObject), keys(secondObject)).length > 0;
    let hasDifferenceByContent = false;
    if (!hasDifferenceByKeys) {
        for (const key of uniq([...keys(firstObject), ...keys(secondObject)])) {
            const first = (firstObject as ObjectLiteral)[key];
            const second = (secondObject as ObjectLiteral)[key];
            if (!first || !second) {
                hasDifferenceByContent = true;
                break;
            }
            if (JSON.stringify(first) !== JSON.stringify(second)) {
                hasDifferenceByContent = true;
                break;
            }
        }
    }

    if (hasDifferenceByKeys || hasDifferenceByContent) {
        return true;
    }
    return false;
};
