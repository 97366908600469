import { useState } from 'react';
import { Table, Form, Tooltip, Button } from 'antd';
import { useEffect } from 'react';

import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { EmailTemplateData } from '@models/email-template-model';

interface TableProps {
    pageSize: number;
    rows: EmailTemplateData[];
    total: number;
    loading: boolean;
    onChangePagination: (page: number, pageSize: number) => void;
    onEdit: (item: EmailTemplateData) => void;
}

export const TemplateTable = (props: TableProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [data, setData] = useState<EmailTemplateData[]>([]);

    useEffect(() => {
        setData(props.rows);
    }, [props]);

    const onChangePage = (page: number, pageSize: number) => {
        props.onChangePagination(page, pageSize);
    };

    const columns = [
        {
            title: 'ID',
            width: '1%',
            dataIndex: 'id'
        },
        {
            title: t('emailTemplate.content.name.title'),
            dataIndex: 'name'
        },
        {
            title: '',
            width: '5%',
            dataIndex: 'operations',
            render: (_: any, record: EmailTemplateData) => {
                return (
                    <Tooltip title={t('common.edit')}>
                        <Button onClick={() => props.onEdit(record)}>
                            <EditOutlined />
                        </Button>
                    </Tooltip>
                );
            }
        }
    ];

    return (
        <Form form={form} component={false}>
            <Table
                size="small"
                loading={props.loading}
                bordered
                dataSource={data}
                columns={columns}
                rowClassName="row"
                rowKey={'id'}
                pagination={{
                    pageSize: props.pageSize,
                    total: props.total,
                    position: ['bottomCenter'],
                    onChange: onChangePage
                }}
            />
        </Form>
    );
};
