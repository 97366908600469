import { deleteUndefined } from '@utils/object.utils';
import { GetEmailTemplatesResponse, EmailTemplateData, EmailTemplateResponse, GetEmailTemplateFilesResponse } from './email-template.contract';
import { EmailTemplateGetFilter } from '@interfaces/email-template.interface';
import { APIInstance, BaseURL } from '@utils/api';
import { RootModel } from '@models/root-model';

export class EmailTemplateModel {
    public async getTemplates(params: EmailTemplateGetFilter): Promise<GetEmailTemplatesResponse> {
        try {
            const result = await APIInstance.get<GetEmailTemplatesResponse>('/cp-manager/email-template', {
                params: deleteUndefined({
                    limit: params.page_size,
                    offset: params.page_size && params.page ? params.page_size * (params.page - 1) : 0
                })
            });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getTemplateById(id: string | number): Promise<EmailTemplateResponse> {
        try {
            const result = await APIInstance.get<EmailTemplateResponse>(`/cp-manager/email-template/${id}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async updateTemplate(template: EmailTemplateData): Promise<EmailTemplateResponse> {
        try {
            const result = await APIInstance.put<EmailTemplateResponse>(`/cp-manager/email-template/${template.id}`, { ...template });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getFiles(): Promise<GetEmailTemplateFilesResponse> {
        try {
            const result = await APIInstance.get<GetEmailTemplateFilesResponse>(`/cp-manager/email-template/file`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public getDownloarFileUrl(fileUUID: string) {
        return `${BaseURL}/cp-manager/email-template/file/${fileUUID}`;
    }

    public getUploadFileURL() {
        return `${BaseURL}/cp-manager/email-template/file`;
    }

    public uploadHeaders = async () => {
        let accessToken = RootModel?.authModel?.getAccessToken();
        if (!accessToken) {
            await RootModel?.authModel?.updateToken();
            accessToken = RootModel?.authModel?.getAccessToken();
        }
        return { Authorization: RootModel?.authModel ? `Bearer ${accessToken}` : '' };
    };

    public async deleteFile(fileUUID: string) {
        try {
            const result = await APIInstance.delete(`/cp-manager/email-template/file/${fileUUID}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }
}
