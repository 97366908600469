import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import '@assets/css/App.css';
import { Button, Col, Divider, Form, Input, notification, Row, Select, Space, Spin, Typography } from 'antd';
import { useViewModel } from '@models/model';
import { useTranslation } from 'react-i18next';
import { StartupEditViewModel } from './startup.edit.view.model';
import { plainToInstance } from 'class-transformer';
import { useNavigate, useParams } from 'react-router-dom';
import { StartupData } from '@models/startup-model';
import { formatDate } from '@utils/date.utils';

const StartupEditView: React.FC = observer(() => {
    const { t } = useTranslation();
    const { id: startupId } = useParams();
    const navigate = useNavigate();

    const [notifyer, contextHolder] = notification.useNotification();
    const showSuccessNotification = (title: string, description: string) => {
        notifyer.success({
            message: title,
            description: description
        });
    };
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    const onClickCancel = () => {
        navigate('/startups-manage');
    };

    const viewModel = useViewModel(
        () =>
            new StartupEditViewModel({
                t,
                startupId,
                showSuccess: showSuccessNotification,
                showError: showErrorNotification
            })
    );
    const [form] = Form.useForm<StartupData>();

    useEffect(() => {
        form.resetFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewModel.startup]);

    const getFormData = () => {
        const valFromFields: Record<string, any> = form.getFieldsValue();
        var converted: StartupData = plainToInstance(StartupData, { ...viewModel.startup, ...valFromFields });

        return converted;
    };

    const onSuccessFormValidation = async () => {
        const data = getFormData();
        await viewModel.onSave(data);
    };

    const getRegionSelectorContent = () =>
        viewModel.regionDictionary.map((item) => {
            return {
                value: item.id,
                label: item.name
            };
        });

    const getInstitutionSelectorContent = () =>
        viewModel.institutionDictionary.map((item) => {
            return {
                value: item.id,
                label: item.name
            };
        });

    const getMonthSelectorContent = () =>
        viewModel.MonthDictionary.map((item) => {
            return {
                value: item.id,
                label: `${item.name} ${item.year}`
            };
        });

    return (
        <div>
            <br />

            <div>
                {contextHolder}
                <Row gutter={12} justify="end" align="middle">
                    <Col>
                        <Button onClick={onClickCancel}>{t('common.back')}</Button>
                    </Col>
                </Row>

                <br />
                <fieldset disabled={true} style={{ border: 'none', padding: '0', margin: '0' }}>
                    <Form form={form} initialValues={viewModel.startup} layout="horizontal" onFinish={onSuccessFormValidation}>
                        <div className="edit-content-view">
                            <Spin spinning={viewModel.isLoading}>
                                <Form.Item>
                                    <Space size="large">
                                        <div>
                                            <Typography.Text>ID: </Typography.Text>
                                            <Typography.Text strong copyable>
                                                {viewModel.startup.id}
                                            </Typography.Text>
                                        </div>
                                        {viewModel.startup.startup_date && (
                                            <div>
                                                <Typography.Text>{t('startups.content.startup_date.title')}: </Typography.Text>
                                                <Typography.Text strong>
                                                    {formatDate(viewModel.startup.startup_date, t('common.date.pattern.date'))}
                                                </Typography.Text>
                                            </div>
                                        )}
                                        {viewModel.startup.startup_liquidation_date && (
                                            <div>
                                                <Typography.Text>{t('startups.content.startup_liquidation_date.title')}: </Typography.Text>
                                                <Typography.Text strong>
                                                    {formatDate(viewModel.startup.startup_liquidation_date, t('common.date.pattern.date'))}
                                                </Typography.Text>
                                            </div>
                                        )}
                                    </Space>
                                </Form.Item>

                                <Form.Item name="name" label={t('startups.content.name.title')}>
                                    <Input />
                                </Form.Item>

                                <Form.Item name="short_name" label={t('startups.content.short_name.title')}>
                                    <Input />
                                </Form.Item>

                                <Form.Item name={'region_id'} label={t('startups.content.region.title')}>
                                    <Select
                                        open={false}
                                        options={getRegionSelectorContent()}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    />
                                </Form.Item>

                                <Form.Item name={'institution_id'} label={t('startups.content.institution.title')}>
                                    <Select
                                        open={false}
                                        options={getInstitutionSelectorContent()}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    />
                                </Form.Item>

                                <Form.Item name="contact_fio" label={t('startups.content.contact_fio.title')}>
                                    <Input />
                                </Form.Item>

                                <Form.Item name="contact_phone" label={t('startups.content.contact_phone.title')}>
                                    <Input />
                                </Form.Item>

                                <Form.Item name="contact_email" label={t('startups.content.contact_email.title')}>
                                    <Input />
                                </Form.Item>

                                <Form.Item name={'month_id'} label={t('startups.content.month.title')}>
                                    <Select
                                        open={false}
                                        options={getMonthSelectorContent()}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    />
                                </Form.Item>
                            </Spin>
                        </div>

                        <br />
                        <Divider orientation="left">{t('startups.content.org_info')}</Divider>
                        <div className="edit-content-view">
                            <Form.Item name="startup_address" label={t('startups.content.startup_address.title')} rules={[]}>
                                <Input.TextArea rows={2} placeholder={t('startups.content.startup_address.placeholder') || ''} />
                            </Form.Item>

                            <Form.Item name="inn" label={t('common.inn')}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="kpp" label={t('common.kpp')}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="ogrn" label={t('common.ogrn')}>
                                <Input />
                            </Form.Item>
                        </div>
                    </Form>
                </fieldset>
            </div>
            <br />
            <br />
        </div>
    );
});

export default StartupEditView;
