import { Switch, Modal, Table, Row, Col, Button, Radio, notification } from 'antd';
import type { RadioChangeEvent } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { ApiErrorsViewModel } from './api-errors-modal.view.model';
import { OperatorError } from '@models/operator-model';
import { useViewModel } from '@models/model';


interface ApiErrorsModalProps {
    apiId?: number;
    apiDataStorageId?: number;
    showModal: boolean;
    fetchOnCommand?: boolean;

    closeModal: () => void;
}

const ApiErrorsModal: React.FC<ApiErrorsModalProps> = observer((props: ApiErrorsModalProps) => {
    const { apiId, apiDataStorageId, showModal, closeModal } = props;
    const { t } = useTranslation();

    const [modalErrorsFilter, setModalErrorsFilter] = useState(null);

    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };
    const viewModel = useViewModel(
        () =>
            new ApiErrorsViewModel({
                apiId,
                apiDataStorageId,
                t,
                showError: showErrorNotification
            })
    );

    const filterApiErrors = (e: RadioChangeEvent) => {
        setModalErrorsFilter(e.target.value);
        viewModel.fetchErrors(viewModel.activeErrorsApiId, viewModel.errorPageSize, 0, e.target.value);
    };

    const addErrorToSolved = (errId: number, solve: boolean) => {
        viewModel.solvedErrors = [errId];
        viewModel.setSolvedErrors(!solve);
        viewModel.fetchErrors(viewModel.activeErrorsApiId);
    };

    const solveAll = async (solve: boolean) => {
        viewModel.solvedErrors = [];
        viewModel.apiErrors = [];
        await viewModel.setSolvedErrors(solve);
        await viewModel.fetchErrors(viewModel.activeErrorsApiId);
    };

    const deleteAll = async () => {
        await viewModel.deleteErrors(viewModel.activeErrorsApiId);
        closeModal();
    };

    const apiErrorsColumns: any = [
        {
            title: 'ID',
            dataIndex: 'id'
        },
        {
            title: t('operators.content.errors.table.title'),
            dataIndex: 'title'
        },
        {
            title: t('operators.content.errors.table.errorLog'),
            dataIndex: 'error_log',
            render: (_: any, record: OperatorError) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {record.error_log.split('\n').map((item, i) => (
                            <div key={`error_${i}`} style={{ marginBottom: '10px' }}>
                                {item}
                            </div>
                        ))}
                    </div>
                );
            }
        },
        {
            title: t('operators.content.errors.table.is_solved'),
            dataIndex: 'is_solved',
            render: (_: any, record: OperatorError) => {
                return <Switch defaultChecked={record.is_solved} onChange={() => addErrorToSolved(record.id, record.is_solved)} />;
            }
        }
    ];

    return (
        <Modal
            title={t('operators.content.errors.title')}
            open={showModal}
            onCancel={() => closeModal()}
            footer={null}
            width={window.innerWidth * 0.8}
        >
            {contextHolder}

            <Row justify="space-between" style={{ marginBottom: '15px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Col style={{ marginRight: '10px' }}>{t('operators.content.errors.table.filter.title')}</Col>
                    <Col>
                        <Radio.Group value={modalErrorsFilter} onChange={filterApiErrors}>
                            <Radio value={null}>{t('operators.content.errors.table.filter.all')}</Radio>
                            <Radio value={true}>{t('operators.content.errors.table.filter.solved')}</Radio>
                            <Radio value={false}>{t('operators.content.errors.table.filter.not_solved')}</Radio>
                        </Radio.Group>
                    </Col>
                </div>
                <Col>
                    <Button onClick={() => solveAll(true)} style={{ marginRight: '10px' }} type="primary">
                        {t('operators.content.errors.table.solve_all')}
                    </Button>
                    <Button onClick={() => solveAll(false)} style={{ marginRight: '10px' }}>
                        {t('operators.content.errors.table.cancel_all')}
                    </Button>
                    <Button onClick={() => deleteAll()} className="users-edit-delete-account-button">
                        {t('operators.content.errors.table.delete_all')}
                    </Button>
                </Col>
            </Row>
            <Table
                size="small"
                bordered
                loading={viewModel.isLoadingErrors}
                dataSource={viewModel.apiErrors}
                columns={apiErrorsColumns}
                rowClassName="row"
                rowKey={(record: OperatorError) => `error_${record.id}`}
                pagination={{
                    pageSize: viewModel.errorPageSize,
                    total: viewModel.errorPageTotal,
                    position: ['bottomCenter'],
                    onChange: viewModel.onChangeErrorPage
                }}
            />
        </Modal>
    );
});

export default ApiErrorsModal;
