import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import '@assets/css/App.css';
import { Button, Col, Form, Input, notification, Row, Spin, Switch } from 'antd';
import { useViewModel } from '@models/model';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined, RobotOutlined } from '@ant-design/icons';
import { ResultTypeEditViewModel } from './result-type.edit.view.model';
import { plainToInstance } from 'class-transformer';
import { useNavigate, useParams } from 'react-router-dom';
import { ResultTypeData } from '@models/result-type-model';

const ResultTypeEditView: React.FC = observer(() => {
    const { t } = useTranslation();
    const { id: resultId } = useParams();
    const navigate = useNavigate();

    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (description: string) => {
        notifyer.error({
            message: t('common.error.empty'),
            description: description
        });
    };

    const onFinish = () => {
        onClickCancel();
    };

    const onClickCancel = () => {
        navigate('/result-types-manage');
    };

    const viewModel = useViewModel(
        () => new ResultTypeEditViewModel(resultId, (description: string) => showErrorNotification(description), onFinish)
    );
    const [form] = Form.useForm<ResultTypeData>();
    const [isAltNameAuto, setIsAltNameAuto] = useState(true);
    const [generatedAltName, setGeneratedAltName] = useState(viewModel.resultType.alt_title || '');

    useEffect(() => {
        form.resetFields();
        setGeneratedAltName(viewModel.resultType.alt_title || '');
        setIsAltNameAuto(viewModel.resultType.alt_title === viewModel.generateAltName(viewModel.resultType.title));
    }, [viewModel.resultType]);

    const onChangeTitle = (value: string) => {
        setGeneratedAltName(viewModel.generateAltName(value));
    };

    const onChangeAltNameSwitch = () => {
        setIsAltNameAuto(!isAltNameAuto);
        form.setFieldsValue({ alt_title: generatedAltName });
    };

    const onClickSave = () => form.submit();

    const getFormData = () => {
        const valFromFields: Record<string, any> = form.getFieldsValue();
        var converted: ResultTypeData = plainToInstance(ResultTypeData, { ...viewModel.resultType, ...valFromFields });

        if (isAltNameAuto) {
            converted.alt_title = generatedAltName;
        }
        return converted;
    };

    const onSuccessFormValidation = async () => {
        const data = getFormData();
        await viewModel.onSave(data);
    };

    return (
        <div>
            <br />
            <Spin spinning={viewModel.loading}>
                <div>
                    {contextHolder}
                    <Row gutter={12} justify="end" align="middle">
                        <Col>
                            <Button onClick={onClickCancel}>
                                {t('common.cancel')}
                            </Button>
                        </Col>
                        <Col>
                            <Button type="primary" onClick={onClickSave} loading={viewModel.loading} disabled={viewModel.loading}>
                                {t('common.save')}
                            </Button>
                        </Col>
                    </Row>

                    <br />
                    <div className="edit-content-view">
                        <Form
                            form={form}
                            initialValues={viewModel.resultType}
                            layout="horizontal"
                            onFinish={onSuccessFormValidation}
                        >
                            <Form.Item
                                name="title"
                                label={t('result_types.content.title.title')}
                                rules={[{ required: true, message: t('result_types.content.title.required') || '' }]}
                            >
                                <Input
                                    placeholder={t('result_types.content.title.placeholder') || ''}
                                    onChange={(event) => onChangeTitle(event.currentTarget.value)}
                                />
                            </Form.Item>
                            <Form.Item
                                name="alt_title"
                                label={t('result_types.content.alt_title.title')}
                                tooltip={t('result_types.content.alt_title.hint')}
                                required
                            >
                                <Row gutter={12}>
                                    <Col span={20}>
                                        {isAltNameAuto === true ? (
                                            <Form.Item noStyle>
                                                <Input disabled value={generatedAltName} />
                                            </Form.Item>
                                        ) : (
                                            <Form.Item
                                                name="alt_title"
                                                rules={[{ required: true, message: t('result_types.content.alt_title.required') || '' }]}
                                            >
                                                <Input placeholder={t('result_types.content.alt_title.placeholder') || ''} />
                                            </Form.Item>
                                        )}
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item label={<RobotOutlined />} tooltip={t('result_types.content.alt_title.auto')}>
                                            <Switch checked={isAltNameAuto} onChange={onChangeAltNameSwitch} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form.Item>
                            <Form.Item
                                label={t('result_types.content.description.title')}
                                name="description"
                                rules={[{ required: true, message: t('result_types.content.description.required') || '' }]}
                            >
                                <Input placeholder={t('result_types.content.description.placeholder') || ''} />
                            </Form.Item>

                            <Form.Item
                                label={t('result_types.content.dashboard_link.title')}
                                name="dashboard_link"
                                rules={[{ required: true, message: t('result_types.content.dashboard_link.required') || '' }]}
                            >
                                <Input placeholder={t('result_types.content.dashboard_link.placeholder') || ''} />
                            </Form.Item>

                            <Form.Item
                                name="filter_isset"
                                label={t('result_types.content.filter_isset.title')}
                                tooltip={t('result_types.content.filter_isset.hint')}
                                valuePropName="checked"
                            >
                                <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                            </Form.Item>

                            <Form.Item
                                name="filter_by_subject"
                                label={t('result_types.content.filter_by_subject.title')}
                                tooltip={t('result_types.content.filter_by_subject.hint')}
                                valuePropName="checked"
                            >
                                <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                            </Form.Item>

                            <Form.Item
                                name="filter_by_org"
                                label={t('result_types.content.filter_by_org.title')}
                                tooltip={t('result_types.content.filter_by_org.hint')}
                                valuePropName="checked"
                            >
                                <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                            </Form.Item>

                            <Form.Item
                                name="items_isset"
                                label={t('result_types.content.items_isset.title')}
                                tooltip={t('result_types.content.items_isset.hint')}
                                valuePropName="checked"
                            >
                                <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Spin>
        </div>
    );
});

export default ResultTypeEditView;
