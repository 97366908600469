import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect } from 'react';
import { Divider, Form, Input, Select, Skeleton, Tag, Typography, notification } from 'antd';
import { EditableTable } from './table';
import { useViewModel } from '@models/model';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { StartupsViewModel } from './startup-project.view.model';
import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { StartupProjectData } from '@models/startup-project-model';
import { ObjectLiteral } from '@utils/object.utils';
import { windowOpen } from '@utils/window.utils';

const StartupProjectView: React.FC = observer(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [search, setSearch] = useSearchParams();
    const setSearchParams = (params: any) => setSearch(params);
    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    const viewModel = useViewModel(() => new StartupsViewModel({ t, searchParams: search, setSearchParams, showError: showErrorNotification }));
    const [filterForm] = Form.useForm();

    useEffect(() => {
        updateIfNeeded();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const updateIfNeeded = async () => {
        const isResetNeeded = await viewModel.updateSearchParamsIfNeeded(search);
        if (isResetNeeded) {
            filterForm.resetFields();
        }
    };

    const onFilterValueChange = () => {
        const valFromFields: ObjectLiteral = filterForm.getFieldsValue();
        viewModel.onFilterValueChange(valFromFields);
    };

    const onEdit = (startupId?: number | undefined) => {
        if (!startupId) return;
        windowOpen(`/startup-project-manage/edit/${startupId || ''}`);
    };
    const onShowMembers = (startup: StartupProjectData) => {
        if (!startup.id) return;
        navigate({
            pathname: '/members-manage',
            search: createSearchParams({
                startup_project_id: `${startup.id}`
            }).toString()
        });
    };
    const onShowStartups = (startup: StartupProjectData) => {
        if (!startup.id) return;
        navigate({
            pathname: '/startups-manage',
            search: createSearchParams({
                startup_project_id: `${startup.id}`
            }).toString()
        });
    };
    const onShowFinance = (startup: StartupProjectData) => {
        if (!startup.id) return;
        navigate({
            pathname: '/finances-manage',
            search: createSearchParams({
                startup_project_id: `${startup.id}`
            }).toString()
        });
    };
    const onShowRid = (startup: StartupProjectData) => {
        if (!startup.id) return;
        navigate({
            pathname: '/rids-manage',
            search: createSearchParams({
                startup_project_id: `${startup.id}`
            }).toString()
        });
    };

    const getStudiosSelectorContent = useCallback(
        () =>
            viewModel.startupStudios.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                };
            }),
        [viewModel.startupStudios]
    );

    const filters = () => {
        const infoCount = viewModel.filterInfoCount(viewModel.currentFilter);
        const infoFake = Array(infoCount).fill(0);

        return (
            <div className="edit-content-view">
                {infoCount > 0 && (
                    <Space style={{ width: '100%', marginTop: '10px' }} direction="horizontal" size="small">
                        {viewModel.isCurrentFilterLoading === true
                            ? infoFake.map((_, index) => <Skeleton.Input key={`skeleton-${index}`} active={true} size="small" />)
                            : viewModel.currentFilterInfo.map((infoItem, index) => (
                                  <Tag
                                      key={`tag-${index}`}
                                      closable
                                      onClose={() => {
                                          viewModel.onRemoveFilterInfo(infoItem);
                                      }}
                                      color="purple"
                                  >
                                      <Space>
                                          <FilterOutlined />
                                          <Typography.Text strong>
                                              {infoItem.name}: {infoItem.value}
                                          </Typography.Text>
                                      </Space>
                                  </Tag>
                              ))}
                    </Space>
                )}
                <Form form={filterForm} initialValues={viewModel.currentFilter} layout="vertical" onValuesChange={onFilterValueChange}>
                    <Form.Item name={'startup_studio_id'} label={t('startup_project.filter.startup_studio.title')}>
                        <Select
                            options={getStudiosSelectorContent()}
                            showSearch
                            onSearch={(value: string) => viewModel.searchStartupStudio(value)}
                            allowClear
                            filterOption={false}
                            placeholder={t('startup_project.filter.startup_studio.search')}
                        />
                    </Form.Item>
                    <Form.Item name={'search'} label={t('startup_project.filter.search.placeholder')}>
                        <Input prefix={<SearchOutlined />} allowClear placeholder={t('startup_project.filter.search.placeholder') || ''} />
                    </Form.Item>
                </Form>
            </div>
        );
    };

    return (
        <div>
            {contextHolder}
            <Space>
                <Typography.Title>{t('startup_project.title')}</Typography.Title>
                <Typography.Title style={{ marginTop: 25 }} level={2} type="secondary">
                    {viewModel.startupsTotal}
                </Typography.Title>
            </Space>

            <Divider style={{ marginTop: 0, marginBottom: 0 }} />
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                {filters()}

                <EditableTable
                    currentPage={viewModel.currentPage}
                    loading={viewModel.isLoading}
                    rows={viewModel.startups}
                    total={viewModel.startupsTotal}
                    pageSize={viewModel.pageSize}
                    onChangePagination={viewModel.onChangePagination}
                    onEdit={onEdit}
                    onShowMembers={onShowMembers}
                    onShowStartups={onShowStartups}
                    onShowFinance={onShowFinance}
                    onShowRid={onShowRid}
                />
            </Space>
        </div>
    );
});

export default StartupProjectView;
