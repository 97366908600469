import { APIInstance } from '@utils/api';
import { deleteUndefined } from '@utils/object.utils';
import { FinanceData, FinanceResponse, GetFinancesResponse } from './finance.contract';
import { FinancesFilter } from '@interfaces/finances.interface';

export interface GetParams {
    limit: number;
    offset: number;
}

export class FinanceModel {
    public async getFinances(params: FinancesFilter): Promise<GetFinancesResponse> {
        try {
            const result = await APIInstance.get<GetFinancesResponse>('/cp-manager/finance', {
                params: deleteUndefined({
                    result_type_id: params.result_type_id ? [params.result_type_id] : undefined,
                    result_id: params.result_id ? [params.result_id] : undefined,
                    startup_id: params.startup_id,
                    startup_project_id: params.startup_project_id,
                    limit: params.page_size,
                    offset: params.page_size && params.page ? params.page_size * (params.page - 1) : 0
                })
            });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getFinanceById(id: string | number): Promise<FinanceResponse> {
        try {
            const result = await APIInstance.get<FinanceResponse>(`/cp-manager/finance/${id}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async update(finance: FinanceData): Promise<FinanceResponse> {
        try {
            const result = await APIInstance.put<FinanceResponse>(`/cp-manager/finance/${finance.id}`, { ...finance });
            return result.data;
        } catch (error) {
            throw error;
        }
    }
}
