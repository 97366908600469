import { APIUtilError } from '@utils/api';
import { makeAutoObservable, runInAction } from 'mobx';
import cyrillicToTranslit from 'cyrillic-to-translit-js';
import { ResultTypeData, ResultTypeModel } from '@models/result-type-model';

export class ResultTypeEditViewModel {
    private resultTypeModel = new ResultTypeModel();
    public loading: boolean = false;
    public resultType: ResultTypeData = new ResultTypeData();

    constructor(public resultTypeId: string | undefined, private showError: (description: string) => void, private onFinish: () => void) {
        makeAutoObservable(this);
        this.wakeUpSir();
    }

    private async wakeUpSir() {
        if (this.resultTypeId && this.resultTypeId.length > 0) this.fetchResultType(this.resultTypeId);
    }

    private async fetchResultType(resultId: string) {
        runInAction(() => {
            this.loading = true;
        });
        try {
            const resultType = await this.resultTypeModel.getResultTypeById(resultId);
            runInAction(() => {
                this.resultType = resultType.data;
            });
        } catch (error) {
            this.showError((error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    public generateAltName = (name: string) => {
        return cyrillicToTranslit().transform(name, '_').toLocaleLowerCase();
    };

    public onSave = async (resultType: ResultTypeData) => {
        if (!this.resultTypeId) {
            await this.create(resultType);
        } else {
            await this.save(resultType);
        }
    };

    private create = async (resultType: ResultTypeData) => {
        runInAction(() => {
            this.loading = true;
        });
        try {
            await this.resultTypeModel.create(resultType);
            this.onFinish();
        } catch (error) {
            this.showError((error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    };

    private save = async (resultType: ResultTypeData) => {
        runInAction(() => {
            this.loading = true;
        });
        try {
            await this.resultTypeModel.update(resultType);
            this.onFinish();
        } catch (error) {
            this.showError((error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    };
}
