import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Button, Col, Divider, Empty, Form, Input, Row, Select, Skeleton, Tag, Tooltip, Typography, notification } from 'antd';
import { MembersTable } from './table';
import { useViewModel } from '@models/model';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { MembersViewModel } from './members.view.model';
import { FileExcelOutlined, FilterOutlined } from '@ant-design/icons';
import { ObjectLiteral } from '@utils/object.utils';
import ShowMember from './member.show.view';
import { GUIDForm } from './member.guid';

const MembersView: React.FC = observer(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [search, setSearch] = useSearchParams();
    const setSearchParams = (params: any) => setSearch(params);
    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    const viewModel = useViewModel(() => new MembersViewModel({ t, searchParams: search, setSearchParams, showError: showErrorNotification }));
    const [filterForm] = Form.useForm();

    useEffect(() => {
        updateIfNeeded();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const updateIfNeeded = async () => {
        const isResetNeeded = await viewModel.updateSearchParamsIfNeeded(search);
        if (isResetNeeded) {
            filterForm.resetFields();
        }
    };

    const onFilterValueChange = () => {
        const valFromFields: ObjectLiteral = filterForm.getFieldsValue();
        viewModel.onFilterValueChange(valFromFields);
    };
    const onSetGuid = (guid?: string) => {
        filterForm.setFieldValue('guid', guid);
        const valFromFields: ObjectLiteral = filterForm.getFieldsValue();
        viewModel.onFilterValueChange({ ...valFromFields, guid });
    };

    const onShowEvent = (memberId: number) => {
        navigate({
            pathname: `/events-manage/edit/${memberId}`
        });
    };

    const onShowStartupProject = (startupProjectId: number) => {
        navigate({
            pathname: `/startup-project-manage/edit/${startupProjectId}`
        });
    };

    const getRegionSelectorContent = () =>
        viewModel.regionDictionary.map((item) => {
            return {
                value: item.id,
                label: item.name
            };
        });
    const getResultTypeSelectorContent = () =>
        viewModel.resultTypeDictionary.map((item) => {
            return {
                value: item.id,
                label: item.title
            };
        });

    const filters = () => {
        const infoCount = viewModel.filterInfoCount(viewModel.currentFilter);
        const infoFake = Array(infoCount).fill(0);

        return (
            <div className="edit-content-view">
                <Form form={filterForm} initialValues={viewModel.currentFilter} layout="vertical" onValuesChange={onFilterValueChange}>
                    <Row gutter={[16, 16]}>
                        {infoCount > 0 && (
                            <>
                                {viewModel.isCurrentFilterLoading === true
                                    ? infoFake.map((_, index) => <Skeleton.Input key={`skeleton-${index}`} active={true} size="small" />)
                                    : viewModel.currentFilterInfo.map((infoItem) => (
                                          <Col key={infoItem.name} span={24}>
                                              <Tag
                                                  key={infoItem.type}
                                                  closable
                                                  onClose={() => {
                                                      viewModel.onRemoveFilterInfo(infoItem);
                                                  }}
                                                  color="purple"
                                              >
                                                  <>
                                                      <FilterOutlined />
                                                      <Typography.Text strong>
                                                          {infoItem.name}: {infoItem.value}
                                                      </Typography.Text>
                                                  </>
                                              </Tag>
                                          </Col>
                                      ))}
                            </>
                        )}
                        <Col span={6}>
                            <Form.Item label={t('members.filter.searchName.title')} name={'name'}>
                                <Input allowClear placeholder={t('common.search') || ''} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={t('members.filter.searchGuid.title')}>
                                <GUIDForm
                                    guid={filterForm.getFieldValue('guid')}
                                    onSearch={(guid?: string) => {
                                        onSetGuid(guid);
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name={'result_type_id'} label={t('events.content.result_type.title')}>
                                <Select
                                    options={getResultTypeSelectorContent()}
                                    showSearch
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    allowClear
                                    placeholder={t('common.sorting.not_selected')}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name={'region_id'} label={t('members.filter.region')}>
                                <Select
                                    options={getRegionSelectorContent()}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    allowClear
                                    placeholder={t('common.sorting.not_selected')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name={'institution_id'} label={t('members.filter.institution')}>
                                <Select
                                    showSearch
                                    allowClear
                                    notFoundContent={<Empty description={''} />}
                                    onSearch={(value: string) => viewModel.searchOrganizations(value)}
                                    loading={viewModel.isOrganizationsLoading}
                                    filterOption={false}
                                    placeholder={t('common.sorting.not_selected')}
                                >
                                    {viewModel.organizations.map((item) => (
                                        <Select.Option value={item.id} key={item.id}>
                                            {item.short_name ? `${item.short_name} - ${item.name}` : item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    };

    return (
        <div>
            {contextHolder}
            <Row gutter={12} align="middle" justify="space-between">
                <Col>
                    <Space align="center">
                        <Typography.Title>{t('members.title')}</Typography.Title>
                        <Typography.Title style={{ marginTop: 25 }} level={2} type="secondary">
                            {viewModel.membersTotal}
                        </Typography.Title>
                        <Tag style={{ color: 'black', marginTop: 7 }} color={'blue'}>
                            <Typography.Title style={{ margin: 0 }} level={2} type="secondary">
                                {t('members.uniq')}: {viewModel.membersUniq}
                            </Typography.Title>
                        </Tag>
                    </Space>
                </Col>
                <Col>
                    <Space>
                        <Tooltip title={!viewModel.isExportAvailable ? t('members.export.excel.notAvailable') : ''}>
                            <Button
                                disabled={!viewModel.isExportAvailable}
                                type="dashed"
                                icon={<FileExcelOutlined />}
                                onClick={viewModel.onExportExcel}
                            >
                                {t('members.export.excel.title')}
                            </Button>
                        </Tooltip>
                    </Space>
                </Col>
            </Row>
            <Divider style={{ marginTop: 0 }} />

            {filters()}
            <br />
            <MembersTable
                currentPage={viewModel.currentPage}
                loading={viewModel.isLoading}
                rows={viewModel.members}
                total={viewModel.membersTotal}
                pageSize={viewModel.pageSize}
                onChangePagination={viewModel.onChangePagination}
                onShow={(id: number) => viewModel.onShow(id)}
                onShowEvent={onShowEvent}
                onShowStartupProject={onShowStartupProject}
            />
            {viewModel.memberOnShow && (
                <ShowMember visible={!!viewModel.memberOnShow} member={viewModel.memberOnShow} onClose={() => viewModel.onCancelShow()} />
            )}
        </div>
    );
});

export default MembersView;
