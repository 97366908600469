import { OrganizationDictionaryData, RegionDictionaryData, ResultTypeDictionaryData, TimezoneDictionaryData } from '@models/dictionary-model';
import { ResultData } from '@models/result-model';
import { ObjectLiteral } from '@utils/object.utils';

export class EventData {
    id?: number | undefined;
    name: string;
    description: string;
    is_visible: boolean = true;
    announcing_date?: string | Date | undefined;
    registration_begin_date?: string | Date | undefined;
    registration_end_date?: string | Date | undefined;
    begin_date?: string | Date | undefined;
    end_date?: string | Date | undefined;
    address: string;
    terms: string;
    url: string;
    url_click_count: number;
    contact: EventDataContact[] = [];
    comment: string;
    event_format_id?: number | undefined = 2;

    result_type_id?: number | undefined;
    result_type?: ResultTypeDictionaryData | undefined;

    //TODO: Часовой пояс выбирать согласно времени браузера
    timezone_id?: number | undefined = 2;
    timezone?: TimezoneDictionaryData | undefined;

    region_id?: number | undefined;
    region?: RegionDictionaryData | undefined;

    members_count?: number | undefined;

    event_type_id?: number | undefined;
    organizer?: OrganizationDictionaryData | undefined;

    results?: ResultData[];

    created_at: string;
    updated_at: string;
}

export class EventDataContact {
    email?: string | undefined;
    fio: string;
    phone?: string | undefined;
}

export class GetEventsResponse {
    data: EventData[];
    total: number;
    count: number;
}

export class EventResponse {
    data: EventData;
}

export class EventAttachedFile {
    created_at: string;
    downloads: number;
    id: number;
    is_private: boolean;
    name: string;
    size: number;
    uuid: string;
    title?: string;
}

export class GetEventFilesResponse {
    data: EventAttachedFile[];
    count: number;
    total: number;
}

export class HistoryLoggerData {
    id: number;
    user: HistoryLoggerDataUser;
    obj_id: number;
    /// Тип действия: 'create' | 'update' | 'delete'
    action: string;
    created_at: string;

    /// Объект с предыдущим состоянием
    prev_data?: EventData;
    /// Объект с измененными полями
    changed_data?: ObjectLiteral;
}

export class HistoryLoggerDataUser {
    id: number;
    name: string;
}

export class GetEventHistoryItemsReponse {
    data: HistoryLoggerData[];
    total: number;
}

export class GetEventHistoryItemReponse {
    data: HistoryLoggerData;
}
