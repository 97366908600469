export const LocalStorageKeys = {
    sidebar: {
        collapsed: () => `putp:sidebar:collapsed`
    },
    links: {
        onNewTab: 'putp:links:onNewTab'
    },
    theme: {
        compact: 'putp:theme:compact'
    }
};
