export class StaticPage {
    id?: number | undefined;
    title: string = '';
    subtitle: string = '';
    description: string = '';
    alt_name: string = '';
    body: string = '';
    is_deleted: boolean = false;
    is_visible: boolean = false;
    keywords: string = '';
    tags_list: string[] = [];
    created_at: string;
    updated_at: string;
}

export class GetStaticPagesResponse {
    data: StaticPage[];
    count: number;
    total: number;
}

export class StaticPageResponse {
    data: StaticPage;
}

export class StaticPageFile {
    created_at: string;
    downloads: number;
    id: number;
    is_private: boolean;
    name: string;
    size: number;
    uuid: string;
}

export class GetStaticPageFilesResponse {
    data: StaticPageFile[];
    count: number;
    total: number;
}
