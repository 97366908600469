import { useEffect, useState } from 'react';
import { Badge, Button, Form, InputNumber, Space, Switch, Table, Tooltip, Typography } from 'antd';
import { ObjectLiteral } from '@utils/object.utils';
import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    QuestionCircleOutlined,
    SaveOutlined,
    StopOutlined,
    UnlockOutlined
} from '@ant-design/icons';
import { IndicatorAdditionalValue } from '@models/indicator-model';
import { useTranslation } from 'react-i18next';

interface TableProps {
    rows: IndicatorAdditionalValue[];
    currentPage: number;
    pageSize: number;
    total: number;
    loading: boolean;
    onChangePagination: (page: number, page_size: number) => void;
    onSave: (id: number, value: number, is_active: boolean) => Promise<boolean>;
    onDelete: (id: number) => Promise<boolean>;
}

export const ValuesTable = (props: TableProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const [data, setData] = useState<ObjectLiteral[]>([]);
    const [editingKey, setEditingKey] = useState('');
    const isEditing = (record: ObjectLiteral) => record.id === editingKey;

    useEffect(() => {
        setData(props.rows);
    }, [props.rows]);

    const onChangePage = (page: number, pageSize: number) => {
        props.onChangePagination(page, pageSize);
    };

    const edit = (record: ObjectLiteral) => {
        form.resetFields();
        form.setFieldsValue(record);
        setEditingKey(record.id);
    };

    const cancelEdit = () => {
        setEditingKey('');
    };

    const save = async (id: string) => {
        try {
            const row = (await form.validateFields()) as ObjectLiteral;

            const newData = [...data];
            const index = newData.findIndex((item) => id === item.id);

            if (index > -1) {
                const item = newData[index];
                const result = await props.onSave(item.id, row.add_value, row.is_active);
                if (!result) {
                    return;
                }

                newData.splice(index, 1, {
                    ...item,
                    ...row
                });
                setData(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const createColums = () => {
        let columns: ObjectLiteral[] = [];
        columns.push({
            title: t('indicator.content.indicator_additional_value.edit.month'),
            width: '15%',
            // dataIndex: 'month_name',
            editable: false,
            render: (_: any, record: IndicatorAdditionalValue) => {
                return (
                    <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                        {record.month_is_current && (
                            <Tooltip title={t('indicator.content.indicator_additional_value.edit.current_month')}>
                                <Badge status="success" />
                            </Tooltip>
                        )}
                        <Typography.Text>{record.month_name}</Typography.Text>
                    </div>
                );
            }
        });
        columns.push({
            title: t('indicator.content.indicator_additional_value.edit.year'),
            width: '10%',
            dataIndex: 'month_year',
            editable: false
        });
        columns.push({
            title: t('indicator.content.indicator_additional_value.edit.value'),
            dataIndex: 'add_value',
            editable: true
        });

        columns.push({
            title: (
                <Tooltip title={t('indicator.content.indicator_additional_value.edit.isActive.tooltip')}>
                    <Typography.Text>
                        {t('indicator.content.indicator_additional_value.edit.isActive.title')} <QuestionCircleOutlined />
                    </Typography.Text>
                </Tooltip>
            ),
            dataIndex: 'is_active',
            width: '20%',
            editable: true,
            render: (_: any, record: IndicatorAdditionalValue) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {record.is_active !== undefined && record.is_active === true ? (
                            <CheckOutlined style={{ color: 'green' }} />
                        ) : (
                            <CloseOutlined style={{ color: 'red' }} />
                        )}
                    </div>
                );
            }
        });

        columns.push({
            title: '',
            width: '18%',
            dataIndex: 'operations',
            render: (_: any, record: ObjectLiteral) => {
                const editable = isEditing(record);
                if (editingKey !== '' && editable) {
                    return (
                        <Space direction="horizontal">
                            <Tooltip title={t('common.save')}>
                                <Button onClick={() => save(record.id)}>
                                    <SaveOutlined />
                                </Button>
                            </Tooltip>
                            <Tooltip title={t('common.cancel')}>
                                <Button onClick={() => cancelEdit()}>
                                    <StopOutlined />
                                </Button>
                            </Tooltip>
                        </Space>
                    );
                } else {
                    return (
                        <Space direction="horizontal">
                            <Tooltip title={t('common.edit')}>
                                <Button onClick={() => edit(record)} disabled={editingKey !== ''}>
                                    <EditOutlined />
                                </Button>
                            </Tooltip>
                            <Tooltip title={t('common.delete')}>
                                <Button onClick={() => props.onDelete(record.id)}>
                                    <DeleteOutlined />
                                </Button>
                            </Tooltip>
                        </Space>
                    );
                }
            }
        });
        return columns;
    };

    const mergedColumns = createColums().map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: ObjectLiteral) => ({
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                onFinish: (id: string) => save(id)
            })
        };
    });

    return (
        <Form form={form} component={false}>
            <Table
                size="small"
                components={{
                    body: {
                        cell: EditableCell
                    }
                }}
                loading={props.loading}
                bordered
                dataSource={data}
                columns={mergedColumns}
                rowClassName="row"
                rowKey={'id'}
                pagination={{
                    current: props.currentPage,
                    pageSize: props.pageSize,
                    total: props.total,
                    position: ['bottomCenter'],
                    onChange: onChangePage
                }}
            />
        </Form>
    );
};

interface EditableCellProps {
    editing: boolean;
    dataIndex: string;
    title: any;
    record: ObjectLiteral;
    index: number;
    children: React.ReactNode;
    onFinish: (id: string) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({ editing, dataIndex, title, record, index, children, onFinish, ...restProps }) => {
    if (editing) {
        switch (dataIndex) {
            case 'is_active':
                return (
                    <td {...restProps}>
                        <Form.Item
                            name={dataIndex}
                            style={{ margin: 0, padding: 0, display: 'flex', justifyContent: 'center' }}
                            valuePropName="checked"
                        >
                            <Switch checkedChildren={<UnlockOutlined />} />
                        </Form.Item>
                    </td>
                );

            default:
                return (
                    <td {...restProps}>
                        <Form.Item
                            name={dataIndex}
                            style={{ margin: 0, padding: 0 }}
                            rules={[
                                {
                                    required: true
                                }
                            ]}
                        >
                            <InputNumber<number> style={{ width: '100%' }} min={0} onPressEnter={() => onFinish(record.id)} />
                        </Form.Item>
                    </td>
                );
        }
    } else {
        return <td {...restProps}>{children}</td>;
    }
};

export default ValuesTable;
