export class PopularizationResourceData {
    id?: number;
    title: string;
    desc: string;
    is_deleted: boolean;
    subtitle: string;
    date_published: string;
    url: string;
    clicks_count: number;
    created_at: string;
    updated_at: string;
}

export class PopularizationResourcesResponse {
    data: PopularizationResourceData[];
    total: number;
}

export class PopularizationResourceResponse {
    data: PopularizationResourceData;
}
