import { APIInstance } from '@utils/api';
import { deleteUndefined } from '@utils/object.utils';
import { GetOperatorErrors, IDObject } from './api-errors.contract';

export class ApiErrorsModel {
    public async fetchErrors(id_object: IDObject, limit?: number, offset?: number, solved_filter?: boolean): Promise<GetOperatorErrors> {
        try {
            const result = await APIInstance.get<GetOperatorErrors>('/cp-manager/airflow/api-errors', {
                params: deleteUndefined({ ...id_object, limit, offset, solved_filter })
            });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async setSolvedErrors(solvedIds: number[], id_object: IDObject, solve?: boolean) {
        try {
            await APIInstance.post('/cp-manager/airflow/api-errors/solve', { solved_ids: solvedIds, solve, ...id_object });
        } catch (error) {
            throw error;
        }
    }

    public async deleteErrors(id_object: IDObject) {
        try {
            await APIInstance.delete('/cp-manager/airflow/api-errors', { params: { ...id_object } });
        } catch (error) {
            throw error;
        }
    }
}
