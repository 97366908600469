import { APIInstance } from '@utils/api';
import { GetResultsResponse, ResultResponse } from './result.contract';
import { deleteUndefined } from '@utils/object.utils';
import { ResultsGetFilter } from '@interfaces/result.interface';

export class ResultModel {
    public async getResults(params: ResultsGetFilter): Promise<GetResultsResponse> {
        try {
            const q_result = await APIInstance.get<GetResultsResponse>('/cp-manager/result', {
                params: deleteUndefined({
                    search: params.search,
                    result_type_id: params.result_type_id,
                    limit: params.page_size,
                    offset: params.page_size && params.page ? params.page_size * (params.page - 1) : 0
                })
            });
            return q_result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getById(id: string | number): Promise<ResultResponse> {
        try {
            const q_result = await APIInstance.get<ResultResponse>(`/cp-manager/result/${id}`);
            return q_result.data;
        } catch (error) {
            throw error;
        }
    }
}
