import { APIUtilError } from '@utils/api';
import { makeAutoObservable, runInAction } from 'mobx';
import { EventAttachedFile, EventData, EventModel, HistoryLoggerData } from '@models/event-model';
import {
    DictionaryModel,
    EventFormatDictionaryData,
    EventTypeDictionaryData,
    OrganizationDictionaryData,
    RegionDictionaryData,
    ResultTypeDictionaryData,
    TimezoneDictionaryData
} from '@models/dictionary-model';
import { ResultData } from '@models/result-model';

export interface EventEditViewModelStartParams {
    t: any;
    eventId: string | undefined;
    showError: (title: string, description: string) => void;
    showSuccess: (title: string, description: string) => void;
}

export class EventEditViewModel {
    private dictionaryModel = new DictionaryModel();
    private eventModel = new EventModel();

    public isPublished = false;
    public isLoading: boolean = false;
    public resultTypeDictionary: ResultTypeDictionaryData[] = [];
    public timezoneDictionary: TimezoneDictionaryData[] = [];
    public eventFormatDictionary: EventFormatDictionaryData[] = [];
    public regionDictionary: RegionDictionaryData[] = [];
    public eventTypes: EventTypeDictionaryData[] = [];

    public isOrganizationsLoading: boolean = false;
    public organizations: OrganizationDictionaryData[] = [];

    public event: EventData = new EventData();
    public files: EventAttachedFile[] = [];

    public isHistoryLoading = false;
    public historyTotal: number = 0;
    public historyPageSize = 4;
    public historyItems: HistoryLoggerData[];
    public historyCurrentItem?: HistoryLoggerData | undefined;

    constructor(private props: EventEditViewModelStartParams) {
        this.isPublished = props.eventId !== undefined;
        makeAutoObservable(this);
        this.wakeUpSir();
    }

    private async wakeUpSir() {
        await this.fetchResultTypeDictionary();
        await this.fetchRegionDictionary();
        await this.fetchTimezoneDictionary();
        await this.fetchEventFormatDictionary();
        await this.fetchEventTypesDictionary();
        if (this.props.eventId && this.props.eventId.length > 0 && Number(this.props.eventId)) {
            await this.fetchEvent(this.props.eventId);
            await this.fetchFiles(Number(this.props.eventId));
            await this.fetchHistoryItems(this.props.eventId, 1, this.historyPageSize);
        }
    }

    private async fetchResultTypeDictionary() {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.dictionaryModel.getDictionary({ altname: 'result_type' });
            runInAction(() => {
                this.resultTypeDictionary = result.data as ResultTypeDictionaryData[];
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    private async fetchRegionDictionary() {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.dictionaryModel.getDictionary({ altname: 'region' });
            runInAction(() => {
                this.regionDictionary = result.data as RegionDictionaryData[];
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    private async fetchTimezoneDictionary() {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.dictionaryModel.getDictionary({ altname: 'timezone' });
            runInAction(() => {
                this.timezoneDictionary = result.data as TimezoneDictionaryData[];
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    private async fetchEventFormatDictionary() {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.dictionaryModel.getDictionary({ altname: 'event-format' });
            runInAction(() => {
                this.eventFormatDictionary = result.data as EventFormatDictionaryData[];
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    private async fetchEventTypesDictionary() {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.dictionaryModel.getDictionary({ altname: 'event-type' });
            runInAction(() => {
                this.eventTypes = result.data as EventTypeDictionaryData[];
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public async searchOrganizations(query: string) {
        runInAction(() => {
            this.isOrganizationsLoading = true;
        });
        try {
            const result = await this.dictionaryModel.getDictionary({ altname: 'institution', search: query });
            runInAction(() => {
                this.organizations = result.data as OrganizationDictionaryData[];
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isOrganizationsLoading = false;
            });
        }
    }

    private async fetchEvent(eventId: string) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.eventModel.getEventById(eventId);
            runInAction(() => {
                this.event = result.data;
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    private async fetchFiles(eventId: number | undefined) {
        if (!eventId) return;
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const filesResponse = await this.eventModel.getFiles(eventId);
            runInAction(() => {
                this.files = filesResponse.data;
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public onSave = async (event: any) => {
        if (!this.props.eventId) {
            await this.create(event);
        } else {
            await this.update(event);
        }
    };

    private create = async (event: any) => {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const created = await this.eventModel.create(event);
            runInAction(() => {
                this.event = created.data;
            });
            this.props.showSuccess(this.props.t('common.saved'), '');
        } catch (error) {
            this.props.showError(this.props.t('common.error.save'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };

    private update = async (event: any) => {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            await this.eventModel.update(event);
            await this.fetchHistoryItems(this.props.eventId!, 1, this.historyPageSize);
            this.props.showSuccess(this.props.t('common.saved'), '');
        } catch (error) {
            this.props.showError(this.props.t('common.error.save'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };

    public onSetEventResults = (results: [ResultData] | undefined) => {
        runInAction(() => {
            this.event.results = results;
        });
    };

    public onSetEventResultTypeId = (id: number | undefined) => {
        runInAction(() => {
            this.event.result_type_id = id;
        });
    };

    public getUploaderURL() {
        if (!this.event.id) return '';
        return this.eventModel.uploadURL(this.event.id);
    }

    public getUploaderHeaders() {
        return this.eventModel.uploadHeaders;
    }

    public getDownloadUrl(uuid: string): string | undefined {
        if (!this.event.id) return undefined;
        return this.eventModel.getDownloarFileUrl(this.event.id, uuid);
    }

    public async onEditFileTitle(uuid: string, title: string) {
        if (!this.event.id) return false;

        try {
            await this.eventModel.saveFileTitle(this.event.id, uuid, title);
            return true;
        } catch (error) {
            this.props.showError(this.props.t('common.error.save'), (error as APIUtilError).localizedDescription);
            return false;
        }
    }

    public async deleteFile(uuid: string): Promise<boolean> {
        if (!this.event.id) return false;

        try {
            await this.eventModel.deleteFile(this.event.id, uuid);
            return true;
        } catch (error) {
            this.props.showError(this.props.t('common.error.save'), (error as APIUtilError).localizedDescription);
            return false;
        }
    }

    public getTimezoneById(id: number): TimezoneDictionaryData | undefined {
        const filtered = this.timezoneDictionary.filter((item) => item.id === id);
        if (filtered.length > 0) return filtered[0];
        return undefined;
    }

    public async fetchNextHistoryItemsPage() {
        if (!this.props.eventId) return;
        await this.fetchHistoryItems(this.props.eventId, 1, this.historyPageSize + 1);
    }

    private async fetchHistoryItems(eventId: string | number, page: number, pageSize: number) {
        runInAction(() => {
            this.isHistoryLoading = true;
        });
        try {
            const result = await this.eventModel.getHistoryItems(eventId, page, pageSize);
            runInAction(() => {
                this.historyItems = result.data;
                this.historyTotal = result.total;
                this.historyPageSize = pageSize;
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isHistoryLoading = false;
            });
        }
    }

    public async onSelectHistoryItem(historyId: number) {
        if (!this.props.eventId) return;
        await this.fetchHistoryItem(this.props.eventId, historyId);
    }

    private async fetchHistoryItem(eventId: string | number, historyId: number) {
        runInAction(() => {
            this.isHistoryLoading = true;
        });
        try {
            const result = await this.eventModel.getHistoryItem(eventId, historyId);
            runInAction(() => {
                this.historyCurrentItem = result.data;
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isHistoryLoading = false;
            });
        }
    }
}
