import { UserOutlined } from '@ant-design/icons';
import { HistoryLoggerData } from '@models/event-model';
import { formatDate } from '@utils/date.utils';
import { Badge, Card, Col, Divider, List, Row, Skeleton, Spin, Table, Typography, Tag } from 'antd';
import { instanceToPlain } from 'class-transformer';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

export interface HistoryViewProps {
    isLoading: boolean;
    items: HistoryLoggerData[];
    itemsTotal: number;
    currentItem?: HistoryLoggerData;
    loadNextItems: () => void;
    loadHistoryItem: (historyId: number) => void;
}

export const HistoryView = (props: HistoryViewProps) => {
    const { t } = useTranslation();

    const [selectedId, setSelectedId] = useState(0);

    interface TableData {
        index: number;
        key: string;
        old_value: any;
        new_value: any;
        changed: boolean;
    }

    const loadMoreData = () => {
        props.loadNextItems();
    };

    const onSelectHistoryItem = async (id: number) => {
        if (props.currentItem?.id === id) return; // при повторном клике на активную карточку
        setSelectedId(id);
        props.loadHistoryItem(id);
    };

    const TableColumns = [
        {
            title: 'key',
            width: '20%',
            render: (_: any, record: TableData) => {
                return <Typography.Text type={record.changed ? undefined : 'secondary'}>{record.key}</Typography.Text>;
            }
        },
        {
            title: t('components.history-logger.old_value'),
            width: '40%',
            render: (_: any, record: TableData) => {
                return (
                    <Typography.Text keyboard={record.changed} type={record.changed ? undefined : 'secondary'}>
                        {record.old_value === 'true' || record.old_value === 'false' ? (
                            <span>
                                {record.old_value === 'true' ? (
                                    <Badge status="processing" color="primary" text={t('common.yes')} />
                                ) : (
                                    <Badge status="error" text={t('common.no')} />
                                )}
                            </span>
                        ) : (
                            <span>{record.old_value}</span>
                        )}
                    </Typography.Text>
                );
            }
        },
        {
            title: t('components.history-logger.new_value'),
            width: '40%',
            render: (_: any, record: TableData) => {
                return (
                    <Typography.Text keyboard={record.changed} type={record.changed ? 'success' : 'secondary'}>
                        {record.new_value === 'true' || record.new_value === 'false' ? (
                            <span>
                                {record.new_value === 'true' ? (
                                    <Badge status="processing" color="primary" text={t('common.yes')} />
                                ) : (
                                    <Badge status="error" text={t('common.no')} />
                                )}
                            </span>
                        ) : (
                            <span>{record.new_value}</span>
                        )}
                    </Typography.Text>
                );
            }
        }
    ];

    const translateKey = (key: string) => {
        switch (key) {
            case 'name':
                return t('events.content.name.title');
            case 'description':
                return t('events.content.description.title');
            case 'is_visible':
                return t('events.content.is_visible.title');
            case 'address':
                return t('events.content.address.title');
            case 'terms':
                return t('events.content.terms.title');
            case 'url':
                return t('events.content.url.title');
            case 'contact':
                return t('events.content.contacts.title');
            case 'comment':
                return t('events.content.comment.title');
            default:
                return key;
        }
    };

    const historyFields = (historyItem: HistoryLoggerData) => {
        const old_data = instanceToPlain(historyItem.prev_data || {});
        const new_data = instanceToPlain(historyItem.changed_data || {});
        const combined_data = { ...old_data, ...new_data };

        const f = Object.keys(combined_data).map((key, index) => {
            const q: TableData = {
                index: index,
                key: translateKey(key),
                old_value: JSON.stringify(old_data[key] != null ? old_data[key] : ''),
                new_value: JSON.stringify(new_data && new_data[key] != null ? new_data[key] : old_data[key]),
                changed: new_data[key] != null ? new_data[key] !== old_data[key] : false
            };
            return q;
        });
        return f;
    };

    const actionDict: any = {
        create: { title: 'Создание', color: '#b7eb8f' },
        edit: { title: 'Редактирование', color: '#ffd591' },
        delete: { title: 'Удаление', color: '#ffa39e' }
    };

    return (
        <div>
            <Row gutter={24}>
                <Col span={6}>
                    <Spin spinning={props.isLoading}>
                        <div className="edit-content-view">
                            <div>
                                <div
                                    id="scrollableDiv"
                                    style={{
                                        height: 500,
                                        overflow: 'auto',
                                        padding: '0 16px'
                                    }}
                                    className="custom_scroll"
                                >
                                    <InfiniteScroll
                                        dataLength={(props.items || []).length}
                                        next={loadMoreData}
                                        hasMore={(props.items || []).length < props.itemsTotal}
                                        loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                                        endMessage={<Divider plain>{t('components.history-logger.list_end')} 🤐</Divider>}
                                        scrollableTarget="scrollableDiv"
                                        className="custom_scroll"
                                    >
                                        <List
                                            style={{
                                                padding: 10
                                            }}
                                            dataSource={props.items}
                                            renderItem={(item) => (
                                                <List.Item>
                                                    <Card
                                                        style={{ width: '100%' }}
                                                        size="small"
                                                        hoverable={item.id === selectedId ? false : true}
                                                        type={item.id === selectedId ? 'inner' : undefined}
                                                        title={formatDate(item.created_at, t('common.date.pattern.date_time'))}
                                                        onClick={() => onSelectHistoryItem(item.id)}
                                                        extra={item.id === selectedId ? <Badge color="purple" /> : undefined}
                                                    >
                                                        <Card.Meta
                                                            avatar={<UserOutlined />}
                                                            title={item.user.name}
                                                            description={
                                                                <Tag color={actionDict[item.action].color}>{actionDict[item.action].title}</Tag>
                                                            }
                                                        />
                                                    </Card>
                                                </List.Item>
                                            )}
                                        />
                                    </InfiniteScroll>
                                </div>
                            </div>
                        </div>
                    </Spin>
                </Col>
                <Col span={18}>
                    {props.currentItem !== undefined && (
                        <Spin spinning={props.isLoading}>
                            <div className="edit-content-view">
                                <Table size="small" dataSource={historyFields(props.currentItem)} columns={TableColumns} pagination={false} />
                            </div>
                        </Spin>
                    )}
                </Col>
            </Row>
        </div>
    );
};
