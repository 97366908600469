import { CloudDownloadOutlined, DeleteOutlined, EditOutlined, InboxOutlined, PaperClipOutlined, SaveOutlined, StopOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, Progress, Row, Space, Tooltip, Typography, Upload, UploadFile } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '@utils/date.utils';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { CatalogFile } from './types';

export interface HttpRequestHeader {
    [key: string]: string;
}

type methods = 'POST' | 'PUT' | 'PATCH' | 'post' | 'put' | 'patch';

interface Props {
    name: string;
    uploadURL: string;
    getHeaders: () => Promise<any>;
    onRemove?: (uid: string) => Promise<boolean> | undefined;
    downloadLink?: (uid: string) => string | undefined;
    onEditTitle?: (uid: string, title: string) => Promise<boolean> | undefined;
    defaultFileList?: CatalogFile[] | undefined;
    onChangeFileList?: (list: UploadFile[] | CatalogFile[] | undefined) => void;
    /// При загрузке не добавлять файл к defaultFileList, а заменить его (если нужен только 1 файл)
    changeOnUpload?: boolean;
    method?: methods;
    disabled?: boolean;
}

const FilesCatalogView: React.FC<Props> = ({
    name,
    uploadURL,
    getHeaders,
    onRemove,
    downloadLink,
    onEditTitle,
    defaultFileList,
    onChangeFileList,
    changeOnUpload,
    method,
    disabled
}) => {
    const { t } = useTranslation();
    const [headers, setHeaders] = useState<HttpRequestHeader>();
    const [fileList, setFileList] = useState<UploadFile[] | CatalogFile[] | undefined>(defaultFileList);

    const updateFileList = (list: UploadFile[] | CatalogFile[] | undefined) => {
        setFileList(list);
        onChangeFileList && onChangeFileList(list);
    };

    const onDownloadHandler = (file: CatalogFile | UploadFile, filename: string) => {
        if (!downloadLink) return;

        switch (file.status) {
            case undefined: {
                // Скачиваем ранее загруженный файл
                const url = downloadLink(file.uid);
                if (!url) return;
                downloadFile(url, filename);
                break;
            }
            case 'done': {
                const uid = file.response?.data?.uuid;
                if (!uid) return;
                const url = downloadLink(uid);
                if (!url) return;
                downloadFile(url, filename);
                break;
            }
            default:
                break;
        }
    };

    const downloadFile = async (url: string, fileName: string) => {
        const headers = await getHeaders();
        axios({
            url: url,
            method: 'GET',
            headers: headers,
            responseType: 'blob'
        }).then((response) => {
            saveAs(response.data, fileName);
        });
    };

    const onRemoveHandler = async (file: CatalogFile | UploadFile) => {
        if (!onRemove) return;

        switch (file.status) {
            case undefined: //Удаляем ранее загруженный файл
                let result = await onRemove(file.uid);
                if (result) {
                    updateFileList([...(fileList || [])].filter((item) => item.uid !== file.uid));
                }
                break;
            case 'error': //Удаляем херовые
                updateFileList([...(fileList || [])].filter((item) => item.uid !== file.uid));
                break;
            case 'done':
                if (file.response?.data?.uuid) {
                    let result = await onRemove(file.response?.data?.uuid);
                    if (result) {
                        updateFileList([...(fileList || [])].filter((item) => item.uid !== file.uid));
                    }
                }
                break;
            default: // В других случаях удаляем просто из списка
                updateFileList([...(fileList || [])].filter((item) => item.uid !== file.uid));
                break;
        }
    };

    const onChange = (info: UploadChangeParam<UploadFile>) => {
        switch (info.file.status) {
            case 'uploading':
                updateFileList(info.fileList);
                break;
            case 'done':
                let fileList: UploadFile[] = info.fileList;
                if (changeOnUpload && fileList.length > 1) fileList.shift();
                updateFileList(fileList);

                break;
            case 'error':
                updateFileList(info.fileList);
                break;
            default:
                break;
        }
    };

    const beforeUpload = async (file: RcFile) => {
        const headers = await getHeaders();
        setHeaders(headers);
        return true;
    };

    return (
        <div>
            <Upload.Dragger
                disabled={disabled}
                name={name}
                beforeUpload={beforeUpload}
                action={uploadURL}
                method={method}
                headers={headers}
                onChange={onChange}
                itemRender={(originNode, file) => (
                    <FileCardView
                        file={file}
                        onDownload={downloadLink ? onDownloadHandler : undefined}
                        onRemove={onRemove ? onRemoveHandler : undefined}
                        onEditTitle={onEditTitle}
                    />
                )}
                fileList={fileList}
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">{t('components.files_catalog.upload.title')}</p>
                <p className="ant-upload-hint">{t('components.files_catalog.upload.description')}</p>
            </Upload.Dragger>

            <br />
        </div>
    );
};

interface FileCardProps {
    file: CatalogFile | UploadFile;
    onDownload?: (file: CatalogFile | UploadFile, filename: string) => void | undefined;
    onRemove?: (file: CatalogFile | UploadFile) => void | boolean | Promise<void | boolean> | undefined;
    onEditTitle?: (uid: string, title: string) => Promise<boolean> | undefined;
}

const FileCardView: React.FC<FileCardProps> = ({ file, onDownload, onRemove, onEditTitle }) => {
    const { t } = useTranslation();
    const getTitle = () => {
        return (file as any)['title'] ? (file as any)['title'] : file.originFileObj ? file.originFileObj.name : file.name;
    };
    const [title, setTitle] = useState(getTitle());

    const fileSize = () => {
        return ((file.size || 0) / 1000000).toFixed(3);
    };

    return (
        <Card style={{ marginTop: 20 }}>
            <Row gutter={22} align="middle" justify="space-between">
                <Col span={14}>
                    <Row gutter={12} align="middle">
                        <Col span={1}>
                            {file.percent !== undefined ? (
                                <Progress type="circle" size={20} percent={file.percent} />
                            ) : (
                                <PaperClipOutlined style={{ fontSize: 20 }} />
                            )}
                        </Col>
                        <Col span={23}>
                            <FileCardTitleView file={file} title={title} setTitle={setTitle} onEditTitle={onEditTitle} />
                        </Col>
                    </Row>
                </Col>
                <Col span={10}>
                    <Row align="middle" justify="end" gutter={20}>
                        {file.status !== 'error' && (
                            <Col>
                                <Typography.Text>{`${t('components.files_catalog.uploaded_at')}: ${formatDate(
                                    file.lastModifiedDate,
                                    t('common.date.pattern.date_time')
                                )}`}</Typography.Text>
                            </Col>
                        )}

                        <Col>
                            <Typography.Text>{`${t('components.files_catalog.size')}: ${fileSize()} Mb`}</Typography.Text>
                        </Col>
                        <Col>
                            <Space direction="horizontal" align="end" size={22}>
                                {(file.status === undefined || file.status === 'done') && onDownload && (
                                    <Tooltip title={t('components.files_catalog.download')}>
                                        <CloudDownloadOutlined key="download" onClick={() => onDownload(file, title)} />
                                    </Tooltip>
                                )}
                                {onRemove && (
                                    <Tooltip title={t('common.delete')}>
                                        <DeleteOutlined key="delete" onClick={() => onRemove(file)} />
                                    </Tooltip>
                                )}
                            </Space>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    );
};

interface FileCardTitleProps {
    file: CatalogFile | UploadFile;
    title: string;
    setTitle: (title: string) => void;
    onEditTitle?: (uid: string, title: string) => Promise<boolean> | undefined;
}

const FileCardTitleView: React.FC<FileCardTitleProps> = ({ file, title, setTitle, onEditTitle }) => {
    const { t } = useTranslation();
    const [onEdit, setOnEdit] = useState(false);

    const [editedTitle, setEditedTitle] = useState(title);
    const [editedExtension, setEditedExtension] = useState<string | undefined>();

    const onClickEdit = () => {
        setOnEdit(true);
        const extensionSource = file.originFileObj ? file.originFileObj.name : file.name;
        const extension = extensionSource.split('.').pop();
        if (extension && extension !== extensionSource && extension !== '') {
            setEditedExtension(extension);

            let filename = title;
            if (`.${extension}` === filename.substring(filename.length - (extension.length + 1))) {
                filename = filename.substring(0, filename.length - (extension.length + 1));
            }
            setEditedTitle(filename);
        }
    };
    const onClickSave = async () => {
        if (!onEditTitle) return;
        let filename = editedTitle.length > 0 ? editedTitle : title;
        filename = editedTitle.length > 0 && editedExtension && editedExtension?.length > 0 ? `${filename}.${editedExtension}` : filename;

        switch (file.status) {
            case undefined: //Изменяем ранее загруженный файл
                let result = await onEditTitle(file.uid, filename);
                if (result) {
                    setTitle(filename);
                }
                break;
            case 'done':
                if (file.response?.data?.uuid) {
                    let result = await onEditTitle(file.response.data.uuid, filename);
                    if (result) {
                        setTitle(filename);
                    }
                }
                break;
            default:
                break;
        }
        setOnEdit(false);
    };
    const onClickCancelEdit = () => {
        setOnEdit(false);
    };

    if (onEdit) {
        return (
            <Row align="middle" gutter={20}>
                <Col span={19}>
                    <Input
                        style={{ width: '100%' }}
                        value={editedTitle}
                        onChange={(event) => setEditedTitle(event.currentTarget.value)}
                        addonAfter={editedExtension}
                    />
                </Col>
                <Col>
                    <Space>
                        <Tooltip title={t('common.save')}>
                            <Button onClick={onClickSave}>
                                <SaveOutlined />
                            </Button>
                        </Tooltip>
                        <Tooltip title={t('common.cancel')}>
                            <Button onClick={onClickCancelEdit}>
                                <StopOutlined />
                            </Button>
                        </Tooltip>
                    </Space>
                </Col>
            </Row>
        );
    } else {
        return (
            <Row align="middle" gutter={6}>
                <Col>
                    <Typography.Text strong style={{ color: file.status === 'error' ? 'red' : 'black' }}>
                        {title}
                    </Typography.Text>
                </Col>
                <Col>
                    {onEditTitle && file.status !== 'error' && file.status !== 'uploading' && file.status !== 'removed' && (
                        <Col>
                            <Tooltip title={t('components.files_catalog.edit_title')}>
                                <Button onClick={onClickEdit}>
                                    <EditOutlined />
                                </Button>
                            </Tooltip>
                        </Col>
                    )}
                </Col>
            </Row>
        );
    }
};

export default FilesCatalogView;
