import { AirflowModel, AirflowProcessData } from '@models/airflow-model';
import { APIUtilError } from '@utils/api';
import { makeAutoObservable, runInAction } from 'mobx';

export interface AirflowViewModelModelStartParams {
    t: any;
    showError: (title: string, description: string) => void;
}

export class AirflowViewModel {
    private airflowModel = new AirflowModel();
    public isLoading: boolean = false;

    public processesTotal: number = 0;
    public processes: AirflowProcessData[] = [];

    constructor(private startParams: AirflowViewModelModelStartParams) {
        makeAutoObservable(this);
        this.fetchProcesses();
    }

    private async fetchProcesses() {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.airflowModel.getProcesses();
            runInAction(() => {
                this.processes = result.data;
                this.processesTotal = result.total;
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }
}
