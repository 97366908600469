import { useState } from 'react';
import { Table, Form, Tooltip, Button } from 'antd';
import { useEffect } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Operator } from '@models/operator-model';

interface TableProps {
    rows: Operator[];
    total: number;
    loading: boolean;
    onEdit: (id: number) => void;
}

export const EditableTable = (props: TableProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [data, setData] = useState<Operator[]>([]);

    useEffect(() => {
        setData(props.rows);
    }, [props]);

    const columns = [
        {
            title: t('operators.content.id.api'),
            dataIndex: 'api_id'
        },
        {
            title: t('operators.content.api_name'),
            dataIndex: 'api_name'
        },
        {
            title: t('operators.content.id.operator'),
            width: '8%',
            dataIndex: 'id'
        },
        {
            title: t('operators.content.name.title'),
            dataIndex: 'name',
            // render: (_: any, record: Operator) => {
            //     return (
            //         <div style={{ display: 'flex', alignItems: 'center' }}>
            //             {record.logo_url !== 'https://ceph.miccedu.ru/putp/' && ( // обработка отсутствия лого ПУ - переделать на id, потому что адреса s3 разные
            //                 <img src={record.logo_url} alt={`${t('operators.content.logo')}`} height={32} style={{ marginRight: '10px' }} />
            //             )}
            //             <div>{record.name}</div>
            //         </div>
            //     );
            // }
        },
        {
            title: t('operators.content.site.title'),
            dataIndex: 'site',
            render: (_: any, record: Operator) => {
                return (
                    <a href={record.site} target="_blank" rel="noreferrer">
                        {record.site}
                    </a>
                );
            }
        },
        {
            title: '',
            width: '5%',
            dataIndex: 'operations',
            render: (_: any, record: Operator) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Tooltip title={t('common.edit')}>
                            <Button onClick={() => props.onEdit(record.id)}>
                                <EditOutlined />
                            </Button>
                        </Tooltip>
                    </div>
                );
            }
        },
    ];

    return (
        <Form form={form} component={false}>
            <Table
                size="small"
                loading={props.loading}
                bordered
                dataSource={data}
                columns={columns}
                rowClassName="row"
                rowKey={'api_id'}
                pagination={false}
            />
        </Form>
    );
};
