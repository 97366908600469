import { RootModel } from '@models/root-model';
import axios, { AxiosError } from 'axios';
import { APIUtilError } from './api.error';

const baseURL = process.env.REACT_APP_BACKEND_HOST || '';

export const APICleanInstance = axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
});
export const APIInstance = axios.create({ baseURL });

const ExtractLocalizedDescription = (error: AxiosError): Promise<APIUtilError> => {
    const backendMsg =
        (error.response?.data as any).detail[0]?.msg || (error.response?.data as any).detail?.msg || (error.response?.data as any).detail;
    const apiError = error as AxiosError as APIUtilError;
    apiError.localizedDescription = backendMsg || apiError.message;
    return Promise.reject(apiError);
};

APIInstance.interceptors.request.use(
    (config) => {
        config.headers['Authorization'] = RootModel?.authModel ? `Bearer ${RootModel.authModel.getAccessToken()}` : '';
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

APIInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry && RootModel.authModel) {
            originalRequest._retry = true;
            await RootModel.authModel.updateToken();
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + RootModel.authModel.getAccessToken();
            return APIInstance(originalRequest);
        }
        return ExtractLocalizedDescription(error);
    }
);

APICleanInstance.interceptors.response.use(undefined, (error) => {
    return ExtractLocalizedDescription(error);
});

export const BaseURL = baseURL;
