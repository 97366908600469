import { Button, Input, notification } from 'antd';
import { AuthViewModel } from './auth.view.model';
import { useTranslation } from 'react-i18next';
import { useViewModel } from '@models/model';
import { ReactComponent as PutpLogo } from '@assets/images/logo.svg';
import { observer } from 'mobx-react-lite';

const Login: React.FC = observer(() => {
    const { t } = useTranslation();

    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    const viewModel = useViewModel(
        () =>
            new AuthViewModel({
                t,
                showError: showErrorNotification
            })
    );

    return (
        <div className="login__wrapper">
            {contextHolder}
            <div className="login__container">
                <div className="login__box">
                    <div className="login__card">
                        <h2>Авторизация</h2>
                        <div className="login-fields">
                            <div className="login-row">
                                <div className="login-title">Электронный адрес:</div>
                                <Input
                                    size="large"
                                    className="login-field"
                                    placeholder={t('auth.email-field.placeholder') || ''}
                                    pattern="/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/"
                                    onPressEnter={() => viewModel.onClickLogin()}
                                    value={viewModel.username}
                                    onChange={(event) => viewModel.onChangeUsername(event.currentTarget.value)}
                                />
                            </div>
                            <div className="login-row">
                                <div className="login-title">Пароль:</div>
                                <Input.Password
                                    size="large"
                                    className="login-field"
                                    placeholder={t('auth.password-field.placeholder') || ''}
                                    onPressEnter={() => viewModel.onClickLogin()}
                                    value={viewModel.password}
                                    onChange={(event) => viewModel.onChangePassword(event.currentTarget.value)}
                                />
                            </div>

                            {/* <div className="login-row">
                                <Checkbox>Запомнить меня</Checkbox>
                            </div> */}
                        </div>
                        <div className="login__btn-row">
                            <Button
                                loading={viewModel.isLoading}
                                disabled={!viewModel.isLoginEnabled}
                                className="login__btn"
                                onClick={() => viewModel.onClickLogin()}
                                size="large"
                                block
                            >
                                {t('auth.signin-button.title')}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="login__img-wrapper">
                    <div className="login__img-box">
                        <div className="login__img-boxinner">
                            <PutpLogo />
                            <div className="platform__name">ПЛАТФОРМА УНИВЕРСИТЕТСКОГО ТЕХНОЛОГИЧЕСКОГО ПРЕДПРИНИМАТЕЛЬСТВА</div>
                            <a href="https://putp.ru" target="_blank" rel="noreferrer">
                                К платформе
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Login;
