import { EmailTemplateFilterParamName, EmailTemplateGetFilter } from '@interfaces/email-template.interface';
import { EmailTemplateData, EmailTemplateModel } from '@models/email-template-model';
import { APIUtilError } from '@utils/api';
import { ObjectLiteral, deleteUndefined } from '@utils/object.utils';
import { makeAutoObservable, runInAction } from 'mobx';

export interface EmailTemplateViewModelStartParams {
    t: any;
    searchParams: URLSearchParams;
    setSearchParams: (params: any) => void;
    showError: (title: string, description: string) => void;
    showSuccess: (title: string, description: string) => void;
}

export class EmailTemplateViewModel {
    private emailTemplateModel = new EmailTemplateModel();
    public isLoading: boolean = false;

    public templates: EmailTemplateData[] = [];
    public templatesTotal: number = 0;

    public currentPage: number = 1;
    public availablePageSizes = [10, 20, 50, 100];
    public pageSize: number = this.availablePageSizes[1];
    public currentFilter: EmailTemplateGetFilter;

    constructor(private startParams: EmailTemplateViewModelStartParams) {
        const incomingFilter = this.getFilterFromSearchParams(startParams.searchParams);
        this.currentFilter = incomingFilter;
        this.currentPage = incomingFilter.page || 0;
        this.pageSize = incomingFilter.page_size || this.availablePageSizes[1];
        makeAutoObservable(this);
        this.wakeUpSir();
    }

    private async wakeUpSir() {
        await this.fetchTemplates(this.currentFilter);
    }

    private getFilterFromSearchParams = (searchParams: URLSearchParams) => {
        let filter: EmailTemplateGetFilter = { page: 1, page_size: this.pageSize };
        const keys: EmailTemplateFilterParamName[] = ['page', 'page_size'];

        for (const key of keys) {
            const value = searchParams.get(key);
            if (!value) continue;
            switch (key) {
                case 'page':
                    try {
                        const parsedPage = parseInt(value);
                        filter[key] = parsedPage;
                    } catch {
                        filter[key] = 1;
                    }
                    break;
                case 'page_size':
                    try {
                        const parsedSize = parseInt(value);
                        if (!this.availablePageSizes.includes(parsedSize)) throw new Error();
                        filter.page_size = parsedSize;
                    } catch {
                        filter.page_size = undefined;
                    }
                    break;

                default:
                    break;
            }
        }
        return filter;
    };

    private extractSearchParamsFromFilter = (filter: ObjectLiteral) => {
        let searchParams: ObjectLiteral = {};
        for (const key in filter) {
            switch (key) {
                default:
                    searchParams[key] = filter[key] ? filter[key] : undefined;
            }
        }
        return deleteUndefined(searchParams);
    };

    private async fetchTemplates(params: EmailTemplateGetFilter) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.emailTemplateModel.getTemplates(params);
            runInAction(() => {
                this.templates = result.data;
                this.templatesTotal = result.total;
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public onChangePagination = async (page: number, pageSize: number) => {
        if (pageSize !== this.pageSize) {
            runInAction(() => {
                this.pageSize = pageSize;
            });
        }
        const filter: EmailTemplateGetFilter = { ...this.currentFilter, page: page, page_size: pageSize };
        runInAction(() => {
            this.currentPage = page;
            this.currentFilter = filter;
        });

        const currentFiltersSearchParams = this.extractSearchParamsFromFilter(filter);
        this.startParams.setSearchParams(currentFiltersSearchParams);

        this.fetchTemplates(filter);
    };
}
