import { ReportApiData, ReportApiModel, ReportApiSchema, ReportRequest } from '@models/report-api';
import { APIUtilError } from '@utils/api';
import { makeAutoObservable, runInAction } from 'mobx';

export interface ReportApiEditViewModelStartParams {
    t: any;
    operatorAPIId?: string | undefined;
    showError: (title: string, description: string) => void;
    onFinish: () => void;
}

export class ReportApiEditViewModel {
    private reportModel = new ReportApiModel();
    public isLoading: boolean = false;
    public report: ReportApiData = new ReportApiData();
    public reportSchema: ReportApiSchema = new ReportApiSchema();

    public reportRequests: ReportRequest[] = [];
    public reportRequestsTotal: number = 0;
    public reportRequestsLimit: number = 10;
    public reportRequestsOffset: number = 0;
    public reportRequestsCurPage: number = 1;

    public isLoadingRequest: boolean = false;
    public reportRequestData: ReportRequest = new ReportRequest();
    public reqDataFisrtkey: string | undefined = '';

    public availablePageSizes = [10, 20, 50, 100];

    constructor(private startParams: ReportApiEditViewModelStartParams) {
        makeAutoObservable(this);
        this.wakeUpSir();
    }

    private async wakeUpSir() {
        if (this.startParams.operatorAPIId && this.startParams.operatorAPIId.length > 0 && Number(this.startParams.operatorAPIId)) {
            await this.fetchReport(this.startParams.operatorAPIId);
            await this.fetchReportSchema(this.startParams.operatorAPIId);
            await this.fetchReportRequests(this.startParams.operatorAPIId);
        }
    }

    private async fetchReport(operatorAPIId: string) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.reportModel.getReportById(parseInt(operatorAPIId));
            runInAction(() => {
                this.report = result.data;
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    private async fetchReportSchema(operatorAPIId: string) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.reportModel.getReportSchema(parseInt(operatorAPIId));
            runInAction(() => {
                this.reportSchema = result.data;
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    private async fetchReportRequests(operatorAPIId: string) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.reportModel.getReportRequests(parseInt(operatorAPIId), {
                limit: this.reportRequestsLimit,
                offset: this.reportRequestsOffset
            });
            runInAction(() => {
                this.reportRequests = result.data;
                this.reportRequestsTotal = result.total;
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public async onChangeRequestsPagination(page: number, pageSize: number) {
        if (pageSize !== this.reportRequestsLimit) {
            runInAction(() => {
                this.reportRequestsLimit = pageSize;
            });
        }
        runInAction(() => {
            this.reportRequestsOffset = (page - 1) * this.reportRequestsLimit;
            this.reportRequestsCurPage = page;
        });
        if (this.startParams.operatorAPIId) await this.fetchReportRequests(this.startParams.operatorAPIId);
    }

    public async getReportRequestsData(requestId: number) {
        runInAction(() => {
            this.isLoadingRequest = true;
        });
        try {
            const result = await this.reportModel.getReportRequestsData(this.report.id, requestId);
            runInAction(() => {
                this.reportRequestData = result;
                this.reqDataFisrtkey = Object.keys(result.data).find((_key: string) => result.data[_key].length);
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoadingRequest = false;
            });
        }
    }

    public async revokeRequest(requestId: number, revoke: boolean) {
        runInAction(() => {
            this.isLoadingRequest = true;
        });
        try {
            await this.reportModel.revokeRequest(this.report.id, requestId, revoke);
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            if (this.startParams.operatorAPIId) await this.fetchReportRequests(this.startParams.operatorAPIId);
            runInAction(() => {
                this.isLoadingRequest = false;
            });
        }
    }
}
