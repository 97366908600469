import React from 'react';
import { Badge, Button, Divider, Form, Input, Modal, Select, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import '@assets/css/App.css';
import 'katex/dist/katex.min.css';
import { IndicatorFormulaPart, indicatorFormulaPartValues } from '@models/indicator-model';
import { DashOutlined, DeleteOutlined, ExclamationCircleOutlined, SlidersOutlined } from '@ant-design/icons';
import { plainToInstance } from 'class-transformer';
import { ResultTypeDictionaryData } from '@models/dictionary-model';

interface Props {
    part: IndicatorFormulaPart;
    resultTypes: ResultTypeDictionaryData[];
    onEdit: (part: IndicatorFormulaPart) => void;
    onEditAdditional: (tag: string) => void;
    onDelete: (part: IndicatorFormulaPart) => void;
}

const PartView: React.FC<Props> = ({ part, resultTypes, onEdit, onEditAdditional, onDelete }) => {
    const { t } = useTranslation();
    const [modal, contextHolder] = Modal.useModal();
    const [form] = Form.useForm<IndicatorFormulaPart>();

    const type = Form.useWatch('type', form);

    const getValueTitle = () => {
        return type === indicatorFormulaPartValues[0]
            ? t('indicator.content.indicator_formula.value.value.title')
            : t('indicator.content.indicator_formula.value.symbol.title');
    };

    const getValuePlaceholder = () => {
        return type === indicatorFormulaPartValues[0]
            ? t('indicator.content.indicator_formula.value.value.placeholder')
            : t('indicator.content.indicator_formula.value.symbol.placeholder');
    };

    const onChangeForm = () => {
        form.submit();
    };

    const confirmDelete = (part: IndicatorFormulaPart) => {
        modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: t('common.confirmation_required'),
            okText: t('common.delete'),
            cancelText: t('common.cancel'),
            onOk: () => onDelete(part)
        });
    };

    const onSuccessFormValidation = async () => {
        const data = form.getFieldsValue();
        let converted: IndicatorFormulaPart = plainToInstance(IndicatorFormulaPart, { ...part, ...data });
        onEdit(converted);
    };

    const getResultTypeSelectorItems = () => {
        let resultTypeTags: Array<{ value: string; label: string }> = resultTypes.map((item) => {
            return {
                value: item.tag,
                label: `${item.alt_title} (${item.tag})`
            };
        });
        resultTypeTags.push({ value: 'sum', label: 'Сумма (sum)' });
        return resultTypeTags;
    };
    const partTypes = [
        { value: indicatorFormulaPartValues[0], label: t('indicator.content.indicator_formula.type.value') },
        { value: indicatorFormulaPartValues[1], label: t('indicator.content.indicator_formula.type.symbol') }
    ];

    return (
        <div className="indicator-edit-formula-dnd-item">
            {contextHolder}
            <Form form={form} initialValues={part} layout="vertical" onFinish={onSuccessFormValidation} onChange={onChangeForm}>
                <Form.Item name="type">
                    <Select options={partTypes} />
                </Form.Item>
                {type && type === indicatorFormulaPartValues[0] && (
                    <Form.Item name="tag" label={t('indicator.content.indicator_formula.tag.title')}>
                        <Select options={getResultTypeSelectorItems()} onChange={onChangeForm} />
                    </Form.Item>
                )}
                <Form.Item name="value" label={getValueTitle() || ''}>
                    <Input placeholder={getValuePlaceholder() || ''} />
                </Form.Item>

                {!(type && type === indicatorFormulaPartValues[0]) && <div style={{ height: '86px' }}></div>}
            </Form>
            <Divider />
            <Tooltip title={t('indicator.drag.hint')}>
                <DashOutlined />
            </Tooltip>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 10, marginTop: 5 }}>
                {type && type === indicatorFormulaPartValues[0] && form.getFieldValue('tag') !== 'sum' && (
                    <Tooltip title={t('indicator.content.indicator_additional_value.edit.title')}>
                        <Badge count={part.value_count || 0} showZero>
                            <Button color="primary" variant="outlined" icon={<SlidersOutlined />} onClick={() => onEditAdditional(part.tag)}>
                                {t('indicator.content.indicator_additional_value.edit.values')}
                            </Button>
                        </Badge>
                    </Tooltip>
                )}
                <Tooltip title={t('common.delete')}>
                    <Button style={{ padding: 0, paddingTop: 5 }} type="dashed" icon={<DeleteOutlined />} onClick={() => confirmDelete(part)} />
                </Tooltip>
            </div>
        </div>
    );
};

export default PartView;
