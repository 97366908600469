import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Col, Divider, Row, Typography, notification, Button, Form, Tooltip, Tag } from 'antd';
import { useViewModel } from '@models/model';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { OauthClientEditViewModel } from './oauth.edit.view.model';
import { OauthClientsDataFull } from '@models/oauth-model';

const OauthClientEditView: React.FC = observer(() => {
    const { t } = useTranslation();
    const { id: clientId } = useParams();
    const [form] = Form.useForm<OauthClientsDataFull>();
    const navigate = useNavigate();

    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (description: string) => {
        notifyer.error({
            message: t('common.error.empty'),
            description: description
        });
    };

    const onClickCancel = () => {
        navigate('/oauth-clients-manage');
    };

    const viewModel = useViewModel(() => new OauthClientEditViewModel(clientId, (description: string) => showErrorNotification(description)));

    useEffect(() => {
        form.resetFields();
    }, [viewModel.client]);

    return (
        <div>
            {contextHolder}
            <Row gutter={12} align="middle" justify="space-between">
                <Col>
                    <Typography.Title>Клиент: {clientId}</Typography.Title>
                </Col>
                <Col>
                    <Button onClick={onClickCancel}>{t('common.back')}</Button>
                </Col>
            </Row>

            <Divider style={{ marginTop: 0 }} />

            <div className="edit-content-view">
                <Form form={form} initialValues={viewModel.client} layout="horizontal">
                    <Form.Item label={t('oauth.content.client_id.title')} name="client_id">
                        {/* <Input placeholder={t('oauth.content.client_id.title') || ''} /> */}
                        <Typography.Text strong copyable>
                            {viewModel.client.client_id}
                        </Typography.Text>
                    </Form.Item>
                    <Form.Item label={t('oauth.content.client_secret.title')} name="client_secret">
                        {/* <Input placeholder={t('oauth.content.client_secret.title') || ''} /> */}
                        <Typography.Text strong copyable>
                            {viewModel.client.client_secret}
                        </Typography.Text>
                    </Form.Item>
                    <Form.Item label={t('oauth.content.access_type.title')} name="access_type">
                        {/* <Input placeholder={t('oauth.content.access_type.title') || ''} /> */}
                        <Typography.Text strong>{viewModel.client.access_type}</Typography.Text>
                    </Form.Item>
                    <Form.Item label={t('oauth.content.operator_id.title')} name="operator_id">
                        {/* <Input placeholder={t('oauth.content.operator_id.title') || ''} /> */}
                        <Typography.Text strong>{viewModel.client.operator_id}</Typography.Text>
                    </Form.Item>
                    <Form.Item label={t('oauth.content.operator_name.title')} name="operator_name">
                        {/* <Input placeholder={t('oauth.content.operator_name.title') || ''} /> */}
                        <Typography.Text strong>{viewModel.client.operator_name}</Typography.Text>
                    </Form.Item>
                    <Form.Item label={t('oauth.content.operator_api_id.title')} name="operator_api_id">
                        {/* <Input placeholder={t('oauth.content.operator_api_id.title') || ''} /> */}
                        <Typography.Text strong>{viewModel.client.operator_api_id}</Typography.Text>
                    </Form.Item>
                    <Form.Item label={t('oauth.content.operator_api_name.title')} name="operator_api_name">
                        {/* <Input placeholder={t('oauth.content.operator_api_name.title') || ''} /> */}
                        <Typography.Text strong>{viewModel.client.operator_api_name}</Typography.Text>
                    </Form.Item>
                    <Form.Item label={t('oauth.content.scope.title')} name="scope">
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {viewModel.client?.scope &&
                                viewModel.client?.scope?.map((item, i) => (
                                    <Tooltip title={item.desc} key={`scope_item_${i}`} style={{ marginRight: '10px' }}>
                                        <Tag>{item.name}</Tag>
                                    </Tooltip>
                                ))}
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
});

export default OauthClientEditView;
