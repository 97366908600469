import { ArrowRightOutlined, DeleteOutlined, DownloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Table, Tooltip, Button, Space, Modal, Typography, Tag, Row, Col } from 'antd';
import ApiErrorsModal from '@components/UI/api-errors-modal/api-errors-modal.view';
import { ReportAttachedFile } from '@models/report';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';

interface TableProps {
    currentPage: number;
    pageSize: number;
    rows: ReportAttachedFile[];
    total: number;
    loading: boolean;
    onDownload: (user: ReportAttachedFile) => void;
    onDelete: (user: ReportAttachedFile) => void;
    onChangePagination: (page: number, pageSize: number) => void;
}

export const FilesTable = (props: TableProps) => {
    const { t } = useTranslation();
    const [modal, contextHolder] = Modal.useModal();
    const [data, setData] = useState<ReportAttachedFile[]>([]);
    const { id: reportId } = useParams();
    const [modalErrors, setModalErrors] = useState(false);
    const [loadingModalErrors, setLoadingModalErrors] = useState(0);

    const [selectedFileApiDataStorageId, setSelectedFileApiDataStorageId] = useState(0);

    useEffect(() => {
        setData(props.rows);
    }, [props]);

    const onChangePage = (page: number, pageSize: number) => {
        props.onChangePagination(page, pageSize);
    };

    const confirmDelete = (file: ReportAttachedFile) => {
        modal.confirm({
            title: t('common.confirmation_required'),
            icon: <ExclamationCircleOutlined />,
            content: t('reports.files.delete.confirm'),
            okText: t('common.delete'),
            cancelText: t('common.cancel'),
            onOk: () => props.onDelete(file)
        });
    };

    const status_colors: any = {
        null: '#FCCAD4',
        1: '#FFE8BE',
        2: '#FCCAD4',
        3: '#E4C1FF',
        4: '#FCCAD4',
        5: '#B5D8E1',
        6: '#D3E1E5',
        8: '#FCCAD4'
    };

    const openModalErrors = (api_data_storage_id: number | undefined) => {
        if (api_data_storage_id) {
            setSelectedFileApiDataStorageId(api_data_storage_id);
            setLoadingModalErrors(api_data_storage_id);
        }
        setModalErrors(true);
    };

    const closeModalError = () => {
        setLoadingModalErrors(0);
        setModalErrors(false);
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id'
        },
        {
            title: t('reports.files.filename'),
            dataIndex: 'name'
        },
        {
            title: t('reports.files.author'),
            render: (_: any, record: ReportAttachedFile) => {
                if (record.user_info.error) {
                    return <Typography.Text>{record.user_info.error}</Typography.Text>;
                } else {
                    return (
                        <Typography.Text>{`${record.user_info.last_name} ${record.user_info.first_name} ${record.user_info.middle_name}`}</Typography.Text>
                    );
                }
            }
        },
        {
            title: t('reports.files.created_at'),
            render: (_: any, record: ReportAttachedFile) => {
                return <Typography.Text>{dayjs(record.created_at).format('DD.MM.YYYY HH:mm')}</Typography.Text>;
            }
        },
        {
            title: t('reports.files.status_name'),
            render: (_: any, record: ReportAttachedFile) => {
                return (
                    <Row align="middle" justify="space-between">
                        <Col>
                            <Tooltip title={record.status_desc}>
                                <Tag color={status_colors[record.status_id]}>{record.status_name}</Tag>
                            </Tooltip>
                        </Col>
                        {(record.status_id === 4 || record.status_id === 8) && (
                            <Col>
                                <Tooltip title={record.api_data_storage_id ? t('reports.files.show_errors') : t('reports.files.show_errors_error')}>
                                    <Button
                                        icon={<ArrowRightOutlined />}
                                        onClick={() => openModalErrors(record.api_data_storage_id)}
                                        loading={loadingModalErrors === record.api_data_storage_id}
                                        disabled={record.api_data_storage_id ? false : true}
                                    />
                                </Tooltip>
                            </Col>
                        )}
                    </Row>
                );
            }
        },
        {
            title: '',
            width: '7%',
            dataIndex: 'operations',
            render: (_: any, record: ReportAttachedFile) => {
                return (
                    <Space direction="horizontal" size="small" align="center">
                        <Tooltip title={t('reports.files.download')}>
                            <Button onClick={() => props.onDownload(record)}>
                                <DownloadOutlined />
                            </Button>
                        </Tooltip>
                        <Tooltip title={t('common.delete')}>
                            <Button onClick={() => confirmDelete(record)}>
                                <DeleteOutlined />
                            </Button>
                        </Tooltip>
                    </Space>
                );
            }
        }
    ];

    return (
        <div>
            {contextHolder}

            <Table
                size="small"
                loading={props.loading}
                bordered
                dataSource={data}
                columns={columns}
                rowClassName="row"
                rowKey={'id'}
                pagination={{
                    current: props.currentPage,
                    pageSize: props.pageSize,
                    showSizeChanger: false,
                    total: props.total,
                    position: ['bottomCenter'],
                    onChange: onChangePage
                }}
            />

            <ApiErrorsModal apiDataStorageId={selectedFileApiDataStorageId} showModal={modalErrors} closeModal={() => setModalErrors(false)} />
        </div>
    );
};
