import { useState } from 'react';
import { Table, Form, Typography, Tooltip, Button } from 'antd';
import { useEffect } from 'react';
import { UnorderedListOutlined, FundProjectionScreenOutlined, ArrowRightOutlined, MinusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { MemberData } from '@models/member-model';
import { formatDate } from '@utils/date.utils';

interface TableProps {
    currentPage: number;
    pageSize: number;
    rows: MemberData[];
    total: number;
    loading: boolean;
    onShow: (id: number) => void;
    onShowEvent: (id: number) => void;
    onShowStartupProject: (id: number) => void;
    onChangePagination: (page: number, pageSize: number) => void;
}

export const MembersTable = (props: TableProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [data, setData] = useState<MemberData[]>([]);

    useEffect(() => {
        setData(props.rows);
    }, [props]);

    const onChangePage = (page: number, pageSize: number) => {
        props.onChangePagination(page, pageSize);
    };

    const columns = [
        {
            title: 'GUID',
            dataIndex: 'guid_putp'
        },
        {
            title: t('members.content.birth_date'),
            width: '8%',
            render: (_: any, record: MemberData) => {
                return <Typography.Text>{record.birth_date ? formatDate(record.birth_date, t('common.date.pattern.date')) : '-'}</Typography.Text>;
            }
        },
        {
            title: t('members.content.institution.title'),
            render: (_: any, record: MemberData) => {
                return <Typography.Text>{record.institution?.name || '-'}</Typography.Text>;
            }
        },
        {
            title: t('members.content.participation_count.title'),
            render: (_: any, record: MemberData) => {
                if (record.participation) {
                    const tooltipContent = () => {
                        if (record.participation?.type === 'event') return t('members.content.participation_count.description_event');
                        if (record.participation?.type === 'startup_project')
                            return t('members.content.participation_count.description_startup_project');
                        return '';
                    };
                    const icon = () => {
                        if (record.participation?.type === 'event') return <UnorderedListOutlined style={{ fontSize: 18, color: 'gray' }} />;
                        if (record.participation?.type === 'startup_project')
                            return <FundProjectionScreenOutlined style={{ fontSize: 18, color: 'gray' }} />;
                        return <MinusOutlined style={{ fontSize: 18, color: 'gray' }} />;
                    };
                    const action = () => {
                        if (record.participation?.type === 'event') return props.onShowEvent(record.participation.id);
                        if (record.participation?.type === 'startup_project') return props.onShowStartupProject(record.participation.id);
                    };

                    return (
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 5 }}>
                                <Typography.Text type="secondary">{record.participation.result_type_name}</Typography.Text>
                                <Typography.Text>{record.participation.name}</Typography.Text>
                            </div>
                            <Tooltip title={tooltipContent()}>
                                <Button style={{ padding: 0, paddingTop: 0, paddingLeft: 5, paddingRight: 5 }} onClick={action} icon={icon()} />
                            </Tooltip>
                        </div>
                    );
                } else {
                    return <Typography.Text>-</Typography.Text>;
                }
            }
        },
        {
            title: '',
            width: '5%',
            dataIndex: 'operations',
            render: (_: any, record: MemberData) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Tooltip title={t('common.open')}>
                            <Button onClick={() => props.onShow(record.id)}>
                                <ArrowRightOutlined />
                            </Button>
                        </Tooltip>
                    </div>
                );
            }
        }
    ];

    return (
        <Form form={form} component={false}>
            <Table
                size="small"
                loading={props.loading}
                bordered
                dataSource={data}
                columns={columns}
                rowClassName="row"
                rowKey={'id'}
                pagination={{
                    current: props.currentPage,
                    pageSize: props.pageSize,
                    total: props.total,
                    position: ['bottomCenter'],
                    onChange: onChangePage
                }}
            />
        </Form>
    );
};
