import { RootModel } from '@models/root-model';
import { APIInstance, BaseURL } from '@utils/api';
import { deleteUndefined } from '@utils/object.utils';
import { GetStaticPageFilesResponse, GetStaticPagesResponse, StaticPageResponse, StaticPage } from './static-page.contract';

export interface GetNewsParams {
    count: number;
    offset: number;
    search?: string | undefined;
}

export class StaticPageModel {
    public uploadURL(pageId: number) {
        return `${BaseURL}/cp-manager/static-page/${pageId}/file`;
    }

    public uploadHeaders = async () => {
        let accessToken = RootModel?.authModel?.getAccessToken();
        if (!accessToken) {
            await RootModel?.authModel?.updateToken();
            accessToken = RootModel?.authModel?.getAccessToken();
        }
        return { Authorization: RootModel?.authModel ? `Bearer ${accessToken}` : '' };
    };

    public async getPages(params: GetNewsParams): Promise<GetStaticPagesResponse> {
        try {
            const result = await APIInstance.get<GetStaticPagesResponse>('/cp-manager/static-page', {
                params: deleteUndefined({
                    count: params.count,
                    offset: params.offset,
                    search: (params.search || '').length > 0 ? params.search : undefined
                })
            });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getStaticPageById(id: string): Promise<StaticPageResponse> {
        try {
            const result = await APIInstance.get<StaticPageResponse>(`/cp-manager/static-page/${id}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async createStaticPage(page: StaticPage): Promise<StaticPageResponse> {
        try {
            const result = await APIInstance.post<StaticPageResponse>('/cp-manager/static-page', { ...page });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async updateStaticPage(page: StaticPage): Promise<StaticPageResponse> {
        try {
            const result = await APIInstance.put<StaticPageResponse>(`/cp-manager/static-page/${page.id}`, { ...page });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async deleteStaticPage(id: number) {
        try {
            const result = await APIInstance.delete(`/cp-manager/static-page/${id}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getFiles(pageId: number): Promise<GetStaticPageFilesResponse> {
        try {
            const result = await APIInstance.get(`/cp-manager/static-page/${pageId}/file`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async deleteFile(pageId: number, fileUUID: string) {
        try {
            const result = await APIInstance.delete(`/cp-manager/static-page/${pageId}/file/${fileUUID}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public getDownloarFileUrl(pageId: number, fileUUID: string) {
        return `${BaseURL}/cp-manager/static-page/${pageId}/file/${fileUUID}`;
    }
}
