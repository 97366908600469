import { Logger } from '@utils/logger';
import { AppModel } from '@models/app-model';
import { AuthModel } from '@models/auth-model';

export class RootModel {
    public appModel = new AppModel();
    public authModel = new AuthModel();

    public initialize = async () => {
        try {
            await this.authModel.initialize();
        } catch (error) {
            Logger.error(error);
        }
    };

    private static _instance: RootModel;

    private constructor() {
        // this.initialize();
    }

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }
}
