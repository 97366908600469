export class StartupData {
    id: number;
    name: string;
    short_name?: string;

    inn: string;
    kpp: string;
    ogrn: string;

    region_id?: number;
    institution_id?: number;
    contact_fio?: string;
    contact_phone?: string;
    contact_email?: string;
    startup_address?: string;
    startup_date?: string;
    month_id?: number;
    startup_liquidation_date?: string;
    base_id?: string;

    members_count?: number | undefined;
    projects_count?: number | undefined;
    rid_count?: number | undefined;
    finance_count?: number | undefined;

    created_at: string;
    updated_at: string;
}

export class GetStartupsResponse {
    data: StartupData[];
    total: number;
    count: number;
}

export class StartupResponse {
    data: StartupData;
}
