import { ObjectLiteral } from '@utils/object.utils';

export class EmailTemplateData {
    id: number;
    name: string;
    content: string;
    keys: ObjectLiteral | undefined;
    created_at: string;
    updated_at: string;
}

export class GetEmailTemplatesResponse {
    data: EmailTemplateData[];
    total: number;
}

export class EmailTemplateResponse {
    data: EmailTemplateData;
}
export class EmailTemplateFile {
    created_at: string;
    downloads: number;
    id: number;
    is_private: boolean;
    name: string;
    size: number;
    uuid: string;
    title?: string;
    key: string;
}

export class GetEmailTemplateFilesResponse {
    data: EmailTemplateFile[];
    count: number;
    total: number;
}
