import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Space, Table, Tooltip, Typography } from 'antd';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { PopularizationResourceData } from '@models/popularization/resource';
import dayjs from 'dayjs';

interface TableProps {
    currentPage: number;
    pageSize: number;
    rows: PopularizationResourceData[];
    total: number;
    loading: boolean;
    onChangePagination: (page: number, pageSize: number) => void;
    onDelete: (data: PopularizationResourceData) => void;
    onEdit: (data: PopularizationResourceData) => void;
}

export const ResourcesTable = (props: TableProps) => {
    const { t } = useTranslation();
    const [modal, contextHolder] = Modal.useModal();
    const [data, setData] = useState<PopularizationResourceData[]>([]);

    useEffect(() => {
        setData(props.rows);
    }, [props]);

    const onChangePage = (page: number, pageSize: number) => {
        props.onChangePagination(page, pageSize);
    };

    const confirmDelete = (data: PopularizationResourceData) => {
        modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: t('common.confirmation_required'),
            content: t('popularization.delete.confirm'),
            okText: t('common.delete'),
            cancelText: t('common.cancel'),
            onOk: () => props.onDelete(data)
        });
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id'
        },
        {
            title: t('popularization.resources.content.title'),
            dataIndex: 'title'
        },
        {
            title: t('popularization.resources.content.description'),
            dataIndex: 'desc'
        },
        {
            title: t('popularization.resources.content.url'),
            dataIndex: 'url'
        },
        {
            title: t('popularization.resources.content.clicks_count'),
            dataIndex: 'clicks_count'
        },
        {
            title: t('common.date.created_at'),
            render: (_: any, record: PopularizationResourceData) => {
                return <Typography.Text>{dayjs(record.created_at).format('DD.MM.YYYY HH:mm')}</Typography.Text>;
            }
        },
        {
            title: '',
            width: '8%',
            dataIndex: 'operations',
            render: (_: any, record: PopularizationResourceData) => {
                return (
                    <Space size="small">
                        <Tooltip title={t('common.edit')}>
                            <Button onClick={() => props.onEdit(record)}>
                                <EditOutlined />
                            </Button>
                        </Tooltip>
                        <Tooltip title={t('common.delete')}>
                            <Button onClick={() => confirmDelete(record)}>
                                <DeleteOutlined />
                            </Button>
                        </Tooltip>
                    </Space>
                );
            }
        }
    ];

    return (
        <div>
            <Table
                size="small"
                loading={props.loading}
                bordered
                dataSource={data}
                columns={columns}
                rowClassName="row"
                rowKey={'id'}
                pagination={{
                    current: props.currentPage,
                    pageSize: props.pageSize,
                    total: props.total,
                    position: ['bottomCenter'],
                    onChange: onChangePage
                }}
            />
            {contextHolder}
        </div>
    );
};

export default ResourcesTable;
