import { useState } from 'react';
import { Table, Form, Typography, Tooltip, Space, Button, Modal, Tag, Badge } from 'antd';
import { useEffect } from 'react';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { StaticPage } from '@models/static-page-model';
import { formatDate } from '@utils/date.utils';

interface TableProps {
    currentPage: number;
    pageSize: number;
    rows: StaticPage[];
    total: number;
    loading: boolean;
    onChangePagination: (page: number, pageSize: number) => void;
    onEdit: (staticPage: StaticPage) => void;
    onDelete: (staticPage: StaticPage) => void;
}

export const StaticPageTable = (props: TableProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [data, setData] = useState<StaticPage[]>([]);
    const [modal, contextHolder] = Modal.useModal();

    const confirmDelete = (staticPage: StaticPage) => {
        modal.confirm({
            title: t('common.confirmation_required'),
            icon: <ExclamationCircleOutlined />,
            content: t('static_page.delete.confirm'),
            okText: t('common.delete'),
            cancelText: t('common.cancel'),
            onOk: () => props.onDelete(staticPage)
        });
    };

    useEffect(() => {
        setData(props.rows);
    }, [props]);

    const onChangePage = (page: number, pageSize: number) => {
        props.onChangePagination(page, pageSize);
    };

    const columns = [
        {
            title: t('static_page.content.title.title'),
            dataIndex: 'title',
            render: (_: any, record: StaticPage) => {
                return <Typography.Text strong>{record.title}</Typography.Text>;
            }
        },
        {
            title: t('static_page.content.description.title'),
            dataIndex: 'description'
        },
        {
            title: t('static_page.content.tags.title'),
            render: (_: any, record: StaticPage) => {
                return (
                    <>
                        {record.tags_list.map((tag, index) => {
                            return (
                                <Tag key={`tag-${index}`} color="purple">
                                    {tag}
                                </Tag>
                            );
                        })}
                    </>
                );
            }
        },
        {
            title: t('static_page.content.is_visible.title'),
            width: '5%',
            render: (_: any, record: StaticPage) => {
                return (
                    <span>
                        {record.is_visible ? (
                            <Badge status="processing" color="primary" text={t('common.yes')} />
                        ) : (
                            <Badge status="error" text={t('common.no')} />
                        )}
                    </span>
                );
            }
        },
        {
            title: t('common.date.updated_at'),
            render: (_: any, record: StaticPage) => {
                return (
                    <span>
                        <Typography.Text strong>{formatDate(record.updated_at, t('common.date.pattern.date_time'))}</Typography.Text>
                    </span>
                );
            }
        },
        {
            title: '',
            width: '10%',
            dataIndex: 'operations',
            render: (_: any, record: StaticPage) => {
                return (
                    <Space direction="horizontal" size="small">
                        <Tooltip title={t('common.edit')}>
                            <Button onClick={() => props.onEdit(record)} style={{}}>
                                <EditOutlined />
                            </Button>
                        </Tooltip>
                        <Tooltip title={t('common.delete')}>
                            <Button onClick={() => confirmDelete(record)}>
                                <DeleteOutlined />
                            </Button>
                        </Tooltip>
                    </Space>
                );
            }
        }
    ];

    return (
        <Form form={form} component={false}>
            <Table
                loading={props.loading}
                bordered
                dataSource={data}
                columns={columns}
                rowClassName="row"
                rowKey={'id'}
                pagination={{
                    current: props.currentPage,
                    pageSize: props.pageSize,
                    total: props.total,
                    position: ['bottomCenter'],
                    onChange: onChangePage
                }}
            />
            {contextHolder}
        </Form>
    );
};
