export class LoggerService {
    private sentryEnabled: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

    init() {}

    log(msg: string, extra?: any) {
        console.log(msg, extra) ;
    }

    message(msg: string, extra?: Record<string, string>) {
        console.log(msg, { ...extra });
    }

    error(e: Error | unknown, extra?: Record<string, string>) {
        console.error(e, { ...extra });
    }

    debug(e: Error | unknown, extra?: Record<string, string>) {
        console.debug(e, { ...extra });
    }
}
