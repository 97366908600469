import { DictionaryModel, ResultTypeDictionaryData } from '@models/dictionary-model';
import { EventData, EventModel } from '@models/event-model';
import { APIUtilError } from '@utils/api';
import { CalendarMode } from 'antd/es/calendar/generateCalendar';
import { endOfDay, endOfMonth, endOfYear, startOfDay, startOfMonth, startOfYear } from 'date-fns';
import { makeAutoObservable, runInAction } from 'mobx';
import { ReportsGetFilter, ReportApiData, ReportApiModel } from '@models/report-api';
import { ObjectLiteral } from '@utils/object.utils';

export interface EventsDashboardViewModelStartParams {
    t: any;
    showError: (title: string, description: string) => void;
}

export class EventsDashboardViewModel {
    private dictionaryModel = new DictionaryModel();
    private eventModel = new EventModel();
    public resultTypeDictionary: ResultTypeDictionaryData[] = [];
    public calendarMode: CalendarMode = 'month';
    public isCalendarLoading: boolean = false;
    public isListLoading: boolean = false;
    public calendarEvents: ObjectLiteral = {};
    public listEvents: EventData[] = [];
    public selectedDate: Date;

    private reportModel = new ReportApiModel();
    public operatorsApiLoading: boolean = false;
    public operatorsApi: ReportApiData[] = [];

    constructor(private startParams: EventsDashboardViewModelStartParams) {
        makeAutoObservable(this);
        this.wakeUpSir();
    }

    private async wakeUpSir() {
        await this.fetchResultTypeDictionary();
        await this.fetchCalendarEvents({ range_start: startOfMonth(new Date()), range_end: endOfMonth(new Date()), mode: 'month' });
        await this.fetchReportsApi({});
    }

    private async fetchResultTypeDictionary() {
        runInAction(() => {
            this.isCalendarLoading = true;
        });
        try {
            const result = await this.dictionaryModel.getDictionary({ altname: 'result_type' });
            runInAction(() => {
                this.resultTypeDictionary = result.data as ResultTypeDictionaryData[];
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isCalendarLoading = false;
            });
        }
    }

    private async fetchCalendarEvents(params: { range_start: Date; range_end: Date; mode: 'month' | 'year' }) {
        runInAction(() => {
            this.isCalendarLoading = true;
        });

        try {
            const result = await this.eventModel.getEventCountByDates({
                range_start: params.range_start,
                range_end: params.range_end,
                type: params.mode
            });
            runInAction(() => {
                this.calendarEvents = result.data;
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isCalendarLoading = false;
            });
        }
    }

    public onChangeDate(date: Date, mode: CalendarMode) {
        runInAction(() => {
            this.calendarMode = mode;
        });
        this.fetchCalendarEvents({ range_start: this.getRangeStart(date, mode, 'cell'), range_end: this.getRangeEnd(date, mode, 'cell'), mode });
    }

    public onClickDate = (date: Date) => {
        runInAction(() => {
            this.selectedDate = date;
        });
        this.fetchListEvents(date);
    };

    private async fetchListEvents(date: Date) {
        let range_start = this.getRangeStart(date, this.calendarMode, 'list');
        let range_end = this.getRangeEnd(date, this.calendarMode, 'list');

        runInAction(() => {
            this.isListLoading = true;
        });

        try {
            const result = await this.eventModel.getEvents({
                range_start: range_start,
                range_end: range_end,
                page_size: 150
            });
            runInAction(() => {
                this.listEvents = this.mergeResultTypesWithEvents(this.resultTypeDictionary, result.data);
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isListLoading = false;
            });
        }
    }

    private mergeResultTypesWithEvents(resultTypes: ResultTypeDictionaryData[], events: EventData[]): EventData[] {
        return events.map((event) => {
            if (!(event.result_type_id || resultTypes.length === 0)) return event;
            const resultType = resultTypes.find((item) => item.id === event.id);
            if (!resultType) return event;
            return {
                ...event,
                result_type: resultType
            };
        });
    }

    public getRangeStart = (date: Date, mode: CalendarMode, target: 'list' | 'cell') => {
        switch (mode) {
            case 'month':
                switch (target) {
                    case 'cell':
                        return startOfMonth(date);
                    case 'list':
                        return startOfDay(date);
                }
                break;
            case 'year':
                switch (target) {
                    case 'cell':
                        return startOfYear(date);
                    case 'list':
                        return startOfMonth(date);
                }
                break;
        }
    };
    public getRangeEnd = (date: Date, mode: CalendarMode, target: 'list' | 'cell') => {
        switch (mode) {
            case 'month':
                switch (target) {
                    case 'cell':
                        return endOfMonth(date);
                    case 'list':
                        return endOfDay(date);
                }
                break;
            case 'year':
                switch (target) {
                    case 'cell':
                        return endOfYear(date);
                    case 'list':
                        return endOfMonth(date);
                }
                break;
        }
    };

    // operator-api

    private async fetchReportsApi(params: ReportsGetFilter) {
        runInAction(() => {
            this.operatorsApiLoading = true;
        });
        try {
            const result = await this.reportModel.getReportsApi(params);
            runInAction(() => {
                this.operatorsApi = result.data || [];
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.operatorsApiLoading = false;
            });
        }
    }
}
