import { IndicatorFormulaPart } from '@models/indicator-model';

export const extractFormulaString = (parts?: IndicatorFormulaPart[] | undefined) => {
    try {
        if (!parts || parts?.length === 0) return undefined;
        const math = parts.reduce((accum, current) => `${accum} ${current.value}`, '');
        return math;
    } catch {
        return undefined;
    }
};
