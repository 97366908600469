import { APIInstance } from '@utils/api';
import { GetWebsiteMenuResponse, GetWebsiteMenusResponse } from './website-menu.contract';
import { ObjectLiteral } from '@utils/object.utils';

export class WebsiteMenuModel {
    public async get(): Promise<GetWebsiteMenusResponse> {
        try {
            const result = await APIInstance.get<GetWebsiteMenusResponse>('/dict/schema_menu');
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getByAltName(alt_name: string): Promise<GetWebsiteMenuResponse> {
        try {
            const result = await APIInstance.get<GetWebsiteMenuResponse>(`/dict/schema_menu/${alt_name}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async updateMenuItems(alt_name: string, items: ObjectLiteral) {
        try {
            const result = await APIInstance.put(`/cp-manager/schema_menu/${alt_name}`, { items: items });
            return result.data;
        } catch (error) {
            throw error;
        }
    }
}
