import { PopularizationResourceData, PopularizationResourceModel } from '@models/popularization/resource';
import { APIUtilError } from '@utils/api';
import { ObjectLiteral } from '@utils/object.utils';
import { makeAutoObservable, runInAction } from 'mobx';

export interface ResourceEditViewModelStartParams {
    t: any;
    editId: string | undefined;
    showError: (title: string, description: string) => void;
    showSuccess: (title: string, description: string) => void;
}

export class ResourceEditViewModel {
    private resourceModel = new PopularizationResourceModel();

    public isLoading: boolean = false;
    public isPublished = false;
    public resource: PopularizationResourceData = new PopularizationResourceData();

    constructor(private props: ResourceEditViewModelStartParams) {
        this.isPublished = props.editId !== undefined;
        makeAutoObservable(this);
        this.wakeUpSir();
    }

    private async wakeUpSir() {
        if (this.props.editId && this.props.editId.length > 0) this.fetch(this.props.editId);
    }

    private async fetch(id: string) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.resourceModel.getById(id);
            runInAction(() => {
                this.resource = result.data;
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public onSave = async (data: ObjectLiteral) => {
        if (!this.props.editId) {
            await this.create(data);
        } else {
            await this.update(data);
        }
    };

    private create = async (event: any) => {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const created = await this.resourceModel.create(event);
            runInAction(() => {
                this.resource = created.data;
            });
            this.props.showSuccess(this.props.t('common.saved'), '');
        } catch (error) {
            this.props.showError(this.props.t('common.error.save'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };

    private update = async (startup: ObjectLiteral) => {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            await this.resourceModel.update(startup);
            this.props.showSuccess(this.props.t('common.saved'), '');
        } catch (error) {
            this.props.showError(this.props.t('common.error.save'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };
}
