import { useState } from 'react';
import { Table, Form, Typography, Tooltip, Button, Space, Badge, Modal, Statistic } from 'antd';
import { useEffect } from 'react';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { EventData } from '@models/event-model';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import CountUp from 'react-countup';

interface TableProps {
    currentPage: number;
    pageSize: number;
    rows: EventData[];
    total: number;
    loading: boolean;
    onEdit: (event: EventData) => void;
    onDelete: (event: EventData) => void;
    onChangePagination: (page: number, pageSize: number) => void;
    onShowMembers: (event: EventData) => void;
}

export const EditableTable = (props: TableProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [modal, contextHolder] = Modal.useModal();
    const [data, setData] = useState<EventData[]>([]);

    useEffect(() => {
        setData(props.rows);
    }, [props]);

    const onChangePage = (page: number, pageSize: number) => {
        props.onChangePagination(page, pageSize);
    };

    const confirmDelete = (event: EventData) => {
        modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: t('common.confirmation_required'),
            content: t('events.delete.confirm'),
            okText: t('common.delete'),
            cancelText: t('common.cancel'),
            onOk: () => props.onDelete(event)
        });
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id'
        },
        {
            title: t('events.content.name.title'),
            dataIndex: 'name'
        },
        {
            title: (
                <Tooltip title={t('events.content.is_visible.hint')}>
                    <Typography.Text>{t('events.content.is_visible.title')}</Typography.Text>
                </Tooltip>
            ),
            width: '8%',
            render: (_: any, record: EventData) => {
                return (
                    <span>
                        {record.is_visible ? (
                            <Badge status="processing" color="primary" text={t('common.yes')} />
                        ) : (
                            <Badge status="error" text={t('common.no')} />
                        )}
                    </span>
                );
            }
        },
        {
            title: t('events.content.member_count.title'),
            width: '14%',
            render: (_: any, record: EventData) => {
                return (
                    <Tooltip title={t('events.content.member_count.show')}>
                        <Button
                            style={{ padding: 0, paddingTop: 0, paddingLeft: 5, paddingRight: 5 }}
                            type="text"
                            onClick={() => props.onShowMembers(record)}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                                <UsergroupAddOutlined style={{ fontSize: 16, color: 'gray' }} />
                                <Statistic
                                    style={{ paddingTop: 4, paddingLeft: 5 }}
                                    valueStyle={{ fontSize: 16 }}
                                    value={record.members_count}
                                    formatter={(value: number | string) => {
                                        return <CountUp end={Number(value)} separator=" " />;
                                    }}
                                />
                            </div>
                        </Button>
                    </Tooltip>
                );
            }
        },
        {
            title: t('events.content.region.title'),
            width: '10%',
            render: (_: any, record: EventData) => {
                return <Typography.Text>{record.region?.name || '-'}</Typography.Text>;
            }
        },
        {
            title: t('events.content.begin_date.title'),
            render: (_: any, record: EventData) => {
                dayjs.extend(utc);
                const date = dayjs(record.begin_date);
                if (!date.isValid()) return <Typography.Text>-</Typography.Text>;
                return <Typography.Text>{date.utcOffset(record.timezone?.offset || 0).format('DD.MM.YYYY в HH:mm (Z)')}</Typography.Text>;
            }
        },
        {
            title: '',
            width: '7%',
            dataIndex: 'operations',
            render: (_: any, record: EventData) => {
                return (
                    <Space direction="horizontal" size="small" align="center">
                        <Tooltip title={t('common.edit')}>
                            <Button onClick={() => props.onEdit(record)}>
                                <EditOutlined />
                            </Button>
                        </Tooltip>
                        <Tooltip title={t('common.delete')}>
                            <Button onClick={() => confirmDelete(record)}>
                                <DeleteOutlined />
                            </Button>
                        </Tooltip>
                    </Space>
                );
            }
        }
    ];

    return (
        <Form form={form} component={false}>
            <Table
                size="small"
                loading={props.loading}
                bordered
                dataSource={data}
                columns={columns}
                rowClassName="row"
                rowKey={'id'}
                pagination={{
                    current: props.currentPage,
                    pageSize: props.pageSize,
                    total: props.total,
                    position: ['bottomCenter'],
                    onChange: onChangePage
                }}
            />
            {contextHolder}
        </Form>
    );
};
