import { MemberFilterInfo, MembersFilterParamName, MembersFilter } from '@interfaces/members.interface';
import { DictionaryModel, OrganizationDictionaryData, RegionDictionaryData, ResultTypeDictionaryData } from '@models/dictionary-model';
import { EventModel } from '@models/event-model';
import { MemberData, MemberModel } from '@models/member-model';
import { ResultModel } from '@models/result-model';
import { RidModel } from '@models/rid-model';
import { StartupModel } from '@models/startup-model';
import { StartupProjectModel } from '@models/startup-project-model';
import { APIUtilError } from '@utils/api';
import { ObjectLiteral, deleteUndefined, isObjectsHasDifferences } from '@utils/object.utils';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { makeAutoObservable, runInAction, toJS } from 'mobx';

export interface MembersViewModelStartParams {
    t: any;
    searchParams: URLSearchParams;
    setSearchParams: (params: any) => void;
    showError: (title: string, description: string) => void;
}

export class MembersViewModel {
    private dictionaryModel = new DictionaryModel();
    private memberModel = new MemberModel();
    private resultModel = new ResultModel();
    private eventModel = new EventModel();
    private startupModel = new StartupModel();
    private startupProjectModel = new StartupProjectModel();
    private ridModel = new RidModel();

    public isLoading: boolean = false;

    public currentPage: number = 1;
    public pageSize: number = 20;
    public availablePageSizes = [10, 20, 50, 100];
    public currentFilter: MembersFilter = {};

    public currentFilterInfo: MemberFilterInfo[] = [];
    public isCurrentFilterLoading = false;

    public membersTotal: number = 0;
    public membersUniq: number = 0;
    public members: MemberData[] = [];
    public memberOnShow?: MemberData;

    public isRegionLoading: boolean = false;
    public regionDictionary: RegionDictionaryData[] = [];

    public isOrganizationsLoading: boolean = false;
    public organizations: OrganizationDictionaryData[] = [];

    public resultTypeDictionary: ResultTypeDictionaryData[] = [];

    constructor(private props: MembersViewModelStartParams) {
        const incomingFilter = this.getFilterFromSearchParams(props.searchParams);
        this.currentFilter = incomingFilter;
        this.currentPage = incomingFilter.page || 0;
        this.pageSize = incomingFilter.page_size || this.availablePageSizes[1];

        makeAutoObservable(this);
        this.wakeUpSir();
    }

    private async wakeUpSir() {
        await this.fetchRegionDictionary();
        await this.fetchMembers(this.currentFilter);
        await this.prepareFilterInfo(this.currentFilter);
        await this.fetchResultTypeDictionary();
    }

    public onShow = async (id: number) => {
        const filter: MembersFilter = { ...this.currentFilter, show: id };
        runInAction(() => {
            this.currentFilter = filter;
        });
        const currentFiltersSearchParams = this.extractSearchParamsFromFilter(filter);
        this.props.setSearchParams(currentFiltersSearchParams);

        await this.fetchForShow(`${id}`);
    };

    private async fetchForShow(id: number | string) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.memberModel.getMemberById(id);
            runInAction(() => {
                this.memberOnShow = result.data;
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public onCancelShow = () => {
        const copy = { ...this.currentFilter };
        copy.show = undefined;
        const searchParams = this.extractSearchParamsFromFilter(deleteUndefined(copy));
        this.props.setSearchParams(searchParams as any);

        runInAction(() => {
            this.memberOnShow = undefined;
        });
    };

    public async updateSearchParamsIfNeeded(searchParams: URLSearchParams): Promise<Boolean> {
        const newFilter = this.getFilterFromSearchParams(searchParams);
        if (isObjectsHasDifferences(newFilter, toJS(this.currentFilter))) {
            runInAction(() => {
                this.currentPage = newFilter.page || 1;
                this.pageSize = newFilter.page_size || this.availablePageSizes[1];
                this.currentFilter = newFilter;
            });
            return true;
        }
        return false;
    }

    private getFilterFromSearchParams = (searchParams: URLSearchParams) => {
        let filter: MembersFilter = { page: 1, page_size: this.pageSize };
        const keys: MembersFilterParamName[] = [
            'show',
            'guid',
            'result_type_id',
            'result_id',
            'event_id',
            'startup_id',
            'startup_project_id',
            'rid_id',
            'region_id',
            'institution_id',
            'page',
            'page_size',
            'name'
        ];

        for (const key of keys) {
            const value = searchParams.get(key);
            if (!value) continue;
            switch (key) {
                case 'guid':
                case 'name':
                    filter[key] = value;
                    break;
                case 'show':
                case 'result_type_id':
                case 'result_id':
                case 'event_id':
                case 'startup_id':
                case 'startup_project_id':
                case 'rid_id':
                case 'region_id':
                case 'institution_id':
                case 'page':
                    try {
                        filter[key] = parseInt(value);
                    } catch {
                        filter[key] = undefined;
                    }
                    break;
                case 'page_size':
                    try {
                        const parsedSize = parseInt(value);
                        if (!this.availablePageSizes.includes(parsedSize)) throw new Error();
                        filter.page_size = parsedSize;
                    } catch {
                        filter.page_size = this.availablePageSizes[0];
                    }
                    break;

                default:
                    break;
            }
        }
        return filter;
    };

    private extractSearchParamsFromFilter = (filter: ObjectLiteral) => {
        let searchParams: ObjectLiteral = {};
        for (const key in filter) {
            switch (key) {
                default:
                    searchParams[key] = filter[key] ? filter[key] : undefined;
            }
        }
        return deleteUndefined(searchParams);
    };

    public filterInfoCount(filter: MembersFilter) {
        let count = 0;
        const keys: MembersFilterParamName[] = ['result_id', 'event_id', 'startup_id', 'startup_project_id', 'rid_id'];
        for (const key of keys) {
            const value = filter[key];
            if (!value || value === undefined) continue;
            count++;
        }
        return count;
    }

    public async prepareFilterInfo(filter: MembersFilter) {
        runInAction(() => {
            this.isCurrentFilterLoading = true;
        });

        let info: MemberFilterInfo[] = [];
        const keys: MembersFilterParamName[] = ['show', 'result_id', 'event_id', 'startup_id', 'startup_project_id', 'rid_id', 'institution_id'];
        for (const key of keys) {
            const value = filter[key];
            if (!value || value === undefined) continue;
            switch (key) {
                case 'show':
                    try {
                        await this.fetchForShow(filter[key]!);
                    } catch {}
                    break;
                case 'institution_id':
                    await this.getOrganizationById(filter[key]!);
                    break;
                case 'result_id':
                    try {
                        const result = await this.resultModel.getById(filter[key]!);
                        info.push({
                            type: key,
                            name: this.props.t('members.filter.result_id'),
                            value: result.data.title
                        });
                    } catch {}
                    break;
                case 'event_id':
                    try {
                        const result = await this.eventModel.getEventById(filter[key]!);
                        info.push({
                            type: key,
                            name: this.props.t('members.filter.event_id'),
                            value: result.data.name
                        });
                    } catch {}
                    break;
                case 'startup_id':
                    try {
                        const result = await this.startupModel.getStartupById(filter[key]!);
                        info.push({
                            type: key,
                            name: this.props.t('members.filter.startup_id'),
                            value: result.data.name
                        });
                    } catch {}
                    break;
                case 'startup_project_id':
                    try {
                        const result = await this.startupProjectModel.getStartupProjectById(filter[key]!);
                        info.push({
                            type: key,
                            name: this.props.t('members.filter.startup_project_id'),
                            value: result.data.name
                        });
                    } catch {}
                    break;
                case 'rid_id':
                    try {
                        const result = await this.ridModel.getRidById(filter[key]!);
                        info.push({
                            type: key,
                            name: this.props.t('members.filter.rid_id'),
                            value: result.data.name
                        });
                    } catch {}
                    break;
                default:
                    break;
            }
        }

        runInAction(() => {
            this.currentFilterInfo = info;
            this.isCurrentFilterLoading = false;
        });
    }

    public onRemoveFilterInfo = (info: MemberFilterInfo) => {
        let filterCopy = Object.assign({}, this.currentFilter);
        filterCopy[info.type] = undefined;
        this.onFilterValueChange(filterCopy);
    };

    public onFilterValueChange = (values: ObjectLiteral) => {
        let filter: MembersFilter = { ...this.currentFilter, ...values, page: 1, page_size: this.pageSize };
        const searchParams = this.extractSearchParamsFromFilter(deleteUndefined(filter));
        this.props.setSearchParams(searchParams as any);

        runInAction(() => {
            this.currentPage = 1;
            this.currentFilter = deleteUndefined(filter);
        });
        this.debouncedFetch(deleteUndefined(filter));
    };

    private debouncedFetch = debounce(async (params: MembersFilter) => {
        this.fetchMembers(params);
    }, 400);

    private async fetchMembers(params: MembersFilter) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.memberModel.getMembers(params);
            runInAction(() => {
                this.members = result.data;
                this.membersTotal = result.total || 0;
                this.membersUniq = result.total_guid || 0;
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public onChangePagination = async (page: number, pageSize: number) => {
        if (pageSize !== this.pageSize) {
            runInAction(() => {
                this.pageSize = pageSize;
            });
        }
        const filter: MembersFilter = { ...this.currentFilter, page: page, page_size: pageSize };
        runInAction(() => {
            this.currentPage = page;
            this.currentFilter = filter;
        });

        const currentFiltersSearchParams = this.extractSearchParamsFromFilter(filter);
        this.props.setSearchParams(currentFiltersSearchParams);

        this.fetchMembers(filter);
    };

    public get isExportAvailable() {
        if (this.members.length === 0) return false;
        // Экспорт недоступен, если фильтр не пустой
        const filter = Object.assign({}, this.currentFilter);
        delete filter.page;
        delete filter.page_size;
        if (Object.keys(filter).length === 0) return false;
        return true;
    }

    public onExportExcel = async () => {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            await this.memberModel.getExcelExport(
                this.currentFilter,
                `${this.props.t('members.export.excel.filename')}_${dayjs(new Date()).format('DD.MM.YYYY_HH:mm')}.xlsx`
            );
        } catch (error) {
            this.props.showError(this.props.t('common.error.empty'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };

    private async fetchRegionDictionary() {
        runInAction(() => {
            this.isRegionLoading = true;
        });
        try {
            const result = await this.dictionaryModel.getDictionary({ altname: 'region', page_size: 100 });
            runInAction(() => {
                this.regionDictionary = result.data as RegionDictionaryData[];
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isRegionLoading = false;
            });
        }
    }

    private async fetchResultTypeDictionary() {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.dictionaryModel.getDictionary({ altname: 'result_type' });
            runInAction(() => {
                this.resultTypeDictionary = result.data as ResultTypeDictionaryData[];
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public async searchOrganizations(query: string) {
        runInAction(() => {
            this.isOrganizationsLoading = true;
        });
        try {
            const result = await this.dictionaryModel.getDictionary({ altname: 'institution', search: query });
            runInAction(() => {
                this.organizations = result.data as OrganizationDictionaryData[];
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isOrganizationsLoading = false;
            });
        }
    }

    private async getOrganizationById(id: string | number) {
        runInAction(() => {
            this.isOrganizationsLoading = true;
        });
        try {
            const result = await this.dictionaryModel.getDictionaryItemById('institution', id);
            runInAction(() => {
                this.organizations = result.data as OrganizationDictionaryData[];
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isOrganizationsLoading = false;
            });
        }
    }
}
