import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Space, Table, Tooltip } from 'antd';
import { EventDataContact } from '@models/event-model';
import { v4 } from 'uuid';
import { DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined, StopOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Rule } from 'antd/es/form';
import { deleteUndefined } from '@utils/object.utils';

interface TableItem extends EventDataContact {
    _id: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    record: TableItem;
    index: number;
    children: React.ReactNode;
    rules: Rule[];
}

const EditableCell: React.FC<EditableCellProps> = ({ editing, dataIndex, title, record, index, rules, children, ...restProps }) => {
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item name={dataIndex} style={{ margin: 0 }} rules={rules}>
                    <Input />
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

export interface ContactsTableProps {
    data: EventDataContact[];
    maxContactsCount: number;
    onUpdate: (data: EventDataContact[]) => void;
}

const prepareDataForTable = (data: EventDataContact[]) => {
    return data.map((item) => {
        return {
            _id: v4(),
            ...item
        };
    });
};

const ContactsTable: React.FC<ContactsTableProps> = (props: ContactsTableProps) => {
    const { data, maxContactsCount, onUpdate } = props;
    const { t } = useTranslation();

    const [form] = Form.useForm();
    const [tableData, setTableData] = useState<TableItem[]>([]);
    const [editingId, setEditingId] = useState('');

    useEffect(() => {
        setTableData(prepareDataForTable(data));
    }, [data]);

    const isRowsOutOfLimits = () => {
        if (maxContactsCount && tableData.length >= maxContactsCount) return true;
    };

    const isEditing = (record: TableItem) => record._id === editingId;

    const onUpdateData = (data: TableItem[]) => {
        /// Костыли для капризных бекендов
        const prepared: EventDataContact[] = data.map((item) => {
            return deleteUndefined({
                ...item,
                _id: undefined,
                email: item.email ? item.email : undefined,
                phone: item.phone ? item.phone : undefined
            });
        });
        onUpdate(prepared);
    };

    const onAdd = () => {
        const newRecord = { _id: v4(), email: '', fio: '', phone: '' };
        setTableData([...tableData, newRecord]);
        onUpdateData([...tableData, newRecord]);
        setTimeout(() => {
            onEdit(newRecord);
        }, 100);
    };

    const onEdit = (record: TableItem) => {
        form.setFieldsValue({ ...record });
        setEditingId(record._id);
    };

    const onCancel = () => {
        setEditingId('');
    };

    const onSave = async (id: string) => {
        try {
            const row = (await form.validateFields()) as TableItem;
            let updatedData = [...tableData];
            const index = updatedData.findIndex((item) => id === item._id);
            if (index === -1) return;
            updatedData[index] = { ...updatedData[index], ...row };
            setTableData(updatedData);
            setEditingId('');
            onUpdateData(updatedData);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const onDelete = async (record: TableItem) => {
        const filtered = [...tableData].filter((item) => item._id !== record._id);
        setTableData(filtered);
        onUpdateData(filtered);
    };

    const columns = [
        {
            title: t('events.content.contacts.fio'),
            dataIndex: 'fio',
            editable: true
        },
        {
            title: t('events.content.contacts.email'),
            dataIndex: 'email',
            width: '25%',
            editable: true
        },
        {
            title: t('events.content.contacts.phone'),
            dataIndex: 'phone',
            width: '20%',
            editable: true
        },
        {
            width: '20%',
            dataIndex: 'operation',
            render: (_: any, record: TableItem) => {
                const editable = isEditing(record);
                return editable ? (
                    <Space>
                        <Tooltip title={t('common.save')}>
                            <Button onClick={() => onSave(record._id)}>
                                <SaveOutlined />
                            </Button>
                        </Tooltip>
                        <Tooltip title={t('common.cancel')}>
                            <Button onClick={() => onCancel()}>
                                <StopOutlined />
                            </Button>
                        </Tooltip>
                    </Space>
                ) : (
                    <Space>
                        <Tooltip title={t('common.edit')}>
                            <Button onClick={() => onEdit(record)}>
                                <EditOutlined />
                            </Button>
                        </Tooltip>
                        <Tooltip title={t('common.delete')}>
                            <Button onClick={() => onDelete(record)}>
                                <DeleteOutlined />
                            </Button>
                        </Tooltip>
                    </Space>
                );
            }
        }
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: TableItem) => ({
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                rules: col.dataIndex === 'fio' ? [{ required: true, message: t('events.content.required') || '' }] : []
            })
        };
    });

    return (
        <div>
            <Form form={form} component={false}>
                <Table
                    components={{
                        body: {
                            cell: EditableCell
                        }
                    }}
                    rowKey={'_id'}
                    dataSource={tableData}
                    columns={mergedColumns}
                    pagination={false}
                />
            </Form>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Button
                    disabled={isRowsOutOfLimits()}
                    onClick={onAdd}
                    type="primary"
                    icon={<PlusOutlined />}
                    size="middle"
                    style={{ marginTop: 10 }}
                />
            </div>
        </div>
    );
};

export default ContactsTable;
