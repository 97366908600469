import { Indicator, IndicatorModel } from '@models/indicator-model';
import { APIUtilError } from '@utils/api';
import { makeAutoObservable, runInAction } from 'mobx';

export interface IndicatorViewModelStartParams {
    t: any;
    showError: (title: string, description: string) => void;
}

export class IndicatorViewModel {
    private indicatorModel = new IndicatorModel();
    public loading: boolean = false;
    public indicators: Indicator[] = [];

    public indicatorOnEdit?: Indicator | undefined;

    constructor(private startParams: IndicatorViewModelStartParams) {
        makeAutoObservable(this);
        this.fetchIndicators();
    }

    private async fetchIndicators() {
        this.loading = true;

        try {
            const result = await this.indicatorModel.get({});
            runInAction(() => {
                this.indicators = result.data;
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
