import { makeAutoObservable, runInAction } from 'mobx';
import { ApiErrorsModel, OperatorError, IDObject } from '@models/api-errors-model';
import { APIUtilError } from '@utils/api';

export interface OperatorsViewModelStartParams {
    apiId?: number;
    apiDataStorageId?: number;
    t: any;
    showError: (title: string, description: string) => void;
}

export class ApiErrorsViewModel {
    private apiErrorsModel = new ApiErrorsModel();

    public apiErrors: OperatorError[] = [];
    public isLoadingErrors: boolean = false;
    public errorPageSize: number = 10;
    public errorPageTotal: number = 0;
    public activeErrorsApiId: IDObject = {};
    public solvedErrors: number[] = [];

    constructor(private startParams: OperatorsViewModelStartParams) {
        makeAutoObservable(this);

        if (startParams.apiId) {
            this.activeErrorsApiId = { api_id: startParams.apiId };
        } else if (startParams.apiDataStorageId) {
            this.activeErrorsApiId = { api_data_storage_id: startParams.apiDataStorageId };
        }
        this.fetchErrors(this.activeErrorsApiId, this.errorPageSize, 0);
    }

    public async fetchErrors(id_object: IDObject, limit?: number, offset?: number, solved_filter?: boolean) {
        runInAction(() => {
            this.isLoadingErrors = true;
        });
        try {
            const result = await this.apiErrorsModel.fetchErrors(id_object, limit, offset, solved_filter);
            runInAction(() => {
                this.apiErrors = result.data;
                this.errorPageTotal = result.total;
                this.isLoadingErrors = false;
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoadingErrors = false;
            });
        }
    }

    public onChangeErrorPage = async (page: number, pageSize: number) => {
        if (pageSize !== this.errorPageSize) {
            runInAction(() => {
                this.errorPageSize = pageSize;
            });
        }
        this.fetchErrors(this.activeErrorsApiId, pageSize, (page - 1) * pageSize);
    };

    public setSolvedErrors = async (solve?: boolean) => {
        try {
            await this.apiErrorsModel.setSolvedErrors(this.solvedErrors, this.activeErrorsApiId, solve);
        } catch (error) {
            this.startParams.showError(
                this.startParams.t('operators.content.errors.table.failed_solve'),
                (error as APIUtilError).localizedDescription
            );
        }
        this.solvedErrors = [];
    };

    public deleteErrors = async (id_object: IDObject) => {
        this.isLoadingErrors = true;
        try {
            await this.apiErrorsModel.deleteErrors(id_object);
        } catch (error) {
            this.startParams.showError(
                this.startParams.t('operators.content.errors.table.failed_solve'),
                (error as APIUtilError).localizedDescription
            );
        }
        this.isLoadingErrors = false;
    };
}
