import React, { useEffect, useState } from 'react';
import SunEditor from 'suneditor-react';
import lang from 'suneditor-react/dist/types/lang';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

interface Props {
    placeholder: string;
    languageCode: string;
    editorDefaultValue?: string | undefined;
    readOnly?: boolean | undefined;
    onChange: (content: string) => void;
}

const WYSIWYGEditor: React.FC<Props> = ({ placeholder, languageCode, editorDefaultValue, readOnly, onChange }) => {
    const [sunEditorLanguage, setSunEditorLanguage] = useState<lang>('en');

    useEffect(() => {
        defineEditorLanguageCode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languageCode]);

    function defineEditorLanguageCode() {
        if (languageCode.length === 5) {
            let localCode = languageCode.substring(0, 2).toLowerCase();
            try {
                setSunEditorLanguage(localCode as lang);
            } catch {
                return;
            }
        }
    }

    return (
        <div>
            <SunEditor
                height="400px"
                defaultValue={editorDefaultValue}
                onChange={onChange}
                placeholder={placeholder}
                lang={sunEditorLanguage}
                readOnly={readOnly}
                setOptions={{
                    buttonList: [
                        ['fullScreen'],
                        [],
                        ['undo', 'redo'],
                        [],
                        ['font', 'fontSize', 'formatBlock'],
                        [],
                        ['paragraphStyle', 'blockquote'],
                        [],
                        ['removeFormat'],
                        // '/',
                        ['align', 'list', 'lineHeight'],
                        [],
                        ['bold', 'underline', 'italic', 'strike'],
                        [],
                        ['subscript', 'superscript'],
                        [],
                        ['fontColor', 'hiliteColor', 'textStyle'],
                        // '/', // Line break
                        [],
                        ['table', 'link', 'image', 'video'],
                        [],
                        ['showBlocks', 'codeView', 'preview']
                    ]
                }}
            />
        </div>
    );
};
export default WYSIWYGEditor;
