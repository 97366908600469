import { ObjectLiteral } from '@utils/object.utils';

export class User {
    id?: string;
    email: string;
    name?: string;
    is_active: boolean;
    phone: null;
    role_id: string;
}

export class UserResponse {
    data: User;
}

export class UserCreateResponse {
    data: {
        id: string;
    };
}

export interface UsersGetFilter {
    search?: string | undefined;
    page?: number | undefined;
    page_size?: number | undefined;
}

export class GetUsersResponse {
    data: User[] = [];
    count: number = 0;
    total: number = 0;
}

// ORG INST

export class UserInst {
    id: number;
    name: string;
}
export class UserInstResponse {
    data: UserInst;
}

export class UserHistoryLoggerData {
    id: number;
    user: HistoryLoggerDataUser;
    obj_id: number;
    /// Тип действия: 'create' | 'update' | 'delete'
    action: string;
    created_at: string;

    /// Объект с предыдущим состоянием
    prev_data?: User;
    /// Объект с измененными полями
    changed_data?: ObjectLiteral;
}

export class HistoryLoggerDataUser {
    id: string;
    name: string;
}

export class UserHistoryLoggerDataListResponse {
    data: UserHistoryLoggerData[];
    total: number;
    count: number;
}

export class UserHistoryLoggerDataItemResponse {
    data: UserHistoryLoggerData;
}
