import { IndicatorModel, IndicatorValue } from '@models/indicator-model';
import { APIUtilError } from '@utils/api';
import { makeAutoObservable, runInAction } from 'mobx';

interface Props {
    indicatorId: string;
    t: any;
    showSuccess: (title: string, description: string) => void;
    showError: (description: string) => void;
}
export class IndicatorValueEditViewModel {
    private indicatorModel = new IndicatorModel();
    public isLoading: boolean = false;

    public currentPage: number = 1;
    public pageSize: number = 20;
    public dataTotal: number = 0;

    private _indicatorValues: IndicatorValue[] = [];
    public get indicatorValues(): IndicatorValue[] {
        return this._indicatorValues;
    }

    constructor(private startParams: Props) {
        makeAutoObservable(this);
        this.wakeUpSir();
    }

    private async wakeUpSir() {
        await this.fetchIndicatorValues(this.currentPage, this.pageSize);
    }

    private async fetchIndicatorValues(page: number, page_size: number) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.indicatorModel.getIndicatorValues({ indicator_id: this.startParams.indicatorId, page, page_size });
            runInAction(() => {
                this._indicatorValues = result.data;
                this.dataTotal = result.total;
            });
        } catch (error) {
            this.startParams.showError((error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public onChangePagination = async (page: number, pageSize: number) => {
        runInAction(() => {
            this.currentPage = page;
            this.pageSize = pageSize;
        });
        this.fetchIndicatorValues(page, pageSize);
    };

    public async updateValues(id: number, value: number, is_locked: boolean): Promise<boolean> {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            await this.indicatorModel.updateIndicatorValue(id, { value, is_locked });
            runInAction(() => {
                this.isLoading = false;
            });
            this.startParams.showSuccess(this.startParams.t('common.saved'), '');
            return true;
        } catch (error) {
            this.startParams.showError((error as APIUtilError).localizedDescription);
            runInAction(() => {
                this.isLoading = false;
            });
            return false;
        }
    }
}
