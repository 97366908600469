import { PopularizationInfopovodFilter } from '@interfaces/popularization-infopovod.interface';
import { APIInstance } from '@utils/api';
import { deleteUndefined } from '@utils/object.utils';
import { PopularizationInfopovodResponse, PopularizationInfopovodsResponse } from './popularization-infopovod.contract';

export class PopularizationInfopovodModel {
    public async get(params: PopularizationInfopovodFilter): Promise<PopularizationInfopovodsResponse> {
        try {
            const result = await APIInstance.get<PopularizationInfopovodsResponse>('/cp-manager/popularization/infopovod', {
                params: deleteUndefined({
                    search: params.search,
                    limit: params.page_size,
                    offset: params.page_size && params.page ? params.page_size * (params.page - 1) : 0
                })
            });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getById(id: string): Promise<PopularizationInfopovodResponse> {
        try {
            const result = await APIInstance.get<PopularizationInfopovodResponse>(`/cp-manager/popularization/infopovod/${id}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async create(data: any): Promise<PopularizationInfopovodResponse> {
        try {
            const result = await APIInstance.post<PopularizationInfopovodResponse>('/cp-manager/popularization/infopovod', { ...data });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async update(data: any) {
        try {
            const result = await APIInstance.put(`/cp-manager/popularization/infopovod/${data.id}`, { ...data });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async delete(id: number) {
        try {
            const result = await APIInstance.delete(`/cp-manager/popularization/infopovod/${id}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }
}
