import { LocalStorageKeys, LocalStorageUtil } from '@utils/local-storage';
import { makeAutoObservable, runInAction } from 'mobx';

export class AppModel {
    private localStorageUtil = new LocalStorageUtil();
    public isSidebarCollapsed = false;
    public isCompact = false;
    public isNewTab = false;

    constructor() {
        makeAutoObservable(this);
        this.weakUp();
    }

    private async weakUp() {
        const isSidebarCollapsed: boolean | undefined = this.localStorageUtil.get(LocalStorageKeys.sidebar.collapsed());
        this.isSidebarCollapsed = isSidebarCollapsed || false;

        this.isCompact = this.localStorageUtil.get(LocalStorageKeys.theme.compact) || false;
        this.isNewTab = this.localStorageUtil.get(LocalStorageKeys.links.onNewTab) || false;
    }

    public toggleCollapsedSidebar = () => {
        this.localStorageUtil.set(!this.isSidebarCollapsed, LocalStorageKeys.sidebar.collapsed());
        runInAction(() => {
            this.isSidebarCollapsed = !this.isSidebarCollapsed;
        });
    };

    public toggleCompact = () => {
        this.localStorageUtil.set(!this.isCompact, LocalStorageKeys.theme.compact);
        runInAction(() => {
            this.isCompact = !this.isCompact;
        });
    };

    public toggleNewTab = () => {
        this.localStorageUtil.set(!this.isNewTab, LocalStorageKeys.links.onNewTab);
        runInAction(() => {
            this.isNewTab = !this.isNewTab;
        });
    };
}
