export class PopularizationInfopovodData {
    id?: number;
    title: string;
    desc: string;
    is_deleted: boolean;
    subtitle: string;
    date_published: string;
    created_at: string;
    updated_at: string;
}

export class PopularizationInfopovodsResponse {
    data: PopularizationInfopovodData[];
    total: number;
}

export class PopularizationInfopovodResponse {
    data: PopularizationInfopovodData;
}
