export class FinanceData {
    id: number;
    transaction_type: TransactionType;
    transaction_type_id: number;
    inv_volume: number;
    invest_date: string;
    startup_project_count?: number;

    contract_number?: string;
    investor_base_id?: string;
}

class TransactionType {
    id: number;
    name: string;
}

export class GetFinancesResponse {
    data: FinanceData[];
    total: number;
    count: number;
}

export class FinanceResponse {
    data: FinanceData;
}
