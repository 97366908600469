import { Profile, TokenResponse } from './auth.contract';
import JWTDecoder from 'jwt-decode';
import { makeAutoObservable, runInAction } from 'mobx';
import Cookies from 'js-cookie';
import { add } from 'date-fns';
import { APICleanInstance } from '@utils/api';

const accessTokenKey: string = process.env.REACT_APP_ACCESS_TOKEN_STORAGE_KEY || 'putp_access_token';
const refreshTokenKey: string = process.env.REACT_APP_REFRESH_TOKEN_STORAGE_KEY || 'putp_refresh_token';

export class AuthModel {
    public getAccessToken = (): string | undefined => {
        return Cookies.get(accessTokenKey);
    };
    private getRefreshToken = (): string | undefined => {
        return Cookies.get(refreshTokenKey);
    };
    public isAuthenticated = false;

    public profile?: Profile = undefined;

    constructor() {
        const token = this.getAccessToken();
        if (token && token.length > 0) {
            this.profile = this.extractProfileFromToken(token);
            this.isAuthenticated = true;
        }
        makeAutoObservable(this);
    }

    public initialize = async () => {
        if (!this.getAccessToken() && this.getRefreshToken()) {
            await this.updateToken();
        }
    };

    public async signIn(username: string, password: string): Promise<TokenResponse> {
        try {
            const result = await APICleanInstance.post<TokenResponse>('/auth/login', { username, password, scope: 'cp-manager' });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async updateToken() {
        if (!this.getRefreshToken()) return;
        try {
            const result = await APICleanInstance.post<TokenResponse>('/auth/refresh', { refresh_token: this.getRefreshToken() });
            this.applyTokens(result.data);

            const profile = this.extractProfileFromToken(result.data.access_token);
            this.applyProfile(profile);
        } catch (error) {
            throw error;
        }
    }

    public applyTokens(data: TokenResponse) {
        Cookies.set(accessTokenKey, data.access_token, { expires: add(new Date(), { seconds: data.expires_in }) });
        Cookies.set(refreshTokenKey, data.refresh_token, { expires: add(new Date(), { seconds: data.refresh_expires_in }) });

        this.isAuthenticated = true;

        setTimeout(() => {
            this.isAuthenticated = false;
        }, data.refresh_expires_in * 1000);
    }

    public applyProfile(profile: Profile) {
        runInAction(() => {
            this.profile = profile;
        });
    }

    public extractProfileFromToken(token: string): Profile {
        const profile = JWTDecoder<Profile>(token);
        return profile;
    }

    public async signOut() {
        Cookies.remove(accessTokenKey);
        Cookies.remove(refreshTokenKey);
        runInAction(() => {
            this.isAuthenticated = false;
        });
    }
}
