import { StaticPage, StaticPageFile, StaticPageModel } from '@models/static-page-model';
import { APIUtilError } from '@utils/api';
import { makeAutoObservable, runInAction } from 'mobx';
import cyrillicToTranslit from 'cyrillic-to-translit-js';

export class StaticPageEditViewModel {
    private staticPageModel = new StaticPageModel();
    public loading: boolean = false;
    public host: string = `${process.env.REACT_APP_HOST || 'app_url'}/pages`;
    public staticPage = new StaticPage();
    public isPublished = false;
    public files: StaticPageFile[] = [];

    constructor(public staticPageId: string | undefined, private showError: (description: string) => void, private onFinish: (forceReload: boolean) => void) {
        this.isPublished = staticPageId !== undefined;
        makeAutoObservable(this);
        this.wakeUpSir();
    }

    private async wakeUpSir() {
        if (!this.staticPageId) return;
        const page = await this.fetchStaticPage(this.staticPageId);

        if (page) {
            runInAction(() => {
                this.staticPage = page;
            });
            await this.loadFiles(page?.id);
        }
    }

    public generateAltName = (name: string) => {
        return cyrillicToTranslit().transform(name, '_').toLocaleLowerCase();
    };

    public async fetchStaticPage(staticPageId: string): Promise<StaticPage | undefined> {
        runInAction(() => {
            this.loading = true;
        });
        try {
            const result = await this.staticPageModel.getStaticPageById(staticPageId);
            return result.data;
        } catch (error) {
            this.showError((error as APIUtilError).localizedDescription);
            return undefined;
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    public onSavePage = async (page: StaticPage) => {
        if (!this.staticPageId) {
            await this.createPage(page);
        } else {
            await this.savePage(page);
        }
    };

    private createPage = async (page: StaticPage) => {
        runInAction(() => {
            this.loading = true;
        });
        try {
            const created = await this.staticPageModel.createStaticPage(page);
            this.staticPage = created.data;
        } catch (error) {
            this.showError((error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    };

    private savePage = async (page: StaticPage) => {
        runInAction(() => {
            this.loading = true;
        });
        try {
            await this.staticPageModel.updateStaticPage(page);
            this.onFinish(true);
        } catch (error) {
            this.showError((error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    };

    private async loadFiles(pageId: number | undefined) {
        if (!pageId) return;

        try {
            const files = await this.staticPageModel.getFiles(pageId);
            runInAction(() => {
                this.files = files.data;
            });
        } catch (error) {
            // this.showError((error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    public getUploaderURL() {
        if (!this.staticPage.id) return '';
        return this.staticPageModel.uploadURL(this.staticPage.id);
    }

    public getUploaderHeaders() {
        return this.staticPageModel.uploadHeaders;
    }

    public getDownloadUrl(uuid: string): string | undefined {
        if (!this.staticPage.id) return undefined;
        return this.staticPageModel.getDownloarFileUrl(this.staticPage.id, uuid);
    }

    public async deleteFile(uuid: string): Promise<boolean> {
        if (!this.staticPage.id) return false;

        try {
            await this.staticPageModel.deleteFile(this.staticPage.id, uuid);
            return true;
        } catch (error) {
            // this.showError((error as APIUtilError).localizedDescription);
            return false;
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
