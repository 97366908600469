import { StaticPage, StaticPageModel } from '@models/static-page-model';
import { APIUtilError } from '@utils/api';
import { makeAutoObservable, runInAction } from 'mobx';

export interface StaticPageViewModelStartParams {
    t: any;
    showError: (title: string, description: string) => void;
}

export class StaticPageViewModel {
    private staticPageModel = new StaticPageModel();
    public loading: boolean = false;

    public currentPage: number = 1;
    public pageSize: number = 50;

    public spaticPagesTotal: number = 0;
    public staticPages: StaticPage[] = [];

    constructor(private startParams: StaticPageViewModelStartParams) {
        makeAutoObservable(this);
        this.loadStaticPages(0, this.pageSize);
    }

    private async loadStaticPages(page: number, pageSize: number) {
        runInAction(() => {
            this.loading = true;
        });
        try {
            const result = await this.staticPageModel.getPages({
                count: pageSize,
                offset: page * pageSize
            });
            runInAction(() => {
                this.staticPages = result.data;
                this.spaticPagesTotal = result.total;
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    public delete = async (staticPage: StaticPage) => {
        if (!staticPage.id) return;
        runInAction(() => {
            this.loading = true;
        });
        try {
            await this.staticPageModel.deleteStaticPage(staticPage.id);
            const updatedStaticPages = this.staticPages.filter((item) => item.id !== staticPage.id);
            this.staticPages = updatedStaticPages;
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.empty'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    };

    public onChangePagination = async (page: number, pageSize: number) => {
        this.currentPage = page;
        this.pageSize = pageSize;
        this.loadStaticPages(page - 1, pageSize);
    };
}
