import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import '@assets/css/App.css';
import { Button, Col, Divider, Drawer, Form, Input, notification, Row, Space, Spin, Tag, Typography } from 'antd';
import { useViewModel } from '@models/model';
import { useTranslation } from 'react-i18next';

import { plainToInstance } from 'class-transformer';
import { useNavigate, useParams } from 'react-router-dom';
import { EmailTemplateData } from '@models/email-template-model';
import { EmailTemplateEditViewModel } from './email-template.edit.view.model';
import { EditOutlined } from '@ant-design/icons';
import FilesCatalogView from '@components/UI/files-catalog/catalog.view';
import { stringToDate } from '@utils/date.utils';

import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/snippets/html';
import 'ace-builds/src-min-noconflict/ext-searchbox';
import 'ace-builds/src-min-noconflict/ext-language_tools';


const EmailTemplateEditView: React.FC = observer(() => {
    const { t } = useTranslation();
    const { id: templateId } = useParams();
    const navigate = useNavigate();
    const [isFilesVisible, setIsFilesVisible] = useState(false);

    const [notifyer, contextHolder] = notification.useNotification();
    const showSuccessNotification = (title: string, description: string) => {
        notifyer.success({
            message: title,
            description: description
        });
    };
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    const onClickCancel = () => {
        navigate('/email-templates');
    };

    const viewModel = useViewModel(
        () =>
            new EmailTemplateEditViewModel({
                t,
                templateId,
                showSuccess: showSuccessNotification,
                showError: showErrorNotification,
                close: onClickCancel
            })
    );

    const [form] = Form.useForm<EmailTemplateData>();

    useEffect(() => {
        form.resetFields();
    }, [viewModel.template]);

    const onClickSave = () => form.submit();

    const getFormData = () => {
        const valFromFields: Record<string, any> = form.getFieldsValue();
        var converted: EmailTemplateData = plainToInstance(EmailTemplateData, { ...viewModel.template, ...valFromFields });
        return converted;
    };

    const onSuccessFormValidation = async () => {
        const data = getFormData();
        await viewModel.onSave(data);
    };

    const onCloseFiles = () => {
        setIsFilesVisible(false);
        viewModel.updateFiles();
    };

    return (
        <div>
            <br />
            <Spin spinning={viewModel.isLoading}>
                <div>
                    {contextHolder}
                    <Row gutter={12} justify="end" align="middle">
                        <Col>
                            <Button onClick={onClickCancel}>
                                {t('common.cancel')}
                            </Button>
                        </Col>
                        <Col>
                            <Button type="primary" onClick={onClickSave} loading={viewModel.isLoading} disabled={viewModel.isLoading}>
                                {t('common.save')}
                            </Button>
                        </Col>
                    </Row>
                    <br />

                    <Form form={form} initialValues={viewModel.template} layout="horizontal" onFinish={onSuccessFormValidation}>
                        <Divider orientation="left">{t('emailTemplate.content.common')}</Divider>
                        <div className="edit-content-view">
                            <Form.Item>
                                <Typography.Text>ID: </Typography.Text>
                                <Typography.Text strong copyable>
                                    {viewModel.template.id || ''}
                                </Typography.Text>
                            </Form.Item>
                            <Form.Item
                                name="name"
                                label={t('emailTemplate.content.name.title')}
                                rules={[{ required: true, message: t('emailTemplate.content.required') || '' }]}
                            >
                                <Input />
                            </Form.Item>
                        </div>

                        <br />
                        <Divider orientation="left">{t('emailTemplate.content.content.title')}</Divider>

                        <div className="edit-content-view">
                            <Typography.Text strong>{t('emailTemplate.content.variables')}</Typography.Text>
                            <br />
                            <br />
                            <Space size={[10, 18]} wrap>
                                {Object.keys(viewModel.template?.keys || {}).map((item) => {
                                    return (
                                        <div>
                                            <Typography.Text>{(viewModel.template?.keys || {})[item] || '-'}</Typography.Text>
                                            <Typography.Text> - </Typography.Text>
                                            <Tag>
                                                <Typography.Text copyable>{`%%${item}%%`}</Typography.Text>
                                            </Tag>
                                        </div>
                                    );
                                })}
                            </Space>
                        </div>
                        <br />

                        <div className="edit-content-view">
                            <Space>
                                <Typography.Text strong>{t('emailTemplate.content.files.title')}</Typography.Text>
                                <Button onClick={() => setIsFilesVisible(true)}>
                                    <EditOutlined />
                                </Button>
                            </Space>

                            <br />
                            <br />
                            <Spin spinning={viewModel.isFilesLoading}>
                                <Space size={[10, 18]} wrap>
                                    {viewModel.files.map((file) => {
                                        return (
                                            <Tag style={{ paddingTop: 6, paddingBottom: 6 }}>
                                                <Typography.Text>{file.name || '-'}</Typography.Text>
                                                <Typography.Text> - </Typography.Text>
                                                <Typography.Text copyable>{file.key}</Typography.Text>
                                            </Tag>
                                        );
                                    })}
                                </Space>
                            </Spin>
                        </div>
                        <br />

                        <div>
                            <Form.Item name="content">
                                <AceEditor
                                    style={{ borderRadius: '10px', height: '800px' }}
                                    setOptions={{
                                        showPrintMargin: false,
                                        useWorker: false,
                                        enableBasicAutocompletion: true,
                                        enableLiveAutocompletion: true,
                                        enableSnippets: true,
                                        showLineNumbers: true,

                                        tabSize: 2
                                    }}
                                    width="100%"
                                    name="html_editor"
                                    mode="html"
                                    theme="monokai"
                                    editorProps={{ $blockScrolling: true }}
                                />
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </Spin>

            <Drawer
                title={t('emailTemplate.content.files.title')}
                width={800}
                onClose={onCloseFiles}
                open={isFilesVisible}
                bodyStyle={{ paddingBottom: 80 }}
                // extra={
                //     <Space>
                //         <Button onClick={onClickSave} type="primary">
                //             {t('common.save')}
                //         </Button>
                //     </Space>
                // }
            >
                <FilesCatalogView
                    name="file"
                    uploadURL={viewModel.getUploaderURL()}
                    getHeaders={viewModel.getUploaderHeaders()}
                    downloadLink={(uid: string) => viewModel.getDownloadUrl(uid)}
                    onRemove={(uid: string) => viewModel.deleteFile(uid)}
                    // onEditTitle={(uid: string, title: string) => viewModel.onEditFileTitle(uid, title)}
                    defaultFileList={(viewModel.files || []).map((item) => {
                        return {
                            uid: `${item.uuid}`,
                            name: item.name,
                            title: item.title,
                            size: item.size,
                            lastModifiedDate: stringToDate(item.created_at),
                            downloads: item.downloads
                        };
                    })}
                />
            </Drawer>
        </div>
    );
});

export default EmailTemplateEditView;
