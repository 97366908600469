import { ResultsGetFilter } from '@interfaces/result.interface';
import { ResultTypeDictionaryData } from '@models/dictionary-model';
import { ResultData, ResultModel } from '@models/result-model';
import { APIUtilError } from '@utils/api';
import { deleteUndefined } from '@utils/object.utils';
import { Button, Drawer, Empty, Form, Select, Space, Tag, Typography, notification } from 'antd';
import { keys } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    visible: boolean;
    onClose: () => void;
    onSaveResult: (result: ResultData) => void;
    onSaveResultType: (resultType: ResultTypeDictionaryData) => void;
    resultTypes: ResultTypeDictionaryData[];
    result?: ResultData;
    resultTypeId?: number;
}

const ResultSelector: React.FC<Props> = ({ visible, onClose, onSaveResult, onSaveResultType, resultTypes, result, resultTypeId }) => {
    const { t } = useTranslation();
    const [notifyer, contextHolder] = notification.useNotification();
    const resultsModel = new ResultModel();
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedResultTypeId, setSelectedResultTypeId] = useState<number | null>(null);

    const [results, setResults] = useState<ResultData[]>([]);
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);

    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    useEffect(() => {
        form.resetFields();

        if (result && result.id && resultTypeId) {
            setResults([result]);
            form.setFieldValue('result_id', result.id);
            // setSelectedResult(result.id);
            const resultType = resultTypes.find((item) => item.id === resultTypeId);
            if (resultType) {
                setSelectedResultTypeId(resultType.id);
                form.setFieldValue('result_type_id', resultType.id);
            } else {
                setSelectedResultTypeId(null);
            }
        } else {
            setIsSaveEnabled(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result, resultTypeId]);

    const searchResults = async (query: string) => {
        setIsLoading(true);
        let params: ResultsGetFilter = {
            result_type_id: selectedResultTypeId ? [selectedResultTypeId] : undefined,
            search: query,
            page_size: 10
        };
        try {
            const results = await resultsModel.getResults(deleteUndefined(params));
            setResults(results.data);
        } catch (error) {
            showErrorNotification(t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSave = () => {
        const value = form.getFieldValue('result_id');
        if (!value) return;
        const result = results.find((item) => item.id === value);
        if (!result) return;

        const typeResult = resultTypes.find((item) => item.id === result.result_type_id);
        if (!typeResult) return;

        onSaveResult(result);
        onSaveResultType(typeResult);
        onClose();
    };

    const onFormValueChange = (changed: any) => {
        if (keys(changed).includes('result_type_id')) {
            setResults([]);
            setSelectedResultTypeId(changed.result_type_id);
            form.resetFields(['result_id']);
        }
        if (keys(changed).includes('result_id')) {
            if (changed.result_id) {
                setIsSaveEnabled(true);
            } else {
                setResults([]);
                setIsSaveEnabled(false);
            }
        }
    };

    return (
        <Drawer
            forceRender
            title={t('common.edit')}
            width={700}
            onClose={onClose}
            open={visible}
            bodyStyle={{ paddingBottom: 80 }}
            extra={
                <Space>
                    <Button onClick={handleSave} type="primary" disabled={!isSaveEnabled}>
                        {t('common.save')}
                    </Button>
                </Space>
            }
        >
            {contextHolder}
            <Form layout="vertical" form={form} requiredMark={true} onValuesChange={onFormValueChange}>
                <Form.Item name="result_type_id" label={t('events.content.result_type.title')} tooltip={t('events.content.result_type.hint')}>
                    <Select
                        style={{ width: '100%' }}
                        value={selectedResultTypeId}
                        placeholder={t('events.content.result_type.empty')}
                        allowClear
                        // onChange={onChangeResultType}
                    >
                        {resultTypes.map((item, i) => (
                            <Select.Option value={item.id} key={`org_${i}`}>
                                <Tag style={{ color: 'black' }} color={item.primary_color ? item.primary_color : undefined}>
                                    {item.title}
                                </Tag>
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="result_id" label={t('events.content.result.title')}>
                    <Select
                        // value={selectedResult}
                        // onSelect={(value) => setSelectedResult(value)}
                        // onClear={() => setSelectedResult(null)}
                        style={{ width: '100%' }}
                        showSearch
                        allowClear
                        placeholder={t('events.content.result.placeholder')}
                        onSearch={(value: string) => searchResults(value)}
                        loading={isLoading}
                        filterOption={false}
                        notFoundContent={<Empty description={''} />}
                    >
                        {results.map((item, i) => (
                            <Select.Option value={item.id} key={`org_${i}`}>
                                <Space direction="vertical">
                                    <Typography.Text>{item.title}</Typography.Text>
                                </Space>
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default ResultSelector;
