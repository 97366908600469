import { observer } from 'mobx-react-lite';
import React from 'react';
import { Divider, Typography, notification, Space } from 'antd';
import { EditableTable } from './table';
import EditOperator from './operator.edit.view';
import { useViewModel } from '@models/model';
import { useTranslation } from 'react-i18next';
import { Operator } from '@models/operator-model';
import { OperatorsViewModel } from './operators.view.model';
import { useNavigate, useParams } from 'react-router-dom';

const OperatorsView: React.FC = observer(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id: operatorId } = useParams();

    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    const viewModel = useViewModel(
        () =>
            new OperatorsViewModel({
                t,
                operatorId,
                showError: showErrorNotification
            })
    );

    const onEdit = (operatorId?: number | undefined) => {
        if (!operatorId) return;
        navigate(`${operatorId || ''}`);
        viewModel.onEdit(operatorId);
    };

    const onCloseConstructor = () => {
        navigate('/operators-manage');
        viewModel.onCancelEdit();
    };

    return (
        <div>
            {contextHolder}
            <Typography.Title>{t('operators.title')}</Typography.Title>
            <Divider />
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <EditableTable
                    loading={viewModel.isLoading}
                    rows={viewModel.operators}
                    total={viewModel.operatorsTotal}
                    onEdit={onEdit}
                />
            </Space>
            {viewModel.operatorOnEdit && (
                <EditOperator
                    visible={!!viewModel.operatorOnEdit}
                    operator={viewModel.operatorOnEdit}
                    onClose={onCloseConstructor}
                    onSave={(operator: Operator) => viewModel.update(operator)}
                />
            )}
        </div>
    );
});

export default OperatorsView;
