import { APIInstance } from '@utils/api';
import { deleteUndefined } from '@utils/object.utils';
import { GetOperatorsResponse, Operator, OperatorResponse, GetOperatorErrors } from './operator.contract';

export class OperatorModel {
    public async getOperators(): Promise<GetOperatorsResponse> {
        try {
            const result = await APIInstance.get<GetOperatorsResponse>('/cp-manager/operator');
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getOperatorById(id: string): Promise<OperatorResponse> {
        try {
            const result = await APIInstance.get<OperatorResponse>(`/cp-manager/operator/${id}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async update(operator: Operator): Promise<OperatorResponse> {
        try {
            const result = await APIInstance.put<OperatorResponse>(`/cp-manager/operator/${operator.id}`, { ...operator });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async fetchErrors(api_id: number, limit?: number, offset?: number, solved_filter?: boolean): Promise<GetOperatorErrors> {
        try {
            const result = await APIInstance.get<GetOperatorErrors>('/cp-manager/airflow/api-errors', {
                params: deleteUndefined({ api_id, limit, offset, solved_filter })
            });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async setSolvedErrors(solvedIds: number[], api_id: number, solve?: boolean) {
        try {
            await APIInstance.post('/cp-manager/airflow/api-errors/solve', { solved_ids: solvedIds, solve, api_id });
        } catch (error) {
            throw error;
        }
    }

    public async deleteErrors(api_id: number) {
        try {
            await APIInstance.delete('/cp-manager/airflow/api-errors', { params: { api_id } });
        } catch (error) {
            throw error;
        }
    }
}
