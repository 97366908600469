import { Operator, OperatorModel, OperatorError } from '@models/operator-model';
import { APIUtilError, isAPIError } from '@utils/api';
import { makeAutoObservable, runInAction } from 'mobx';

export interface OperatorsViewModelStartParams {
    t: any;
    operatorId?: string | undefined;
    showError: (title: string, description: string) => void;
    fetchOnlyErrors?: boolean | undefined;
    apiId?: number;
}

export class OperatorsViewModel {
    private operatorModel = new OperatorModel();
    public isLoading: boolean = false;

    public operatorsTotal: number = 0;
    public operators: Operator[] = [];
    public operatorOnEdit?: Operator;

    public apiErrors: OperatorError[] = [];
    public isLoadingErrors: boolean = false;
    public errorPageSize: number = 10;
    public errorPageTotal: number = 0;
    public activeErrorsApiId: number = 0;
    public solvedErrors: number[] = [];

    constructor(private startParams: OperatorsViewModelStartParams) {
        makeAutoObservable(this);

        if (startParams.fetchOnlyErrors && startParams.apiId) {
            this.fetchErrors(startParams.apiId, this.errorPageSize, 0);
        } else {
            this.fetchOperators();
            if (startParams.operatorId) this.setOperatorEdit(startParams.operatorId);
        }
    }

    private async fetchOperators() {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.operatorModel.getOperators();
            runInAction(() => {
                this.operators = result.data;
                this.operatorsTotal = result.total;
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public onEdit = (id: number) => {
        this.setOperatorEdit(`${id}`);
    };

    public onCancelEdit = () => {
        runInAction(() => {
            this.operatorOnEdit = undefined;
        });
    };

    private async setOperatorEdit(id: string) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.operatorModel.getOperatorById(id);
            runInAction(() => {
                this.operatorOnEdit = result.data;
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public async update(operator: Operator): Promise<string | undefined> {
        runInAction(() => {
            this.isLoading = true;
        });

        try {
            await this.operatorModel.update(operator);
            let copy = [...this.operators];
            const index = copy.map((item) => item.id).indexOf(operator.id);
            copy[index] = operator;
            this.operators = copy;
        } catch (error) {
            return isAPIError(error) ? error.localizedDescription : 'Unknown Error';
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public async fetchErrors(apiId: number, limit?: number, offset?: number, solved_filter?: boolean) {
        runInAction(() => {
            this.isLoadingErrors = true;
        });
        try {
            const result = await this.operatorModel.fetchErrors(apiId, limit, offset, solved_filter);
            runInAction(() => {
                this.apiErrors = result.data;
                this.errorPageTotal = result.total;
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoadingErrors = false;
            });
        }
    }

    public onChangeErrorPage = async (page: number, pageSize: number) => {
        if (pageSize !== this.errorPageSize) {
            runInAction(() => {
                this.errorPageSize = pageSize;
            });
        }
        this.fetchErrors(this.activeErrorsApiId, pageSize, (page - 1) * pageSize);
    };

    public setSolvedErrors = async (solve?: boolean) => {
        try {
            await this.operatorModel.setSolvedErrors(this.solvedErrors, this.activeErrorsApiId, solve);

            let copy = [...this.operators];
            const index = copy.map((item) => item.api_id).indexOf(this.activeErrorsApiId);
            if (solve) {
                copy[index].count_errors_not_solved = 0;
                copy[index].count_errors_solved = this.errorPageTotal;
            } else {
                copy[index].count_errors_not_solved = this.errorPageTotal;
                copy[index].count_errors_solved = 0;
            }
            this.operators = copy;
        } catch (error) {
            this.startParams.showError(
                this.startParams.t('operators.content.errors.table.failed_solve'),
                (error as APIUtilError).localizedDescription
            );
        }
        this.solvedErrors = [];
    };

    public deleteErrors = async (apiId: number) => {
        this.isLoadingErrors = true;
        try {
            await this.operatorModel.deleteErrors(apiId);
            await this.fetchOperators();
        } catch (error) {
            this.startParams.showError(
                this.startParams.t('operators.content.errors.table.failed_solve'),
                (error as APIUtilError).localizedDescription
            );
        }
        this.isLoadingErrors = false;
    };
}
