import { useState } from 'react';
import { Table, Form, Tooltip, Button } from 'antd';
import { useEffect } from 'react';
import { User } from '@models/users-model';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

interface TableProps {
    currentPage: number;
    pageSize: number;
    rows: User[];
    total: number;
    loading: boolean;
    onChangePagination: (page: number, pageSize: number) => void;
    onEdit: (item: User) => void;
}

export const UsersTable = (props: TableProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [data, setData] = useState<User[]>([]);

    useEffect(() => {
        setData(props.rows);
    }, [props]);

    const onChangePage = (page: number, pageSize: number) => {
        props.onChangePagination(page, pageSize);
    };
    const columns = [
        {
            title: 'E-mail',
            dataIndex: 'email'
        },
        {
            title: t('users.columns.name'),
            dataIndex: 'name'
        },
        {
            title: t('users.columns.role'),
            dataIndex: 'role_id',
            render: (_: any, record: User) => {
                return t(`users.roles.${record.role_id}`);
            }
        },
        {
            width: '5%',
            dataIndex: 'operations',
            render: (_: any, record: User) => {
                return (
                    <Tooltip title={t('common.edit')}>
                        <Button onClick={() => props.onEdit(record)}>
                            <EditOutlined />
                        </Button>
                    </Tooltip>
                );
            }
        }
    ];

    return (
        <Form form={form} component={false}>
            <Table
                size="small"
                loading={props.loading}
                bordered
                dataSource={data}
                columns={columns}
                rowClassName="row"
                rowKey={'id'}
                pagination={{
                    current: props.currentPage,
                    pageSize: props.pageSize,
                    total: props.total,
                    position: ['bottomCenter'],
                    onChange: onChangePage
                }}
            />
        </Form>
    );
};
