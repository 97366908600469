import { DictionaryModel, OrganizationDictionaryData } from '@models/dictionary-model';
import { UserModel, UserHistoryLoggerData, User, UserInst } from '@models/users-model';
import { APIUtilError } from '@utils/api';
import { ObjectLiteral } from '@utils/object.utils';
import { makeAutoObservable, runInAction } from 'mobx';

export interface UserEdtiViewModelStartParams {
    t: any;
    userId: string | undefined;
    showError: (title: string, description: string) => void;
    showSuccess: (title: string, description: string) => void;
    onClose: () => void;
}
export class UserEditViewModel {
    private dictionaryModel = new DictionaryModel();
    private userModel = new UserModel();

    public isLoading: boolean = false;
    public user: User = new User();

    public isInstitutionLoading: boolean = false;
    public institutions: OrganizationDictionaryData[] = [];
    public institution: UserInst | null = null;

    public isHistoryLoading: boolean = false;
    public historyItems: UserHistoryLoggerData[] = [];
    public historyTotal: number = 0;
    public historyPageSize = 4;
    public historyCurrentItem?: UserHistoryLoggerData | undefined;

    constructor(private startParams: UserEdtiViewModelStartParams) {
        makeAutoObservable(this);
        this.wakeUpSir();
    }

    private async wakeUpSir() {
        if (this.startParams.userId && this.startParams.userId.length > 0) {
            await this.fetchUser(this.startParams.userId);
            await this.fetchHistoryItems(this.startParams.userId, 1, this.historyPageSize);
        } else {
            this.startParams.showError(this.startParams.t('common.error.fetch'), '');
        }
    }

    private async fetchUser(userId: string) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.userModel.getUserById(userId);
            runInAction(() => {
                this.user = result.data;
            });
            if (this.user.id) {
                await this.fetchUserInstitution(this.user.id);
            }
        } catch (error) {
            this.startParams.showError(`${this.startParams.t('common.error.fetch')}: ${error}`, (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    private async fetchUserInstitution(userId: string) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.userModel.getInst(userId);
            if (result.data?.id) {
                runInAction(() => {
                    this.institution = result.data;
                    this.institutions = [result.data];
                });
            }
        } catch (error) {
            this.startParams.showError(`${this.startParams.t('users.content.inst.error')}`, (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public async searchInstitutions(query: string) {
        runInAction(() => {
            this.isInstitutionLoading = true;
        });
        try {
            const result = await this.dictionaryModel.getDictionary({ altname: 'institution', search: query });
            runInAction(() => {
                this.institutions = result.data as OrganizationDictionaryData[];
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isInstitutionLoading = false;
            });
        }
    }

    public onSave = async (data: ObjectLiteral) => {
        if (!this.user?.id) return;
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            await this.userModel.updateUser(this.user.id, data);
            if (data['institution_id'] && data['institution_id'] !== this.institution?.id) {
                await this.userModel.bindInst(this.user.id, data['institution_id']);
            }
            this.startParams.showSuccess(this.startParams.t('common.saved'), '');
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.save'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };

    public resetUserPassword = async () => {
        if (!this.user.id) {
            return;
        }
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            await this.userModel.resetUserPassword(this.user.id);
            this.startParams.showSuccess(this.startParams.t('users.content.reset_pwd.success_text'), '');
        } catch (error) {
            this.startParams.showError(this.startParams.t('users.content.reset_pwd.error_text'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };

    public deleteUser = async () => {
        if (!this.user.id) {
            return;
        }
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            await this.userModel.deleteUser(this.user.id);
            this.startParams.showSuccess(this.startParams.t('users.content.delete.success_text'), '');
            this.startParams.onClose();
        } catch (error) {
            this.startParams.showError(this.startParams.t('users.content.delete.error_text'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };

    public toggleUserActiveStatus = async () => {
        if (!this.user.id) {
            return;
        }
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            await this.userModel.updateUser(this.user.id, { is_active: !this.user.is_active });

            this.startParams.showSuccess(
                this.startParams.t(!this.user.is_active ? 'users.content.active.unlocked' : 'users.content.active.locked'),
                ''
            );
            runInAction(() => {
                this.user.is_active = !this.user.is_active;
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.save'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };

    // HISTORY

    public async fetchNextHistoryItemsPage() {
        if (!this.startParams.userId) return;
        await this.fetchHistoryItems(this.startParams.userId, 1, this.historyPageSize + 1);
    }

    private async fetchHistoryItems(user_id: string, page: number, pageSize: number) {
        runInAction(() => {
            this.isHistoryLoading = true;
        });
        try {
            const result = await this.userModel.getHistoryItems(user_id, page, pageSize);
            runInAction(() => {
                this.historyItems = result.data;
                this.historyTotal = result.total;
                this.historyPageSize = pageSize;
            });
        } catch (error) {
            this.startParams.showError(`${this.startParams.t('common.error.fetch')}: ${error}`, (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isHistoryLoading = false;
            });
        }
    }

    public async onSelectHistoryItem(historyId: number) {
        if (!this.startParams.userId) return;
        await this.fetchHistoryItem(this.startParams.userId, historyId);
    }

    private async fetchHistoryItem(userId: string, historyId: number) {
        runInAction(() => {
            this.isHistoryLoading = true;
        });
        try {
            const result = await this.userModel.getHistoryItem(userId, historyId);
            runInAction(() => {
                this.historyCurrentItem = result.data;
            });
        } catch (error) {
            this.startParams.showError(`${this.startParams.t('common.error.fetch')}: ${error}`, (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isHistoryLoading = false;
            });
        }
    }
}
