export class ResultTypeData {
    id: number;
    title: string = '';
    description: string = '';
    alt_title: string = '';
    dashboard_link: string | undefined = '';
    filter_isset: boolean | undefined = false;
    filter_by_subject: boolean | undefined = false;
    items_isset: boolean | undefined = false;
    filter_by_org: boolean | undefined = false;
    primary_color: string = '#EEEEEE';

    member_count?: number | undefined = 0;
    startup_count?: number | undefined = 0;
    rid_count?: number | undefined = 0;
    event_count?: number | undefined = 0;
    finance_count?: number | undefined = 0;
    result_count?: number | undefined = 0;

    created_at: string;
    updated_at: string;
}

export class GetResultTypesResponse {
    data: ResultTypeData[];
}

export class ResultTypeResponse {
    data: ResultTypeData;
}
