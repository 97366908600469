import { useViewModel } from '@models/model';
import { observer } from 'mobx-react-lite';
import { ResourceEditViewModel } from './resource.edit.view.model';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Form, Input, Row, Spin, Typography, notification } from 'antd';
import { useEffect, useState } from 'react';
import { ObjectLiteral, deleteUndefined } from '@utils/object.utils';

const PopularizationResourceEditView: React.FC = observer(() => {
    const { t } = useTranslation();
    const { id: editId } = useParams();
    const navigate = useNavigate();
    const [changedFields, setChangedFields] = useState<ObjectLiteral>({});
    const onClickCancel = () => {
        navigate('/popularization-resource');
    };
    const [form] = Form.useForm();

    const [notifyer, contextHolder] = notification.useNotification();
    const showSuccessNotification = (title: string, description: string) => {
        notifyer.success({
            message: title,
            description: description
        });
    };
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    const viewModel = useViewModel(
        () =>
            new ResourceEditViewModel({
                t,
                editId,
                showSuccess: showSuccessNotification,
                showError: showErrorNotification
            })
    );

    useEffect(() => {
        form.resetFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewModel.resource]);

    const onFormValueChange = (changed: any) => {
        setChangedFields({ ...changedFields, ...changed });
    };

    const getFormData = () => {
        return deleteUndefined({
            id: viewModel.resource?.id,
            ...changedFields
        });
    };

    const onSuccessFormValidation = async () => {
        let data: any = getFormData();
        for (const key in data) {
            if (typeof data[key] == 'undefined') {
                data[key] = null;
            }
        }
        await viewModel.onSave(data);
    };

    const onErrorFormValidation = (errorsInfo: any) => {
        showErrorNotification(t('common.error.empty'), t('common.check_fields'));
    };

    return (
        <div>
            {contextHolder}
            <br />
            <Row gutter={12} justify="end" align="middle">
                <Col>
                    <Button onClick={onClickCancel}>{t('common.cancel')}</Button>
                </Col>
                <Col>
                    <Button type="primary" onClick={() => form.submit()} loading={viewModel.isLoading} disabled={viewModel.isLoading}>
                        {viewModel.isPublished ? t('common.save') : t('static_page.publish')}
                    </Button>
                </Col>
            </Row>
            <br />
            <Spin spinning={viewModel.isLoading}>
                <Form
                    form={form}
                    initialValues={viewModel.resource}
                    layout="horizontal"
                    onValuesChange={onFormValueChange}
                    onFinish={onSuccessFormValidation}
                    onFinishFailed={onErrorFormValidation}
                >
                    <div className="edit-content-view">
                        {viewModel.isPublished && (
                            <Form.Item>
                                <Typography.Text>ID: </Typography.Text>
                                <Typography.Text strong copyable>
                                    {viewModel.resource.id}
                                </Typography.Text>
                            </Form.Item>
                        )}

                        <Form.Item
                            name="title"
                            label={t('popularization.resources.content.title')}
                            rules={[{ required: true, message: t('common.required') || '' }]}
                        >
                            <Input placeholder={t('popularization.resources.content.title') || ''} />
                        </Form.Item>

                        <Form.Item name="desc" label={t('popularization.resources.content.description')}>
                            <Input placeholder={t('popularization.resources.content.description') || ''} />
                        </Form.Item>

                        <Row gutter={12} align="middle">
                            <Col span={viewModel.isPublished ? 18 : 24}>
                                <Form.Item
                                    name="url"
                                    label={t('popularization.resources.content.url')}
                                    rules={[{ required: true, message: t('common.required') || '' }]}
                                >
                                    <Input placeholder={t('popularization.resources.content.url') || ''} />
                                </Form.Item>
                            </Col>
                            {viewModel.isPublished && (
                                <Col span={6}>
                                    <Form.Item name="url_click_count" label={t('popularization.resources.content.clicks_count')} rules={[]}>
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                    </div>
                </Form>
            </Spin>
        </div>
    );
});

export default PopularizationResourceEditView;
