import { Alert, Button, Card, Col, Divider, Drawer, Form, Input, Row, Space, Typography } from 'antd';
import '@assets/css/App.css';
import { plainToInstance } from 'class-transformer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Operator } from '@models/operator-model';

interface Props {
    visible: boolean;
    operator: Operator;
    onClose: () => void;
    onSave: (operator: Operator) => void;
}

const EditOperator: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errorText, setErrorText] = useState<string | null>(null);

    useEffect(() => {
        form.resetFields();
    }, [props.operator]);

    const onClose = async () => {
        props.onClose();
    };

    const onSave = async () => {
        try {
            const valFromFields: Record<string, any> = form.getFieldsValue();

            var converted: Operator = plainToInstance(Operator, { id: props.operator?.id, ...valFromFields });
            await save(converted);
        } catch (error) {
            showError(`${t('common.save')}: ${error}`);
        }
    };

    const save = async (operator: Operator) => {
        const result = await props.onSave(operator);
        if (typeof result === 'string') {
            showError(`${t('common.error.save')}: ${result}`);
        } else {
            onClose();
        }
    };

    const showError = (errorText: string) => {
        setErrorText(errorText);
        setTimeout(() => {
            setErrorText(null);
        }, 4000);
    };

    return (
        <Drawer
            forceRender
            title={t('operators.edit.title')}
            width={480}
            onClose={onClose}
            open={props.visible}
            bodyStyle={{ paddingBottom: 80 }}
            extra={
                <Space>
                    <Button onClick={onSave} type="primary">
                        {t('common.save')}
                    </Button>
                </Space>
            }
        >
            {errorText && (
                <Alert
                    style={{
                        marginBottom: 10
                    }}
                    message={errorText}
                    type="error"
                    showIcon
                />
            )}

            <Card title="id" size="small">
                <Typography.Text copyable>{props.operator.id}</Typography.Text>
            </Card>

            <Divider />
            <Form layout="vertical" form={form} initialValues={props.operator} requiredMark={true}>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item name="name" label={t('operators.content.name.title')} rules={[{ required: true, message: t('operators.content.name.required') || '' }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="site" label={t('operators.content.site.title')} rules={[{ required: true, message: t('operators.content.site.required') || '' }]}>
                            <Input placeholder={t('operators.content.site.placeholder') || ''} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    );
};

export default EditOperator;
