import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import '@assets/css/App.css';
import { Button, Col, Collapse, Form, Input, notification, Result, Row, Space, Steps, Switch, Tabs, Tooltip, Typography } from 'antd';
import { useViewModel } from '@models/model';
import { useTranslation } from 'react-i18next';
import {
    CheckCircleOutlined,
    CheckOutlined,
    CloseOutlined,
    CopyOutlined,
    FileTextOutlined,
    PaperClipOutlined,
    RobotOutlined
} from '@ant-design/icons';
import { StaticPage } from '@models/static-page-model';
import { plainToInstance } from 'class-transformer';
import { StaticPageEditViewModel } from './static-page.edit.view.model';
import { EditableValuesTable } from '@components/UI/values-table';
import WYSIWYGEditor from '@components/UI/WYSIWYG/WYSIWYG.editor';
import TabPane from 'antd/es/tabs/TabPane';
import { stringToDate } from '@utils/date.utils';
import { useNavigate, useParams } from 'react-router-dom';
import FilesCatalogView from '@components/UI/files-catalog/catalog.view';

const StaticPageEditView: React.FC = observer(() => {
    const { t, i18n } = useTranslation();
    const { id: staticPageId } = useParams();
    const navigate = useNavigate();

    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (description: string) => {
        notifyer.error({
            message: t('common.error.empty'),
            description: description
        });
    };

    const onFinish = () => {
        onClickCancel();
    };

    const onClickCancel = () => {
        navigate('/static-page-manage');
    };

    const viewModel = useViewModel(
        () => new StaticPageEditViewModel(staticPageId, (description: string) => showErrorNotification(description), onFinish)
    );

    const [creatorCurrentStep, setCreatorCurrentStep] = useState(0);
    const [form] = Form.useForm<StaticPage>();
    const [isAltNameAuto, setIsAltNameAuto] = useState(true);
    const [generatedAltName, setGeneratedAltName] = useState(viewModel.staticPage.alt_name || '');
    const manualAltName = Form.useWatch('alt_name', form);
    const [keywords, setKeywords] = useState<string[]>([]);
    const [tags, setTags] = useState<string[]>([]);

    useEffect(() => {
        form.resetFields();
        setGeneratedAltName(viewModel.staticPage.alt_name);
        setIsAltNameAuto(viewModel.staticPage.alt_name === viewModel.generateAltName(viewModel.staticPage.title));
        setKeywords((viewModel.staticPage.keywords || '').split(','));
        setTags(viewModel.staticPage.tags_list || []);
    }, [viewModel.staticPage]);

    const onChangeTitle = (value: string) => {
        setGeneratedAltName(viewModel.generateAltName(value));
    };

    const onChangeAltNameSwitch = () => {
        setIsAltNameAuto(!isAltNameAuto);
        form.setFieldsValue({ alt_name: generatedAltName });
    };

    const onChangeFullDescription = (content: string) => {
        form.setFieldsValue({ body: content });
    };

    const onClickSave = () => form.submit();

    const getFormData = () => {
        const valFromFields: Record<string, any> = form.getFieldsValue();
        const preparedKeywords = keywords
            .filter((item) => item !== '' && item !== ' ')
            .map((item) => item.replaceAll(' ', '-'))
            .join(',');
        var converted: StaticPage = plainToInstance(StaticPage, {
            ...viewModel.staticPage,
            ...valFromFields,
            keywords: preparedKeywords,
            tags_list: tags
        });

        if (isAltNameAuto) {
            converted.alt_name = generatedAltName;
        }
        return converted;
    };

    const onSuccessFormValidation = async () => {
        const data = getFormData();
        await viewModel.onSavePage(data);

        if (viewModel.isPublished === false && viewModel.staticPage.id !== undefined) {
            setCreatorCurrentStep(creatorCurrentStep + 1);
        }
    };

    const getLink = () => {
        return `${viewModel.host}/${isAltNameAuto ? generatedAltName : manualAltName}`;
    };

    const copyLink = () => {
        navigator.clipboard.writeText(getLink());
    };

    const editPageContent = () => {
        return (
            <div className="edit-content-view">
                <Form
                    form={form}
                    initialValues={viewModel.staticPage}
                    onFinish={onSuccessFormValidation}
                >
                    <Form.Item
                        name="title"
                        label={t('static_page.content.title.title')}
                        rules={[{ required: true, message: t('static_page.content.title.required') || '' }]}
                    >
                        <Input
                            placeholder={t('static_page.content.title.placeholder') || ''}
                            onChange={(event) => onChangeTitle(event.currentTarget.value)}
                        />
                    </Form.Item>
                    <Form.Item
                        name="subtitle"
                        label={t('static_page.content.subtitle.title')}
                        rules={[{ required: true, message: t('static_page.content.subtitle.required') || '' }]}
                    >
                        <Input placeholder={t('static_page.content.subtitle.placeholder') || ''} />
                    </Form.Item>
                    <Row justify="space-between">
                        <Col span={21}>
                            {isAltNameAuto === true ? (
                                <Form.Item label={t('static_page.content.alt_name.title')} required>
                                    <Input disabled value={generatedAltName} />
                                </Form.Item>
                            ) : (
                                <Form.Item
                                    name="alt_name"
                                    label={t('static_page.content.alt_name.title')}
                                    rules={[{ required: true, message: t('static_page.content.alt_name.required') || '' }]}
                                >
                                    <Input placeholder={t('static_page.content.alt_name.placeholder') || ''} />
                                </Form.Item>
                            )}
                        </Col>
                        <Col>
                            <Form.Item label={<RobotOutlined />} tooltip={t('static_page.content.alt_name.auto')}>
                                <Switch checked={isAltNameAuto} onChange={onChangeAltNameSwitch} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[18, 0]} justify="start" align="middle">
                        <Col style={{ alignItems: 'center' }}>
                            <Form.Item
                                name="is_visible"
                                label={t('static_page.content.is_visible.title')}
                                tooltip={t('static_page.content.is_visible.hint')}
                                valuePropName="checked"
                            >
                                <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                label={t('static_page.link.title')}
                                validateStatus={viewModel.isPublished ? '' : 'warning'}
                                help={viewModel.isPublished ? '' : t('static_page.link.not_published')}
                            >
                                <Space direction="horizontal">
                                    <Typography.Link type="secondary" underline href={getLink()} target="_blank" rel="noreferrer">
                                        {getLink()}
                                    </Typography.Link>

                                    <Tooltip title={t('common.copy.link')}>
                                        <Button onClick={copyLink}>
                                            <CopyOutlined />
                                        </Button>
                                    </Tooltip>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>

                    <br />
                    <Form.Item
                        label={t('static_page.content.description.title')}
                        name="description"
                        rules={[{ required: true, message: t('static_page.content.description.required') || '' }]}
                    >
                        <Input placeholder={t('static_page.content.description.placeholder') || ''} />
                    </Form.Item>
                    <br />
                    <Collapse
                        items={[
                            {
                                key: 'keywords',
                                label: t('static_page.content.keywords.title') + ` - ${keywords?.length || 0}`,
                                children: (
                                    <EditableValuesTable
                                        rows={keywords}
                                        onUpdate={(values) => {
                                            const typed = values.map((item) => `${item}`);
                                            setKeywords(typed);
                                        }}
                                        maxRows={10}
                                        defaultNewValue=""
                                        inputRules={{
                                            allowClear: true,
                                            placeholder: t('static_page.content.keywords.placeholder') || '',
                                            required: true,
                                            requiredMessage: t('static_page.content.keywords.required') || '',
                                            maxLength: 30
                                        }}
                                    />
                                )
                            },
                            {
                                key: 'tags',
                                label: t('static_page.content.tags.title') + ` - ${tags?.length || 0}`,
                                children: (
                                    <EditableValuesTable
                                        rows={tags}
                                        onUpdate={(values) => {
                                            const typed = values.map((item) => `${item}`);
                                            setTags(typed);
                                        }}
                                        maxRows={10}
                                        defaultNewValue=""
                                        inputRules={{
                                            allowClear: true,
                                            placeholder: t('static_page.content.tags.placeholder') || '',
                                            required: true,
                                            requiredMessage: t('static_page.content.tags.required') || '',
                                            maxLength: 30
                                        }}
                                    />
                                )
                            }
                        ]}
                    />

                    <br />
                    <Form.Item name="body" rules={[{ required: true, message: t('static_page.content.body.required') || '' }]}>
                        <WYSIWYGEditor
                            editorDefaultValue={viewModel.staticPage.body}
                            placeholder={t('static_page.content.body.placeholder')}
                            languageCode={i18n.language}
                            onChange={onChangeFullDescription}
                        />
                    </Form.Item>
                </Form>
            </div>
        );
    };

    const editFiles = () => {
        return (
            <div>
                <FilesCatalogView
                    name="file"
                    uploadURL={viewModel.getUploaderURL()}
                    getHeaders={viewModel.getUploaderHeaders()}
                    downloadLink={(uid: string) => viewModel.getDownloadUrl(uid)}
                    onRemove={(uid: string) => viewModel.deleteFile(uid)}
                    defaultFileList={(viewModel.files || []).map((item) => {
                        return {
                            uid: `${item.uuid}`,
                            name: item.name,
                            size: item.size,
                            lastModifiedDate: stringToDate(item.created_at),
                            downloads: item.downloads
                        };
                    })}
                />
            </div>
        );
    };

    if (!viewModel.isPublished) {
        return (
            <div>
                <br />
                {contextHolder}
                <Row gutter={12} justify="space-between" align="middle">
                    <Col span={14} style={{ backgroundColor: 'white', padding: '10px 30px 10px 30px', borderRadius: 10 }}>
                        <Steps
                            items={[
                                {
                                    title: t('static_page.content.name'),
                                    icon: <FileTextOutlined />
                                },
                                {
                                    title: t('static_page.content.files.title'),
                                    icon: <PaperClipOutlined />
                                },
                                {
                                    title: t('common.done'),
                                    icon: <CheckCircleOutlined />
                                }
                            ]}
                            current={creatorCurrentStep}
                        />
                    </Col>
                    <Col>
                        <Row gutter={12}>
                            {creatorCurrentStep === 0 && (
                                <Col>
                                    <Button
                                        onClick={() => {
                                            onClickCancel();
                                        }}
                                    >
                                        {creatorCurrentStep === 0 ? t('common.cancel') : t('common.back')}
                                    </Button>
                                </Col>
                            )}

                            <Col>
                                <Button
                                    type="primary"
                                    disabled={creatorCurrentStep === 2 || viewModel.loading}
                                    loading={viewModel.loading}
                                    onClick={() => {
                                        switch (creatorCurrentStep) {
                                            case 0:
                                                onClickSave();
                                                break;
                                            case 1:
                                                setCreatorCurrentStep(creatorCurrentStep + 1);
                                                setTimeout(() => {
                                                    onFinish();
                                                }, 1000);
                                                break;
                                            default:
                                                break;
                                        }
                                    }}
                                >
                                    {t('common.next')}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div style={{ paddingTop: 30 }}></div>
                {creatorCurrentStep === 0 && editPageContent()}
                {creatorCurrentStep === 1 && editFiles()}
                {creatorCurrentStep === 2 && <Result status="success" title={t('common.done')} />}
            </div>
        );
    } else {
        return (
            <div>
                <br />
                {contextHolder}
                <Tabs
                    tabBarExtraContent={{
                        right: (
                            <Row gutter={12} justify="end" align="middle">
                                <Col>
                                    <Button onClick={onClickCancel}>
                                        {t('common.cancel')}
                                    </Button>
                                </Col>
                                <Col>
                                    <Button type="primary" onClick={onClickSave} loading={viewModel.loading} disabled={viewModel.loading}>
                                        {viewModel.isPublished ? t('common.save') : t('static_page.publish')}
                                    </Button>
                                </Col>
                            </Row>
                        )
                    }}
                >
                    <TabPane tab={t('static_page.content.name')} key={'content'}>
                        {editPageContent()}
                    </TabPane>

                    <TabPane tab={t('static_page.content.files.title')} key={'files'}>
                        {editFiles()}
                    </TabPane>
                </Tabs>
            </div>
        );
    }
});

export default StaticPageEditView;
