import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import '@assets/css/App.css';
import { Button, Col, Form, Input, notification, Row, Select, Space, Spin, Switch, Tooltip, Typography } from 'antd';
import { useViewModel } from '@models/model';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined, FundProjectionScreenOutlined } from '@ant-design/icons';
import { plainToInstance } from 'class-transformer';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { RidEditViewModel } from './rid.edit.view.model';
import { RidData } from '@models/rid-model';
import { formatDate } from '@utils/date.utils';
import { windowOpen } from '@utils/window.utils';

const RidEditView: React.FC = observer(() => {
    const { t } = useTranslation();
    const { id: ridId } = useParams();
    const navigate = useNavigate();

    const [notifyer, contextHolder] = notification.useNotification();
    const showSuccessNotification = (title: string, description: string) => {
        notifyer.success({
            message: title,
            description: description
        });
    };
    const showErrorNotification = (description: string) => {
        notifyer.error({
            message: t('common.error.empty'),
            description: description
        });
    };

    const onClickCancel = () => {
        navigate('/rids-manage');
    };

    const onClickShowStartupProject = () => {
        if (!viewModel.rid.id) return;

        const searchParams = createSearchParams({
            rid_id: `${viewModel.rid.id}`
        }).toString();

        windowOpen('/startup-project-manage?' + searchParams);
    };

    const viewModel = useViewModel(
        () =>
            new RidEditViewModel({
                t,
                ridId,
                showSuccess: showSuccessNotification,
                showError: showErrorNotification
            })
    );
    const [form] = Form.useForm<RidData>();

    useEffect(() => {
        form.resetFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewModel.rid]);

    const onClickSave = () => form.submit();

    const getFormData = () => {
        const valFromFields: Record<string, any> = form.getFieldsValue();
        var converted: RidData = plainToInstance(RidData, { ...viewModel.rid, ...valFromFields });

        return converted;
    };

    const onSuccessFormValidation = async () => {
        const data = getFormData();
        await viewModel.onSave(data);
    };

    const getRidTypeSelectorItems = () =>
        viewModel.ridTypes.map((item) => {
            return {
                value: item.id,
                label: item.name
            };
        });

    const getRidStatusSelectorItems = () =>
        viewModel.ridStatuses.map((item) => {
            return {
                value: item.id,
                label: item.name
            };
        });

    return (
        <div>
            <br />
            <Spin spinning={viewModel.isLoading}>
                <div>
                    {contextHolder}
                    <Row gutter={12} justify="end" align="middle">
                        <Col>
                            <Button onClick={onClickCancel}>{t('common.cancel')}</Button>
                        </Col>
                        <Col>
                            <Button type="primary" onClick={onClickSave} loading={viewModel.isLoading} disabled={viewModel.isLoading}>
                                {t('common.save')}
                            </Button>
                        </Col>
                    </Row>

                    <br />
                    <Form form={form} initialValues={viewModel.rid} layout="horizontal" onFinish={onSuccessFormValidation}>
                        <div className="edit-content-view">
                            <Form.Item>
                                <Space size="large">
                                    <div>
                                        <Typography.Text>ID: </Typography.Text>
                                        <Typography.Text strong copyable>
                                            {viewModel.rid.id}
                                        </Typography.Text>
                                    </div>
                                    <div>
                                        <Typography.Text>{t('common.date.created_at')}: </Typography.Text>
                                        <Typography.Text strong>
                                            {formatDate(viewModel.rid.created_at, t('common.date.pattern.date'))}
                                        </Typography.Text>
                                    </div>
                                </Space>
                            </Form.Item>
                            <Form.Item name="name" label={t('rids.content.name')} rules={[{ required: true, message: t('common.required') || '' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="rid_assignment"
                                label={t('rids.content.assignment.title')}
                                tooltip={t('rids.content.assignment.hint')}
                                rules={[{ required: true, message: t('common.required') || '' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item name="is_protected" label={t('rids.content.is_protected.title')} valuePropName="checked">
                                <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                            </Form.Item>

                            <Form.Item
                                name={'rid_status_id'}
                                label={t('rids.content.status')}
                                rules={[{ required: true, message: t('common.required') || '' }]}
                            >
                                <Select
                                    options={getRidStatusSelectorItems()}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                />
                            </Form.Item>
                            <Form.Item
                                name={'rid_type'}
                                label={t('rids.content.type')}
                                rules={[{ required: true, message: t('common.required') || '' }]}
                            >
                                <Select
                                    options={getRidTypeSelectorItems()}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                />
                            </Form.Item>

                            <Form.Item label={t('finances.content.startups_count.title')}>
                                <Tooltip title={t('finances.content.startups_count.show')}>
                                    <Button
                                        style={{ padding: 0, paddingTop: 0, paddingLeft: 5, paddingRight: 5 }}
                                        type="text"
                                        onClick={onClickShowStartupProject}
                                    >
                                        <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                                            <FundProjectionScreenOutlined style={{ fontSize: 18, color: 'gray' }} />
                                            <Typography.Text style={{ fontSize: 18, paddingTop: 2, paddingLeft: 4 }}>
                                                {new Intl.NumberFormat('ru-ru', {}).format(viewModel.rid.startup_project_count || 0)}
                                            </Typography.Text>
                                        </div>
                                    </Button>
                                </Tooltip>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </Spin>
        </div>
    );
});

export default RidEditView;
