import { observer } from 'mobx-react-lite';
import React from 'react';
import { Col, Divider, Row, Typography, Table, notification, Tag, Space, Tooltip, Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useViewModel } from '@models/model';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { OauthClientsViewModel } from './oauth.view.model';
import { OauthClientsData } from '@models/oauth-model';

const OauthClientsView: React.FC = observer(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (description: string) => {
        notifyer.error({
            message: t('common.error.empty'),
            description: description
        });
    };

    const viewModel = useViewModel(() => new OauthClientsViewModel((description: string) => showErrorNotification(description)));

    const onShowOauthClient = (item: OauthClientsData) => {
        navigate(`${item.client_id}`);
    };

    const columns = [
        {
            title: t('oauth.content.client_id.title'),
            dataIndex: 'client_id'
        },
        {
            title: t('oauth.content.access_type.title'),
            dataIndex: 'access_type'
        },
        {
            title: t('oauth.content.operator_id.title'),
            dataIndex: 'operator_id'
        },
        {
            title: t('oauth.content.operator_name.title'),
            dataIndex: 'operator_name'
        },
        {
            title: t('oauth.content.operator_api_id.title'),
            dataIndex: 'operator_api_id'
        },
        {
            title: t('oauth.content.operator_api_name.title'),
            dataIndex: 'operator_api_name'
        },
        {
            title: t('oauth.content.scope.title'),
            render: (_: any, record: OauthClientsData) => {
                return (
                    <div>
                        {record.scope.map((item, index) => (
                            <Tooltip title={item.desc}>
                                <Tag key={`scope_${index}`}>{item.name}</Tag>
                            </Tooltip>
                        ))}
                    </div>
                );
            }
        },
        {
            title: '',
            width: '10%',
            dataIndex: 'operations',
            render: (_: any, record: OauthClientsData) => {
                return (
                    <Space direction="horizontal" size="small" align="center">
                        <Tooltip title={t('common.show.hint_show')}>
                            <Button onClick={() => onShowOauthClient(record)}>
                                {t('common.show.title')}
                                <ArrowRightOutlined />
                            </Button>
                        </Tooltip>
                    </Space>
                );
            }
        }
    ];

    return (
        <div>
            {contextHolder}

            <Row gutter={12} align="middle" justify="space-between">
                <Col>
                    <Typography.Title>{t('oauth.title')}</Typography.Title>
                </Col>
            </Row>

            <Divider style={{ marginTop: 0 }} />

            <div className="edit-content-view">
                <Table
                    size="small"
                    loading={viewModel.isLoading}
                    bordered
                    dataSource={viewModel.oauthClients}
                    columns={columns}
                    rowClassName="row"
                    rowKey={'id'}
                    pagination={false}
                />
            </div>
        </div>
    );
});

export default OauthClientsView;
