import { useState } from 'react';
import { Table, Form, Tooltip, Button, Space, Row } from 'antd';
import { useEffect } from 'react';
import { ArrowRightOutlined, FileExcelOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ReportData } from '@models/report';

interface TableProps {
    currentPage: number;
    pageSize: number;
    rows: ReportData[];
    total: number;
    loading: boolean;
    onEdit: (report: ReportData) => void;
    onDelete: (report: ReportData) => void;
    onChangePagination: (page: number, pageSize: number) => void;
}

export const ContentTable = (props: TableProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [data, setData] = useState<ReportData[]>([]);

    useEffect(() => {
        setData(props.rows);
    }, [props]);

    const onChangePage = (page: number, pageSize: number) => {
        props.onChangePagination(page, pageSize);
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id'
        },
        {
            title: t('reports.content.name.title'),
            dataIndex: 'name'
        },
        {
            title: t('reports.content.type.name'),
            dataIndex: 'type_name'
        },
        {
            title: t('reports.files.template'),
            width: '8%',
            dataIndex: 'url',

            render: (_: any, record: ReportData) => {
                if (record.url) {
                    return (
                        <Row justify="space-around">
                            <Space direction="horizontal" size="small" align="center">
                                <Tooltip title={t('reports.files.download')}>
                                    <Button type="link" icon={<FileExcelOutlined />} href={record.url} target="_blank"></Button>
                                </Tooltip>
                            </Space>
                        </Row>
                    );
                }
            }
        },
        {
            title: '',
            width: '15%',
            dataIndex: 'operations',
            render: (_: any, record: ReportData) => {
                return (
                    <Row justify="space-around">
                        <Space direction="horizontal" size="small" align="center">
                            <Tooltip title={t('reports.content.show.description')}>
                                <Button onClick={() => props.onEdit(record)}>
                                    {t('reports.content.show.title')}
                                    <ArrowRightOutlined />
                                </Button>
                            </Tooltip>
                        </Space>
                        <Tooltip title={t('common.delete')}>
                            <Button icon={<DeleteOutlined />} onClick={() => props.onDelete(record)} />
                        </Tooltip>
                    </Row>
                );
            }
        }
    ];

    return (
        <Form form={form} component={false}>
            <Table
                size="small"
                loading={props.loading}
                bordered
                dataSource={data}
                columns={columns}
                rowClassName="row"
                rowKey={'id'}
                pagination={{
                    current: props.currentPage,
                    pageSize: props.pageSize,
                    total: props.total,
                    position: ['bottomCenter'],
                    onChange: onChangePage
                }}
            />
        </Form>
    );
};
