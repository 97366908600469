import { Logger } from '@utils/logger';
import { isAfter, parseISO, add } from 'date-fns';

interface StorageItem {
    item?: any | undefined;
    expireAt?: string | undefined;
}

export class LocalStorageUtil {
    public get<T>(key: string): T | undefined {
        try {
            const objectString = localStorage.getItem(key);

            if (!objectString) {
                return undefined;
            }
            const parsedStorageItem: StorageItem = JSON.parse(objectString) as StorageItem;
            if (!parsedStorageItem.item) {
                return undefined;
            }
            if (parsedStorageItem.expireAt) {
                const expireData = parseISO(parsedStorageItem.expireAt);
                if (isAfter(new Date(), expireData)) {
                    this.delete(key);
                    return undefined;
                }
            }
            return parsedStorageItem.item as T;
        } catch (error) {
            Logger.error(`LocalStorageService get error: ${error}`);
        }
    }

    public set<T>(item: T, key: string, expirationDelay?: number | undefined): T {
        var storageItem: StorageItem = {
            item: item
        };
        if (expirationDelay) {
            const expDate = add(new Date(), { seconds: expirationDelay });
            storageItem.expireAt = expDate.toISOString();
        }
        const objectString = JSON.stringify(storageItem);
        localStorage.setItem(key, objectString);
        return item;
    }

    public delete(key: string) {
        return localStorage.removeItem(key);
    }
}
