import { observer } from 'mobx-react-lite';
import { useViewModel } from '@models/model';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, DatePicker, Form, Input, Row, Spin, Typography, notification } from 'antd';
import { useEffect, useState } from 'react';
import { ObjectLiteral, deleteUndefined } from '@utils/object.utils';
import { InfopovodEditViewModel } from './infopovod.edit.view.model';
import { instanceToPlain } from 'class-transformer';
import dayjs from 'dayjs';

const PopularizationInfopovodEditView: React.FC = observer(() => {
    const { t } = useTranslation();
    const { id: editId } = useParams();
    const navigate = useNavigate();
    const [changedFields, setChangedFields] = useState<ObjectLiteral>({});
    const onClickCancel = () => {
        navigate('/popularization-infopovod');
    };
    const [form] = Form.useForm();

    const [notifyer, contextHolder] = notification.useNotification();
    const showSuccessNotification = (title: string, description: string) => {
        notifyer.success({
            message: title,
            description: description
        });
    };
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    const viewModel = useViewModel(
        () =>
            new InfopovodEditViewModel({
                t,
                editId,
                showSuccess: showSuccessNotification,
                showError: showErrorNotification
            })
    );

    useEffect(() => {
        form.resetFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewModel.infopovod]);

    const onFormValueChange = (changed: any) => {
        setChangedFields({ ...changedFields, ...changed });
    };

    const getFormData = () => {
        return deleteUndefined({
            id: viewModel.infopovod?.id,
            ...changedFields
        });
    };

    const onSuccessFormValidation = async () => {
        let data: any = getFormData();
        for (const key in data) {
            if (typeof data[key] == 'undefined') {
                data[key] = null;
            }
        }
        await viewModel.onSave(data);
    };

    const onErrorFormValidation = (errorsInfo: any) => {
        showErrorNotification(t('common.error.empty'), t('common.check_fields'));
    };

    const prepareDataForForm = () => {
        let data = instanceToPlain(viewModel.infopovod);
        if (changedFields.date_published || typeof viewModel.infopovod.date_published === 'string') {
            data.date_published = dayjs(changedFields.date_published || viewModel.infopovod.date_published);
        }
        return data;
    };

    return (
        <div>
            {contextHolder}
            <br />
            <Row gutter={12} justify="end" align="middle">
                <Col>
                    <Button onClick={onClickCancel}>{t('common.cancel')}</Button>
                </Col>
                <Col>
                    <Button type="primary" onClick={() => form.submit()} loading={viewModel.isLoading} disabled={viewModel.isLoading}>
                        {viewModel.isPublished ? t('common.save') : t('static_page.publish')}
                    </Button>
                </Col>
            </Row>
            <br />
            <Spin spinning={viewModel.isLoading}>
                <Form
                    form={form}
                    initialValues={prepareDataForForm()}
                    layout="horizontal"
                    onValuesChange={onFormValueChange}
                    onFinish={onSuccessFormValidation}
                    onFinishFailed={onErrorFormValidation}
                >
                    <div className="edit-content-view">
                        {viewModel.isPublished && (
                            <Form.Item>
                                <Typography.Text>ID: </Typography.Text>
                                <Typography.Text strong copyable>
                                    {viewModel.infopovod.id}
                                </Typography.Text>
                            </Form.Item>
                        )}

                        <Form.Item
                            name="title"
                            label={t('popularization.infopovods.content.title')}
                            rules={[{ required: true, message: t('common.required') || '' }]}
                        >
                            <Input placeholder={t('popularization.infopovods.content.title') || ''} />
                        </Form.Item>

                        <Form.Item name="subtitle" label={t('popularization.infopovods.content.subtitle')}>
                            <Input placeholder={t('popularization.infopovods.content.subtitle') || ''} />
                        </Form.Item>

                        <Form.Item name="desc" label={t('popularization.infopovods.content.description')}>
                            <Input placeholder={t('popularization.infopovods.content.description') || ''} />
                        </Form.Item>

                        <Form.Item
                            name="date_published"
                            label={t('popularization.infopovods.content.date_published')}
                            rules={[{ required: true, message: t('common.required') || '' }]}
                        >
                            <DatePicker style={{ width: '100%' }} showNow={false} format={'DD.MM.YYYY'} />
                        </Form.Item>
                    </div>
                </Form>
            </Spin>
        </div>
    );
});

export default PopularizationInfopovodEditView;
