import { APIInstance } from '@utils/api';
import { deleteUndefined } from '@utils/object.utils';
import { GetMembersResponse, MemberResponse } from './member.contract';
import { MembersFilter } from '@interfaces/members.interface';
import { saveAs } from 'file-saver';

export class MemberModel {
    public async getMembers(params: MembersFilter): Promise<GetMembersResponse> {
        try {
            const result = await APIInstance.get<GetMembersResponse>('/cp-manager/member', {
                params: deleteUndefined({
                    result_type_id: params.result_type_id,
                    result_id: params.result_id,
                    event_id: params.event_id,
                    startup_id: params.startup_id,
                    startup_project_id: params.startup_project_id,
                    rid_id: params.rid_id,
                    guid: params.guid,
                    region_id: params.region_id,
                    institution_id: params.institution_id,
                    name: params.name,
                    limit: params.page_size,
                    offset: params.page_size && params.page ? params.page_size * (params.page - 1) : 0
                })
            });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getMemberById(id: string | number): Promise<MemberResponse> {
        try {
            const result = await APIInstance.get<MemberResponse>(`/cp-manager/member/${id}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getExcelExport(params: MembersFilter, filename: string) {
        await APIInstance({
            url: '/cp-manager/member/export/excel',
            method: 'GET',
            params: deleteUndefined({
                result_type_id: params.result_type_id,
                result_id: params.result_id,
                event_id: params.event_id,
                startup_id: params.startup_id,
                startup_project_id: params.startup_project_id,
                rid_id: params.rid_id,
                guid: params.guid,
                region_id: params.region_id,
                institution_id: params.institution_id,
                limit: params.page_size,
                offset: params.page_size && params.page ? params.page_size * (params.page - 1) : 0
            }),
            // headers: headers,
            responseType: 'blob'
        }).then((response) => {
            saveAs(response.data, filename);
        });
    }
}
