import { Button, Col, Form, Divider, Input, Row, Select, Spin, notification, Typography } from 'antd';
import '@assets/css/App.css';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
import { useViewModel } from '@models/model';
import { ReportEditViewModel } from './report.edit.view.model';
import { UsersTable } from './users.table';
import { ReportAttachedFile, ReportUser, ReportData } from '@models/report';
import { useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { FilesTable } from './files.table';
import { ObuchEventsTable } from './obuchEvents.table';
import FilesCatalogView from '@components/UI/files-catalog/catalog.view';
import { plainToInstance } from 'class-transformer';

const ReportEditView: React.FC = observer(() => {
    const { t } = useTranslation();
    const { id: reportId } = useParams();
    const navigate = useNavigate();
    const [addUserState, setAddUserState] = useState(false);
    const [userSearchValue, setUserSearchValue] = useState<any[]>([]);
    const [form] = Form.useForm<ReportData>();

    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    const onFinish = () => {
        onClickCancel();
    };

    const onClickCancel = () => {
        navigate('/reports-manage');
    };

    const viewModel = useViewModel(
        () =>
            new ReportEditViewModel({
                t,
                reportId,
                showError: showErrorNotification,
                onFinish
            })
    );

    useEffect(() => {
        form.resetFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewModel.report]);

    const getSearchUserSelectOptions = () =>
        viewModel.searchUsers.map((user) => {
            return {
                value: user.id,
                label: `Имя пользователя: ${user.name}, email: ${user.email}`,
                disabled: viewModel.connectedUsers.map((item) => item.user_id).includes(user.id!)
            };
        });

    const onClickAddUsers = async () => {
        await viewModel.addUsers(userSearchValue.map((item) => item.value));
        setUserSearchValue([]);
        setAddUserState(false);
    };

    const onClickSave = () => form.submit();

    const getFormData = () => {
        const valFromFields: Record<string, any> = form.getFieldsValue();
        var converted: ReportData = plainToInstance(ReportData, { ...viewModel.report, ...valFromFields });

        return converted;
    };

    const onSuccessFormValidation = async () => {
        const data = getFormData();
        await viewModel.updateReport(data);
    };

    return (
        <div>
            {contextHolder}
            <br />
            <Row gutter={12} justify="end" align="middle">
                <Col>
                    <Button onClick={onClickCancel}>{t('common.cancel')}</Button>
                </Col>
            </Row>
            <br />
            <div className="edit-content-view">
                <Spin spinning={viewModel.isLoading}>
                    <Form form={form} initialValues={viewModel.report} layout="horizontal" onFinish={onSuccessFormValidation}>
                        <Row gutter={12} justify="space-between" align="middle">
                            <Col>
                                <Form.Item>
                                    <Typography.Text>ID: </Typography.Text>
                                    <Typography.Text strong copyable>
                                        {viewModel.report?.id || ''}
                                    </Typography.Text>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Button type="primary" loading={viewModel.isLoading} disabled={viewModel.isLoading} onClick={onClickSave}>
                                    {t('common.save')}
                                </Button>
                            </Col>
                        </Row>

                        <Form.Item label={t('reports.files.name')} name="name" rules={[{ required: true, message: t('common.required') || '' }]}>
                            <Input placeholder={t('reports.content.name.title') || ''} />
                        </Form.Item>

                        <Form.Item
                            label={t('reports.content.type.name')}
                            name="type_id"
                            rules={[{ required: true, message: t('common.required') || '' }]}
                        >
                            <Select options={viewModel.reportTypes} fieldNames={{ label: 'name', value: 'id' }} />
                        </Form.Item>

                        <Form.Item name="file_name" label={t('reports.files.template')}>
                            <FilesCatalogView
                                name="file"
                                uploadURL={viewModel.getUploaderURL()}
                                getHeaders={viewModel.getUploaderHeaders()}
                                downloadLink={(uid: string) => viewModel.getDownloadLink(uid)}
                                defaultFileList={viewModel.reportFile}
                                changeOnUpload
                            />
                        </Form.Item>
                    </Form>
                </Spin>
            </div>
            <br />

            {viewModel.report?.id && (
                <div>
                    <Divider orientation="left">{t('reports.users.title')}</Divider>
                    <div className="edit-content-view">
                        {addUserState && (
                            <Row gutter={10} justify="end" align="middle">
                                <Col span={19}>
                                    <Select
                                        style={{ width: '100%' }}
                                        disabled={viewModel.connectedUsersIsLoading}
                                        mode="multiple"
                                        showSearch
                                        value={userSearchValue}
                                        onChange={setUserSearchValue}
                                        placeholder={t('reports.users.add.search.placeholder')}
                                        labelInValue
                                        filterOption={false}
                                        onSearch={(value: string) => viewModel.onSearchUsers(value)}
                                        notFoundContent={viewModel.searchUsersIsLoading ? <Spin size="small" /> : null}
                                        options={getSearchUserSelectOptions()}
                                    />
                                </Col>
                                <Col>
                                    <Row gutter={10} align="middle">
                                        <Col>
                                            <Button type="default" onClick={() => setAddUserState(false)}>
                                                {t('common.cancel')}
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button
                                                type="primary"
                                                disabled={userSearchValue.length === 0 || viewModel.connectedUsersIsLoading}
                                                loading={viewModel.connectedUsersIsLoading}
                                                onClick={onClickAddUsers}
                                            >
                                                {t('common.apply')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )}

                        {!addUserState && (
                            <Row gutter={12} justify="end" align="middle">
                                <Col>
                                    <Input
                                        prefix={<SearchOutlined />}
                                        allowClear
                                        placeholder={t('common.search') || ''}
                                        onChange={(event) => viewModel.onSearchConnectedUsers(event.currentTarget.value)}
                                    />
                                </Col>
                                <Col>
                                    <Button type="primary" onClick={() => setAddUserState(true)}>
                                        {t('common.add')}
                                    </Button>
                                </Col>
                            </Row>
                        )}

                        <br />
                        <UsersTable
                            rows={viewModel.connectedUsers}
                            currentPage={viewModel.connectedUsersPage}
                            pageSize={viewModel.connectedUsersPageSize}
                            total={viewModel.connectedUsersTotal}
                            loading={viewModel.connectedUsersIsLoading}
                            onDelete={(user: ReportUser) => viewModel.deleteUser(user.user_id)}
                            onChangePagination={viewModel.onChangeConnectedUsersPagination}
                        />
                    </div>
                    <br />
                    {viewModel.report?.type_id === 1 && (
                        <>
                            <Divider orientation="left">{t('reports.files.title')}</Divider>
                            <div className="edit-content-view">
                                <FilesTable
                                    rows={viewModel.files}
                                    currentPage={viewModel.filesPage}
                                    pageSize={viewModel.filesPageSize}
                                    total={viewModel.filesTotal}
                                    loading={viewModel.filesIsLoading}
                                    onDownload={(file: ReportAttachedFile) => viewModel.downloadFile(file)}
                                    onDelete={(file: ReportAttachedFile) => viewModel.deleteFile(file.id)}
                                    onChangePagination={viewModel.onChangeFilesPagination}
                                />
                            </div>
                        </>
                    )}
                    {viewModel.report?.type_id === 2 && (
                        <>
                            <Divider orientation="left">{t('reports.obuch_event.title')}</Divider>
                            <div className="edit-content-view">
                                <ObuchEventsTable
                                    rows={viewModel.files}
                                    currentPage={viewModel.filesPage}
                                    pageSize={viewModel.filesPageSize}
                                    total={viewModel.filesTotal}
                                    loading={viewModel.filesIsLoading}
                                    onChangePagination={viewModel.onChangeFilesPagination}
                                />
                            </div>
                        </>
                    )}
                </div>
            )}
            <br />
        </div>
    );
});

export default ReportEditView;
