import { APIUtilError } from '@utils/api';
import { makeAutoObservable, runInAction } from 'mobx';
import { RidData, RidModel } from '@models/rid-model';
import { DictionaryModel, RidStatusDictionaryData, RidTypeDictionaryData } from '@models/dictionary-model';

export interface RidEditViewModelStartParams {
    t: any;
    ridId: string | undefined;
    showError: (title: string, description: string) => void;
    showSuccess: (title: string, description: string) => void;
}

export class RidEditViewModel {
    private dictionaryModel = new DictionaryModel();
    private ridModel = new RidModel();
    public isLoading: boolean = false;
    public rid: RidData = new RidData();

    public ridTypes: RidTypeDictionaryData[] = [];
    public ridStatuses: RidStatusDictionaryData[] = [];

    constructor(private props: RidEditViewModelStartParams) {
        makeAutoObservable(this);
        this.wakeUpSir();
    }

    private async wakeUpSir() {
        await this.fetchRidTypeDictionary();
        await this.fetchRidStatusDictionary();
        if (this.props.ridId && this.props.ridId.length > 0 && Number(this.props.ridId)) {
            await this.fetchRid(this.props.ridId);
        }
    }

    private async fetchRidTypeDictionary() {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.dictionaryModel.getDictionary({ altname: 'rid-type' });
            runInAction(() => {
                this.ridTypes = result.data as RidTypeDictionaryData[];
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    private async fetchRidStatusDictionary() {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.dictionaryModel.getDictionary({ altname: 'rid-status' });
            runInAction(() => {
                this.ridStatuses = result.data as RidStatusDictionaryData[];
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    private async fetchRid(ridId: string) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const rid = await this.ridModel.getRidById(ridId);
            runInAction(() => {
                this.rid = rid.data;
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public onSave = async (rid: RidData) => {
        await this.save(rid);
    };

    private save = async (rid: RidData) => {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            await this.ridModel.update(rid);
            this.props.showSuccess(this.props.t('common.saved'), '');
        } catch (error) {
            this.props.showError(this.props.t('common.error.save'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };
}
