import { observer } from 'mobx-react-lite';
import React from 'react';
import { Badge, Card, Collapse, Descriptions, Divider, Spin, Table, Tag, Typography, notification } from 'antd';
import { useViewModel } from '@models/model';
import { useTranslation } from 'react-i18next';
import { AirflowViewModel } from './airflow.view.model';
import { AirflowProcessData, AirflowProcessDataRunsData, AirflowProcessDataScheduleInterval } from '@models/airflow-model';
import { t } from 'i18next';
import { formatDate } from '@utils/date.utils';

const { Title } = Typography;

const AirflowView: React.FC = observer(() => {
    const { t } = useTranslation();
    const [notifyer, contextHolder] = notification.useNotification();

    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    const viewModel = useViewModel(
        () =>
            new AirflowViewModel({
                t,
                showError: showErrorNotification
            })
    );

    return (
        <div>
            {contextHolder}
            <Title>{t('airflow.title')}</Title>
            <Divider />
            <Spin spinning={viewModel.isLoading}>
                {(viewModel.processes || []).map((item, index) => (
                    <ProcessCard key={`process-${index}`} airflowProcess={item} />
                ))}
            </Spin>
        </div>
    );
});

interface ProcessCardProps {
    airflowProcess: AirflowProcessData;
}

const ProcessCard = (props: ProcessCardProps) => {
    const { airflowProcess } = props;

    const getScheduleIntervalString = (interval: AirflowProcessDataScheduleInterval | undefined | null) => {
        if (!interval) return t('common.no');
        switch (interval.__type) {
            case 'CronExpression': {
                return interval.value;
            }
            case 'TimeDelta': {
                return ` days: ${interval.days || 0}, seconds: ${interval.seconds || 0}, microseconds: ${interval.microseconds || 0}`;
            }
            default:
                return t('common.no');
        }
    };

    const getRunStateColor = (state: string) => {
        switch (state) {
            case 'success':
                return 'green';
            case 'failed':
                return 'red';
            default:
                return undefined;
        }
    };

    const getRunConfString = (conf: any) => {
        try {
            return JSON.stringify(conf);
        } catch {
            return 'conf';
        }
    };

    return (
        <Card
            style={{ width: '100%', marginTop: '20px' }}
            title={airflowProcess.name}
            extra={
                <div>
                    {(airflowProcess.tags || []).map((item, index) => (
                        <Tag key={`process-tag-${index}`}>{item.name}</Tag>
                    ))}
                </div>
            }
        >
            <div>
                <Descriptions bordered>
                    <Descriptions.Item label={t('airflow.content.dag_id')}>{airflowProcess.dag_id}</Descriptions.Item>
                    <Descriptions.Item label={t('airflow.content.is_active')} span={1}>
                        <Badge
                            status={airflowProcess.is_active ? 'processing' : 'warning'}
                            text={airflowProcess.is_active ? t('common.yes') : t('common.no')}
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label={t('airflow.content.is_paused')} span={1}>
                        <Badge
                            status={airflowProcess.is_paused ? 'warning' : 'processing'}
                            text={airflowProcess.is_paused ? t('common.yes') : t('common.no')}
                        />
                    </Descriptions.Item>

                    <Descriptions.Item label={t('airflow.content.schedule_interval')} span={3}>
                        {getScheduleIntervalString(airflowProcess.schedule_interval)}
                    </Descriptions.Item>
                </Descriptions>

                <br />

                <Collapse
                    collapsible="header"
                    ghost
                    items={[
                        {
                            key: 'collapse-table',
                            label: `${t('airflow.content.last_runs.title')} - ${airflowProcess.last_runs.length}`,
                            children: (
                                <Table
                                    dataSource={airflowProcess.last_runs}
                                    rowKey={'dag_run_id'}
                                    columns={[
                                        {
                                            title: t('airflow.content.last_runs.state'),
                                            dataIndex: 'state',
                                            key: 'state',
                                            render: (_: any, record: AirflowProcessDataRunsData) => {
                                                return <Tag color={getRunStateColor(record.state)}>{record.state}</Tag>;
                                            }
                                        },
                                        {
                                            title: t('airflow.content.last_runs.start_date'),
                                            dataIndex: 'start_date',
                                            key: 'start_date',
                                            render: (_: any, record: AirflowProcessDataRunsData) => {
                                                return (
                                                    <Typography.Text>
                                                        {formatDate(record.start_date, t('common.date.pattern.date_time'))}
                                                    </Typography.Text>
                                                );
                                            }
                                        },
                                        {
                                            title: t('airflow.content.last_runs.end_date'),
                                            dataIndex: 'end_date',
                                            key: 'end_date',
                                            render: (_: any, record: AirflowProcessDataRunsData) => {
                                                return (
                                                    <Typography.Text>
                                                        {formatDate(record.end_date, t('common.date.pattern.date_time'))}
                                                    </Typography.Text>
                                                );
                                            }
                                        },
                                        {
                                            title: t('airflow.content.last_runs.dag_run_id'),
                                            dataIndex: 'dag_run_id',
                                            key: 'dag_run_id',
                                            render: (_: any, record: AirflowProcessDataRunsData) => {
                                                return <Typography.Text code>{record.dag_run_id}</Typography.Text>;
                                            }
                                        },
                                        {
                                            title: t('airflow.content.last_runs.conf'),
                                            dataIndex: 'conf',
                                            key: 'conf',
                                            render: (_: any, record: AirflowProcessDataRunsData) => {
                                                return <Typography.Text code>{getRunConfString(record.conf)}</Typography.Text>;
                                            }
                                        }
                                    ]}
                                    pagination={false}
                                />
                            )
                        }
                    ]}
                />
            </div>
        </Card>
    );
};

export default AirflowView;
