export class StartupProjectData {
    id: number;
    name: string;

    idea_description?: string;
    startup_project_direction_id?: number;
    startup_project_direction_ids?: number[];
    has_mvp?: boolean;
    trl_id?: number;
    result_type_id?: number;
    problem_description?: string;
    solution_description?: string;
    region_id?: number;
    institution_id?: number;
    startup_project_date?: string;
    month_id?: number;
    base_id?: string;

    members_count?: number | undefined;
    startup_count?: number | undefined;
    rid_count?: number | undefined;
    finance_count?: number | undefined;
    startup_studio_name?: string | null;

    created_at: string;
    updated_at: string;
}

export class GetStartupsResponse {
    data: StartupProjectData[];
    total: number;
    count: number;
}

export class StartupResponse {
    data: StartupProjectData;
}
