import { Descriptions, Drawer } from 'antd';
import '@assets/css/App.css';
import { useTranslation } from 'react-i18next';
import { MemberData } from '@models/member-model';
import { formatDate } from '@utils/date.utils';

interface Props {
    visible: boolean;
    member: MemberData;
    onClose: () => void;
}

const ShowMember: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();

    const onClose = async () => {
        props.onClose();
    };

    const InstitutionInfoView = (member: MemberData) => {
        return (
            <Descriptions
                size="small"
                items={[
                    {
                        key: '1',
                        label: 'id',
                        span: 3,
                        children: member.institution?.id || '-'
                    },
                    {
                        key: '2',
                        label: t('members.content.institution.name'),
                        span: 3,
                        children: member.institution?.name || '-'
                    }
                ]}
            />
        );
    };

    const getItems = (member: MemberData) => {
        return [
            {
                key: 'id',
                span: 3,
                label: 'ID',
                children: member.id
            },
            {
                key: 'guid_putp',
                span: 3,
                label: 'guid_putp',
                children: member.guid_putp
            },
            {
                key: 'gender',
                span: 3,
                label: t('common.gender.title'),
                children: member.gender ? t(`common.gender.${member.gender.toLowerCase()}`) : t('common.gender.empty')
            },
            {
                key: 'birth_date',
                span: 3,
                label: t('members.content.birth_date'),
                children: member.birth_date ? formatDate(member.birth_date, t('common.date.pattern.date')) : '-'
            },
            {
                key: 'citizenship',
                span: 3,
                label: t('members.content.citizenship'),
                children: member.citizenship?.name || '-'
            },
            {
                key: 'region',
                span: 3,
                label: t('members.content.region'),
                children: member.region?.name || '-'
            },
            {
                key: 'institution',
                label: t('members.content.institution.title'),
                span: 3,
                children: <InstitutionInfoView {...member} />
            },
            {
                key: 'institution-id',
                label: t('members.content.institution.member_status'),
                span: 3,
                children: member.member_status?.name || t('members.content.institution.empty')
            },
            {
                key: 'institution-spec',
                label: t('members.content.institution.spec'),
                span: 3,
                children: member.spec?.name || t('members.content.institution.empty')
            },
            {
                key: 'institution-finance_source',
                label: t('members.content.institution.finance_source'),
                span: 3,
                children: member.finance_source?.name || t('members.content.institution.empty')
            },
            {
                key: 'institution-vo',
                label: t('members.content.institution.vo'),
                span: 3,
                children: member.vo?.name || t('members.content.institution.empty')
            },
            {
                key: 'institution-level_ed',
                label: t('members.content.institution.level_ed'),
                span: 3,
                children: member.spec?.level?.name || t('members.content.institution.empty')
            },
            {
                key: 'institution-course_ed',
                label: t('members.content.institution.course_ed'),
                span: 3,
                children: member.course_ed || t('members.content.institution.empty')
            }
        ];
    };

    return (
        <Drawer forceRender title={t('members.show.title')} width={650} onClose={onClose} open={props.visible}>
            <Descriptions bordered layout="horizontal" items={getItems(props.member)} />
        </Drawer>
    );
};

export default ShowMember;
