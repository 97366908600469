import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import '@assets/css/App.css';
import { Button, Col, Divider, Form, Input, notification, Row, Select, Space, Spin, Switch, Typography } from 'antd';
import { useViewModel } from '@models/model';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { StartupProjectEditViewModel } from './startup-project.edit.view.model';
import { useNavigate, useParams } from 'react-router-dom';
import { StartupProjectData } from '@models/startup-project-model';
import { formatDate } from '@utils/date.utils';
import WYSIWYGEditor from '@components/UI/WYSIWYG/WYSIWYG.editor';

const StartupProjectEditView: React.FC = observer(() => {
    const { t, i18n } = useTranslation();
    const { id: startupId } = useParams();
    const navigate = useNavigate();

    const [notifyer, contextHolder] = notification.useNotification();
    const showSuccessNotification = (title: string, description: string) => {
        notifyer.success({
            message: title,
            description: description
        });
    };
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    const onClickCancel = () => {
        navigate('/startup-project-manage');
    };

    const viewModel = useViewModel(
        () =>
            new StartupProjectEditViewModel({
                t,
                startupId,
                showSuccess: showSuccessNotification,
                showError: showErrorNotification
            })
    );
    const [form] = Form.useForm<StartupProjectData>();

    useEffect(() => {
        form.resetFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewModel.startupProject]);

    const onSuccessFormValidation = async () => {
        return;
    };

    const getStartupDirectionsSelectorItems = () =>
        viewModel.startupDirections.map((item) => {
            return {
                value: item.id,
                label: item.name
            };
        });
    const getRegionSelectorContent = () =>
        viewModel.regionDictionary.map((item) => {
            return {
                value: item.id,
                label: item.name
            };
        });

    const getInstitutionSelectorContent = () =>
        viewModel.institutionDictionary.map((item) => {
            return {
                value: item.id,
                label: item.name
            };
        });

    const getMonthSelectorContent = () =>
        viewModel.MonthDictionary.map((item) => {
            return {
                value: item.id,
                label: `${item.name} ${item.year}`
            };
        });

    const getTRLSelectorContent = () =>
        viewModel.trlDictionary.map((item) => {
            return {
                value: item.id,
                label: item.name
            };
        });

    return (
        <div>
            <br />

            <div>
                {contextHolder}
                <Row gutter={12} justify="end" align="middle">
                    <Col>
                        <Button onClick={onClickCancel}>{t('common.back')}</Button>
                    </Col>
                    {/* <Col>
                        <Button type="primary" onClick={onClickSave} loading={viewModel.isLoading} disabled={viewModel.isLoading}>
                            {t('common.save')}
                        </Button>
                    </Col> */}
                </Row>

                <br />
                <fieldset disabled={true} style={{ border: 'none', padding: '0', margin: '0' }}>
                    <Form form={form} initialValues={viewModel.startupProject} layout="horizontal" onFinish={onSuccessFormValidation}>
                        <div className="edit-content-view">
                            <Spin spinning={viewModel.isLoading}>
                                <Form.Item>
                                    <Space size="large">
                                        <div>
                                            <Typography.Text>ID: </Typography.Text>
                                            <Typography.Text strong copyable>
                                                {viewModel.startupProject.id}
                                            </Typography.Text>
                                        </div>
                                        {viewModel.startupProject.startup_project_date && (
                                            <div>
                                                <Typography.Text>{t('startup_project.content.startup_project_date.title')}: </Typography.Text>
                                                <Typography.Text strong>
                                                    {formatDate(viewModel.startupProject.startup_project_date, t('common.date.pattern.date'))}
                                                </Typography.Text>
                                            </div>
                                        )}
                                    </Space>
                                </Form.Item>

                                <Form.Item
                                    name="name"
                                    label={t('startup_project.content.name.title')}
                                    // rules={[{ required: true, message: t('common.required') || '' }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item name={'startup_direction_id'} label={t('startup_project.content.direction')}>
                                    <Select
                                        open={false}
                                        options={getStartupDirectionsSelectorItems()}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    />
                                </Form.Item>

                                <Form.Item name={'startup_direction_ids'} label={t('startup_project.content.directions')}>
                                    <Select
                                        open={false}
                                        mode="multiple"
                                        options={getStartupDirectionsSelectorItems()}
                                        showSearch
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        allowClear
                                        placeholder={t('common.sorting.not_selected')}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="has_mvp"
                                    label={t('startup_project.content.has_mvp.title')}
                                    tooltip={t('startup_project.content.has_mvp.hint')}
                                    valuePropName="checked"
                                >
                                    <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                                </Form.Item>

                                <Form.Item
                                    name={'trl_id'}
                                    label={t('startup_project.content.trl.title')}
                                    tooltip={t('startup_project.content.trl.hint')}
                                >
                                    <Select
                                        open={false}
                                        options={getTRLSelectorContent()}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    />
                                </Form.Item>

                                <Form.Item name="problem_description" label={t('startup_project.content.problem_description.title')} rules={[]}>
                                    <Input.TextArea rows={3} placeholder={t('startup_project.content.problem_description.placeholder') || ''} />
                                </Form.Item>

                                <Form.Item name="solution_description" label={t('startup_project.content.solution_description.title')} rules={[]}>
                                    <Input.TextArea rows={3} placeholder={t('startup_project.content.solution_description.placeholder') || ''} />
                                </Form.Item>

                                <Form.Item name={'region_id'} label={t('startup_project.content.region.title')}>
                                    <Select
                                        open={false}
                                        options={getRegionSelectorContent()}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    />
                                </Form.Item>

                                <Form.Item name={'institution_id'} label={t('startup_project.content.institution.title')}>
                                    <Select
                                        open={false}
                                        options={getInstitutionSelectorContent()}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    />
                                </Form.Item>

                                <Form.Item name={'month_id'} label={t('startup_project.content.month.title')}>
                                    <Select
                                        open={false}
                                        options={getMonthSelectorContent()}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    />
                                </Form.Item>

                                <Form.Item name={'startup_studio_name'} label={t('startup_project.content.startup_studio.title')}>
                                    <Select open={false} showSearch optionFilterProp="children" />
                                </Form.Item>
                            </Spin>
                        </div>
                        <br />
                        <Divider orientation="left">{t('startup_project.content.idea_description.title')}</Divider>
                        <div className="edit-content-view">
                            <Form.Item name="idea_description" rules={[]}>
                                <WYSIWYGEditor
                                    readOnly
                                    editorDefaultValue={viewModel.startupProject.idea_description || ''}
                                    placeholder={t('startup_project.content.idea_description.placeholder') || ''}
                                    languageCode={i18n.language}
                                    onChange={(content: string) => form.setFieldValue('idea_description', content)}
                                />
                            </Form.Item>
                        </div>
                    </Form>
                </fieldset>
            </div>
            <br />
            <br />
        </div>
    );
});

export default StartupProjectEditView;
